export const online_monitoring = {
  namespaced: true,
  state: {
    organisation: {},
    isVisibleModalOmReport: false,
  },
  getters: {
    organisation: (state) => {
      return state.organisation;
    },
    get_is_visible_modal_om_report: (state) => {
      return state.isVisibleModalOmReport;
    },
  },
  actions: {},
  mutations: {
    set_organisation(store, organisation) {
      store.organisation = organisation;
    },
    set_is_visible_modal_om_report(store, payload) {
      store.isVisibleModalOmReport = payload;
    }
   },
};
