<template>
  <div class="progress" :class="'progress-' + bar.size">
    <div :class="barClasses" class="progress-bar" :style="{width: percent + '%'}">
      <span v-if="!bar.title " class="sr-only" >{{percent + '% Complete'}}</span>
      <template v-else>{{percent + '%'}}</template>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'ProgressBar',
        props: {
            value: Object,
            bar: Object
        },
        computed: {
            percent() {
                return (this.value.max - this.value.min) * this.value.now / 100;
            },
            barClasses() {
                let result = '';
                if (this.bar.striped) {
                    result += 'progress-bar-striped ';
                }
                result += 'progress-bar-' + this.bar.type;

                return result;
            },
             size() {
                if(this.bar.size !== 'null')
                    return 'progress-' + this.bar.size;
                return '';
            }
        }
    }
</script>