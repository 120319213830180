<template>
  <div
    class="modal fade"
    :class="{'show': show}"
  >
    <div class="modal-dialog">
      <div class="modal-content modal-content__trash">
        <div
          class="modal-close"
          @click="$emit('close')"
        >
          <em class="sr-close" />
        </div>
        <div class="d-flex flex-row">
          <div class="modal-container flex-1 d-flex flex-column">
            <div class="modal-header modal-header__trash">
              <h5 class="modal-title modal-title__trash">
                <slot name="title">
                  Удалить запись
                </slot>
              </h5>
            </div>
            <div class="modal-body modal-body__trash flex-1">
              <div
                v-if="$slots.default"
                class="mb-4"
              >
                <slot />
              </div>
            </div>
            <div class="modal-footer modal-footer__trash">
              <slot name="buttons">
                <button
                  class="btn btn-danger"
                  @click="$emit('trash')"
                >
                  Удалить запись
                </button>
              </slot>
            </div>
          </div>
          <div
            v-if="data.items && data.items.length > 0"
            class="modal-addition d-none d-md-block"
          >
            <h5
              v-if="data.title"
              class="modal-addition-title"
            >
              {{ data.title }}
            </h5>
            <div
              v-for="(item, index) in data.items"
              :key="index"
              class="row mb-4"
            >
              <div class="col-12 col-sm-12 bold">
                {{ item.name }}
              </div>
              <div class="col-12 col-sm-12">
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'ModalTrash',
      props: {
          data: {
              type: Object,
              default: null
          }
      },
      data() {
          return {
              show: false
          }
      },
      created() {
          this.show = true;
      },
  }
</script>