export const vehicles = {
  namespaced: true,
  state: {
    updateStatus: false
  },
  getters: {
    updateStatus: state => state.updateStatus
  },
  actions: {
    startUpdate({commit}) {
      commit('startUpdate');
    },
    finishUpdate({commit}) {
      commit('finishUpdate');
    },
  },
  mutations: {
    startUpdate(state) {
      state.updateStatus = true;
    },
    finishUpdate(state) {
      state.updateStatus = false;
    },
  }
}