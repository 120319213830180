export const writeInstructionTechnicalDepartment = {
   namespaced: true,
   state: {
      infoForEditInstr: JSON.parse(localStorage.getItem('infoForEditInstr')) || null,
   },
   getters: {
      getInfoForEditInstr(store) {
         return store.infoForEditInstr;
      }
   },
   actions: {

   },
   mutations: {
      setInfoForEditInstr(store, payload) {
      store.infoForEditInstr = payload;
      localStorage.setItem('infoForEditInstr', JSON.stringify(payload));
      },
   },
};
