<template>
  <div>
    <div class="" id="inventory_map"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "@/_helpers";

export default {
  name: 'Map',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    centerPoint: {
      type: Boolean,
      default: false,
    },
    activeCreatePoint: {
      type: Boolean,
      default: false,
    },
    points: Array,
  },
  data() {
    return {
      myMap: null,
      ymaps: null,
      objectManager: null,
      objectId: null,
      script_upload_yandex: null,
    };
  },
  beforeCreate() {
    this.$store.dispatch("user/coords");
    this.$store.dispatch("containers/types");
    this.$store.dispatch("platformTypes/all");
  },
  watch: {
    points() {
      if (this.objectManager) {
        this.setObjectManager();
      }
    },
    showNavCode() {
      this.myMap.container.fitToViewport();
    }
  },
  mounted() {
    const upload_yandex = () => {
      this.script_upload_yandex = document.createElement("script");
      this.script_upload_yandex.setAttribute(
        "src",
        `https://enterprise.api-maps.yandex.ru/2.1?apikey=${process.env.VUE_APP_YANDEX_KEY}&lang=ru_RU&mode=release&coordorder=latlong`
      );
      this.script_upload_yandex.setAttribute(
        "id",
        `my_map_in`
      );
      this.script_upload_yandex.setAttribute(
        "defer",
        ""
      );
      this.script_upload_yandex.setAttribute(
        "async",
        ""
      );
      document.head.prepend(this.script_upload_yandex);

      this.script_upload_yandex.onload = () => {
        window.ymaps.ready(() => {
          this.ymaps = window.ymaps;
          this.init();
        });
      };
    }
    
    if (!window.ymaps) {
      upload_yandex();
    } else {
      this.ymaps = window.ymaps;
      this.init();
    }
  },
  methods: {
    init() {
      this.location();

      this.objectManager = new window.ymaps.ObjectManager({
        clusterize: false,
        clusterHasBalloon: false,
        geoObjectOpenBalloonOnClick: false,
      });

      this.myMap = new window.ymaps.Map("inventory_map", {
        center: localStorage.getItem("inventoryCenterMap")
          ? JSON.parse(localStorage.getItem("inventoryCenterMap"))
          : [55.76, 37.64],
        zoom: 7,
      });

      this.setObjectManager();
      this.clearText();
      this.objectManagerEvents();
      this.windowCorner();

      this.myMap.events.add("boundschange", () => {
        this.windowCorner();
      });
    },
    setObjectManager() {
      this.objectManager.removeAll();

      let result = [];

      for (const i of this.points) {
        result.push({
          type: "Feature",
          id: i.id,
          geometry: {
            type: "Point",
            coordinates: i.co ? [i.co[1], i.co[0]] : [0, 0],
          },
          options: {
            preset: this.statusColor(i.si),
          },
          properties: {
            iconContent: i.cf || i.cn,
          },
        });
      }

      this.objectManager.add(result);

      this.myMap.geoObjects.add(this.objectManager);
    },
    statusColor(e) {
      let color = "";

      switch (e) {
        case 1:
          color = "islands#blueIcon";
          break;
        case 2:
          color = "islands#greenIcon";
          break;
        case 3:
          color = "islands#redIcon";
          break;
      }

      return color;
    },
    clearText() {
      let copyrightLeft = document.querySelector('[class*="copyrights-promo"]');

      if (copyrightLeft) copyrightLeft.style.display = "none";
    },
    objectManagerEvents() {
      this.objectManager.objects.events.add("click", (e) => {
        this.objectId = e.get("objectId");

        this.balloonTemplateInfo();
      });
    },
    balloonTemplateInfo() {
      this.objectManager.objects.getById(
        this.objectId
      ).properties.balloonContentBody = `<h4>Загрузка...</h4>`;
      this.objectManager.objects.balloon.open(this.objectId);

      request(`${process.env.VUE_APP_WN}/platform_card/${this.objectId}`, "get").then(
        (res) => {
          if (res.success) {
            let data = res.data;

            let valueContainer = this.valueContainer(data.container_cards);

            let balloonTemplate = `
          <div class="row" style="width: 301px">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <p class="small text-muted" v-if="${data.author}">Автор: ${
              data.author
            }</p>
                <p class="small text-muted">${this.$moment
                  .utc(data.datetime.date)
                  .format("DD.MM.YYYY HH:mm")}</p>
              </div>
              <p>
                ${data.address ? data.address : ""}
              </p>
              <div class="d-flex justify-content-between">
                <p class="font-weight-bold">Тип:</p>
                <p>${
                  this.platform_types.find((e) => e.id == data.container_platform_type_id)
                    .name
                }</p>
              </div>
              <div>
                <p class="font-weight-bold mb-0">Контейнеры:</p>
                <ul class="zebra">
                  ${valueContainer
                    .map((i, index) => {
                      return `
                      <li class="d-flex justify-content-between px-1" style="${
                        !(index % 2) ? "background: #ececec;" : ""
                      }">
                        <p class="mb-0">
                          ${i.name} ${i.material ? `(${i.material})` : ""}
                        </p>
                        <p class="mb-0">
                          ${i.element}
                        </p>
                      </li>`;
                    })
                    .join("")}                
                </ul>
              </div>
              <div style="text-align: center" class="pb-1">
                <a class="rounded btn btn-outline-primary btn-sm" href="/inventory/platform/${
                  data.id
                }">Просмотр</a>
                <a class="ml-2 rounded btn btn-outline-info btn-sm" href="/inventory/platform/${
                  data.id
                }/edit">Изменить</a>
              </div>
            </div>
          </div>`;

            this.objectManager.objects.getById(
              this.objectId
            ).properties.balloonContentBody = balloonTemplate;

            this.objectManager.objects.balloon.open(this.objectId);
          }
        }
      );
    },
    valueContainer(data) {
      let containerSumm = data
        .filter((x) => !x.trashed)
        .reduce((acc, el) => {
          acc[el.container_type_id] = (acc[el.container_type_id] || 0) + 1;
          return acc;
        }, {});

      let arr = [];

      for (const key in containerSumm) {
        if (Object.hasOwnProperty.call(containerSumm, key)) {
          const element = containerSumm[key];
          const filterType = this.container_types.filter((x) => x.id == key);

          if (filterType) {
            let returnVar = {
              name: filterType.find((e) => e.id == key)? filterType.find((e) => e.id == key).name: '',
              material: filterType.find((e) => e.id == key)
                ? filterType.find((e) => e.id == key).material
                : "",
              element,
            };

            arr.push(returnVar);
          }
        }
      }

      return arr;
    },
    location() {
      let location = this.ymaps.geolocation.get();

      location.then(
        (result) => {
          localStorage.setItem(
            "inventoryCenterMap",
            JSON.stringify(result.geoObjects.position)
          );
          this.myMap.panTo([result.geoObjects.position]);
        },
        (err) => {
          console.log("Ошибка: " + err);
        }
      );
    },
    windowCorner() {
      let upperCorner = {
        lat: this.myMap.getBounds()[0][0],
        lng: this.myMap.getBounds()[0][1],
      };
      let lowerCorner = {
        lat: this.myMap.getBounds()[1][0],
        lng: this.myMap.getBounds()[1][1],
      };

      this.$emit(`windowCorner`, {
        upperCorner,
        lowerCorner,
      });

      localStorage.setItem("inventoryUpperCorner", JSON.stringify(upperCorner));
      localStorage.setItem("inventoryLowerCorner", JSON.stringify(lowerCorner));
    },
  },
  computed: {
    ...mapGetters({
      container_types: "containers/types",
      platform_types: "platformTypes/allPlatformTypes",
      center: "user/coords",
      showNavCode: 'showNavCode',
    }),
  },
  beforeDestroy() {
    const nodeYandexMap = document.getElementById('my_map_in');
    if (nodeYandexMap) {
      nodeYandexMap.remove();
      delete window.ymaps;
    }
    this.myMap.destroy();
    // this.script_upload_yandex.remove();
  },
};
</script>

<style lang="scss" scoped>
#inventory_map {
  width: 100%;
  height: 700px;
}
</style>
