import { request } from '../_helpers';

export const tutorial = {
  namespaced: true,
  state: {
    listTutorial: [],
    allTutorial: {
      'OM-statuses': {
        arrText: [
          'Попадают только задания, которые напечатали, но не взяли в работу',
          'Попадают те задания, которые взяли в работу',
          'Попадают те задания, которые взяли в работу и завершили',
        ],
      },
    },
  },
  getters: {
    getListTutorial: (state) => {
      return state.listTutorial;
    },
    getAllTutorial: (state) => {
      return state.allTutorial;
    }
  },
  actions: {
    requestListTutorial(store) {
      request(`${process.env.VUE_APP_WN}/tutorial/list`, 'get').then((response) => {
        store.commit('setListTutorial',response.data);
      })
    },
    createTutorial(_, title) {
      request(`${process.env.VUE_APP_WN}/tutorial/create`, 'post', {
        title: title,
      });
    },
    checkUserTutorialCompletion(_, tutorialId) {
      return request(`${process.env.VUE_APP_WN}/tutorial/check?tutorial_id=${tutorialId}`,
        'get'
      );
    },
  },
  mutations: {
    setListTutorial(state, list) {
      state.listTutorial = list;
    },
  },
};
