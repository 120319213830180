var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-component-container"},[(_vm.$slots.title)?_c('div',{staticClass:"select-label"},[_vm._t("title")],2):_vm._e(),_c('div',{ref:"clickOutsideWrapper",staticClass:"select-plus-options-wrapper",attrs:{"id":_vm.customId}},[_c('div',{staticClass:"select-wrapper",class:{
        'select-disabled': _vm.disabled,
        'select-active': _vm.optionsVisible || _vm.selectedOption,
        'select-placeholder': _vm.placeholder && !_vm.selectedOption,
        'select-required': _vm.required,
        'select-wrapper--danger': _vm.danger.status,
      },on:{"click":function($event){return _vm.toggleOptionsVisible(_vm.disabled)}}},[_c('div',{staticClass:"select-box"},[(_vm.useContentSlots)?[(_vm.selectedOption)?_vm._t("selected-option-content"):[_vm._v(_vm._s(_vm.placeholder))]]:[_vm._v(" "+_vm._s(_vm.selectedOption ? _vm.selectedOption[_vm.viewKey] : _vm.placeholder)+" ")]],2),(_vm.required || _vm.danger.status)?_c('div',{staticClass:"select-error"},[_c('img',{staticClass:"select-error-image",attrs:{"src":require("@/assets/restyle/images/shared/select/error.svg"),"alt":""}})]):_vm._e(),_vm._m(0)]),(_vm.optionsVisible)?_c('div',{ref:"selectOptions",staticClass:"select-option-wrapper scrollbar scrollbar--secondary",style:(_vm.selectOptionsStyle)},[(!_vm.notSelected)?_c('div',{staticClass:"select-option",on:{"click":function($event){return _vm.selectOption(null)}}},[_vm._v("Не выбрано")]):_vm._e(),_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"select-option",on:{"click":function($event){return _vm.selectOption(option)}}},[(_vm.useContentSlots)?_vm._t("option-content",null,{"currentOption":option}):[_vm._v(" "+_vm._s(option[_vm.viewKey])+" ")]],2)})],2):_vm._e()]),(_vm.$slots.required && _vm.required)?_c('div',{staticClass:"select-required-label"},[_vm._t("required")],2):_vm._e(),(_vm.danger.text)?_c('small',{staticClass:"danger-text"},[_vm._v(" "+_vm._s(_vm.danger.text)+" ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-arrow"},[_c('img',{attrs:{"src":require("@/assets/restyle/images/shared/arrow_down.svg"),"alt":""}})])
}]

export { render, staticRenderFns }