<template>
  <div class="reference-block">
    <div id="fact-online_monitoring" class="reference-block-link" v-if="userCan('monitoring_read')">
      <router-link :to="{ name: sizeOnlineMonitoring }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('online_monitoring') }}
          <div v-if="isActiveHotSpot('online_monitoring')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('online_monitoring'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="fact-online_photo" class="reference-block-link" v-if="userCan('monitoring_read')">
      <router-link :to="{ name: 'online_monitoring_photo' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('online_monitoring_photo') }}
          <div v-if="isActiveHotSpot('online_monitoring_photo')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('online_monitoring_photo'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <!-- <div id="fact-normative" class="reference-block-link" v-if="userCan('monitoring_read')">
      <router-link :to="{ name: 'fact_norm' }" class="links-item">
        Нормативы
      </router-link>
    </div> -->
    <div id="fact-vehicle" class="reference-block-link" v-if="userCan('vehicle_read_all') || back_office">
      <router-link
        :to="{ name: 'vehicle' }"
        class="links-item"
      >
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('vehicle') }}
          <div v-if="isActiveHotSpot('vehicle')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('vehicle'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="fact-online_list" class="reference-block-link" v-if="userCan('online_list')">
      <router-link :to="{ name: 'online_list' }" class="links-item">
        <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('online_list') }}
          <div v-if="isActiveHotSpot('online_list')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('online_list'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="fact-monitoring_vehicle" class="reference-block-link" v-if="userCan('vehicle_monitoring')">
      <router-link :to="{ name: 'monitoring_vehicle' }" class="links-item">
      <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('monitoring_vehicle') }}
          <div v-if="isActiveHotSpot('monitoring_vehicle')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('monitoring_vehicle'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div id="fact-rework_online_monitoring" class="reference-block-link" v-if="userCan('superadmin') || userCan('monitoring_read')">
      <router-link :to="{ name: 'rework_online_monitoring' }" class="links-item">
        Онлайн мониторинг

        <div class="fact-rework_online_monitoring-beta">
          Бета
        </div>
      </router-link>
    </div>
  </div> 
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    back_office: {
      required: true,
    }
  },
  data() {
    return {
      tooltipText: '',
      hoverEvent: null,
    };
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      sizeOnlineMonitoring: "navigation/sizeOnlineMonitoring",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
  },
  methods: {
      isActiveHotSpot(menu) {
        const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
        const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
        return result;
      },
      currentTooltipForHotSpot(menu) {
        const hotSpot = this.isActiveHotSpot(menu);
        if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
          return hotSpot.tooltip
        } 
        return ''
      }
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 398px;
  border-radius: 5px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d6886;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}

#fact-rework_online_monitoring {
  position: relative;
}

.fact-rework_online_monitoring-beta {
  position: absolute;
  top: 0;
  left: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 24px;
  color: #ffffff;
  background-color: #FF7324;
  font-size: 12px;
  font-weight: 700;
  border-radius: 30px;
}
</style>