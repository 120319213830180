export const wbConnect = {
    namespaced: true,
    state: {
      echo: null,
    },
    getters: {
      getEcho: state => {
          return state.echo
      },
    },
    actions: {
      setEcho(store, payload) {
        store.commit('setEcho', payload);
      },
    },
    mutations: {
      setEcho(store, payload) {
        store.echo = payload;
      },
    }
};