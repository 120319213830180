<template>
  <span>
    <input type="checkbox" v-model="check" v-show="false" />
    <span class="switchery" :class="'switchery-' + switchery.type" :style="checkedStyle" @click.prevent="checked">
      <small :style="sliderStyle"></small>
    </span>
  </span>
</template>
<script>
  export default {
    name: 'Switchery',
      props: {
          switchery: Object,
          check: Number
      },
      computed: {
          checkedStyle() {
              if (this.check) {
                  return {
                      backgroundColor: this.switchery.color,
                      borderColor: this.switchery.color,
                      boxShadow: this.switchery.color + '0 0 0 21px inset',
                      transition: 'border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s'
                  }
              } else {
                  if (this.switchery.secondaryColor) {
                      return {
                          backgroundColor: this.switchery.secondaryColor,
                          borderColor: this.switchery.secondaryColor,
                      }
                  }
              }
              return ''
          },
          sliderStyle() {
              if(this.check) {
                  let left = '';
                  switch (this.switchery.type) {
                      case 'small':
                          left = '13px';
                          break;

                      case 'large':
                          left = '26px';
                          break;

                      default:
                          left = '20px';
                          break;

                  }
                  return {
                      left: left,
                      transition: 'background-color 0.4s ease 0s, left 0.2s ease 0s',
                  };
              }
              return ''
          }
      },
      methods: {
          checked() {
              let check = (this.check === 1) ? 0 : 1;
              this.$emit('change', check);
          }
      }
  }
</script>
