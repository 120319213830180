import Vue from 'vue'
import App from './App.vue'
import { router } from './_helpers';
import store from './_store';
import './geo';

import BootstrapVue from 'bootstrap-vue';
import { components } from "./components"
import { directives } from "./directives";
// import { BrowserTracing } from "@sentry/tracing";
import object from "./_helpers/object";
// https://www.npmjs.com/package/v-mask
import VueMask from 'v-mask'
import VueAuthImage from 'vue-auth-image';
import YmapPlugin from 'vue-yandex-maps';
import { createYmapsVue2 } from 'vue-yandex-maps-new';
import VCalendar from 'v-calendar';
import { initSentry } from "./initSentry"

Vue.use(YmapPlugin, {
  apiKey: `${process.env.VUE_APP_YANDEX_KEY}`,
})

Vue.use(createYmapsVue2({
  apikey: `${process.env.VUE_APP_YANDEX_KEY_V3}`,
}));

Vue.use(VueMask, {
  placeholders: {
    D: /\d/, // цифры
    Я: /[\а-яА-ЯёЁ]/, // кириллица
    '.': /./, // любой символ
  }
});

Vue.use(VueAuthImage);
Vue.use(VCalendar);

Vue.use(require('vue-moment'));
window.object = new object();
Vue.use(BootstrapVue);

directives.forEach(element => {
  Vue.directive(element.name, element.directive);
});

components.forEach(element => {
  Vue.component(element.name, element);
});

Vue.config.productionTip = false

initSentry()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
