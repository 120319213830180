<template>
  <div>
    <!-- <Header /> -->
    <Sidebar />
    <div class="content-page" style="z-index: 0;">
      <div class="content d-flex flex-column">
        <slot></slot>
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
export default {
  name: 'defaultnew-layout',
  components: { Sidebar },
};
</script>
<style lang="scss">
.container-fluid {
  flex: 1;
}
</style>
