<template>
  <span class="position-relative">
    <button type="button" class="btn btn-default" @click.prevent="showTooltipOnClick=!showTooltipOnClick" @mouseenter.prevent="showTooltip = true" @mouseleave.prevent="showTooltip = false">
      {{button.title}}
    </button>
    <div v-if="showTooltip || showTooltipOnClick" class="tooltip fade show" :class="'bs-tooltip-' + direction" @click.prevent="showTooltipOnClick = false">
      <div class="arrow" :class="'bs-tooltip-' + direction + '-arrow'"></div>
      <div class="tooltip-inner"><slot /></div>
    </div>
  </span>
</template>
<script>
    export default {
      name: 'Tooltip',
        props: {
            button: Object,
            direction: String
        },
        data() {
            return {
                showTooltip: false,
                showTooltipOnClick: false
            }
        }
    }
</script>
<style lang="scss">
  .bs-tooltip-top {
    top: -3.5rem;
    left: 15px;
    &-arrow {
      left: 45px;
    }
  }
  .bs-tooltip-bottom {
    top: 1.5rem;
    left: 15px;
    &-arrow {
      left: 60px;
    }
  }
  .bs-tooltip-right {
    top: -0.5rem;
    right: -130px;
    &-arrow {
      top: 7px;
    }
  }
  .bs-tooltip-left {
    top: -0.5rem;
    left: -110px;
    &-arrow {
      top: 7px;
    }
  }
</style>
