<template>
  <div class="col-lg-4">
    <div class="panel" :class="classes">
      <div class="panel-heading">
        <h3 class="panel-title">{{params.title}}</h3>
      </div>
      <div class="panel-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
    export default {
      name: 'Panel',
        props: {
            params: Object,
        },
        computed: {
            classes() {
                let result = 'panel-' + this.params.type;
                if (this.params.type !== 'default') {
                    result += ' panel-color';
                }
                return result;
            }
        }
    }
</script>