export const hotSpotsList = [
  'role_all',
  'organisations',
  'user_all',
  'container_type',
  'garbage_type',
  'platform_type',
  'failure_reason',
  'early completion',
  'breakdown_type',
  'weight_cancel_reason',
  'list_sensor_containers',
  'reasons_stopped_container',
  'reasons_for_the_application',
  'navigation_systems',
  'divisions_organisation',
  'reason_for_transferring_kp',
  'inventory',
  'inventory_platform',
  'online_monitoring',
  'online_monitoring_photo',
  'vehicle',
  'polygon',
  'online_list',
  'monitoring_vehicle',
  'report-work-drivers',
  'Integration',
  'Integrations',
  'Integration_element_technology',
  'vehicle_sensor',
  'all_list_sensor_components',
  'install_documents',
  'instruction_documents',
  'fichas_update',
  'task_installer',
  'command_gserver',
  'list_logs',
  'organisation_settings',
  'report_delay',
  'localities_area',
  'municipal_association',
  'incpectors_district',
  'sub_category',
];

export const stateMenuList = [
  {
    routeName: 'directories-menu',
    titleMenu: 'Cправочники',
    value: 'Cправочники',
    label: 'Cправочники',
  },
  {
    routeName: 'inventory-menu',
    titleMenu: 'Инвентаризация',
    value: 'Инвентаризация',
    label: 'Инвентаризация',
  },
  {
    routeName: 'fact-menu',
    titleMenu: 'Факт',
    value: 'Факт',
    label: 'Факт',
  },
  {
    routeName: 'logistic-menu',
    titleMenu: 'Логистика',
    value: 'Логистика',
    label: 'Логистика',
  },
  {
    routeName: 'reports-menu',
    titleMenu: 'Отчёты',
    value: 'Отчёты',
    label: 'Отчёты',
  },
  {
    routeName: 'installer-menu',
    titleMenu: 'Установщик',
    value: 'Установщик',
    label: 'Установщик',
  },
  {
    routeName: 'report-on-sz',
    titleMenu: 'Отчёт по СЗ',
    value: 'Отчёт по СЗ',
    label: 'Отчёт по СЗ',
  },
];