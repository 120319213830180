import { request } from "@/_helpers";

export const inventory_edited_platform = {
    namespaced: true,
    state: {
        platform: null,
    },
    getters: {
      GET_PLATFORM: state => {
        return state.platform
      },
    },
    actions: {
      SET_PLATFORM({ commit }, payload) {
        commit('SET_PLATFORM', payload)
      },
      CLEAR_PLATFORM({ commit }) {
        commit('SET_PLATFORM', null)
      },
      PUT_EDIT_PLATFORM({ commit, state }, ) {
        const sendData = state.platform;
        sendData.is_open = sendData.is_open?.value;
        sendData.container_platform_type_id = sendData.container_platform_type_id?.id;
        sendData.length = Number(sendData.length);
        sendData.width = Number(sendData.width);
        sendData.latitude = sendData.coordinates.latitude;
        sendData.longitude = sendData.coordinates.longitude;
        delete sendData.datetime;
        return request(`${process.env.VUE_APP_WN}/platform_card/${sendData.id}`, 'put', sendData);
      }
    },
    mutations: {
      SET_PLATFORM(store, platform) {
        if (platform) {
          platform.comment = platform.comment? platform.comment: '';
        }

        store.platform = platform;
      },
    }
};