<template>
  <div class="restyle-header" id="restyle-mobile-header-in-layout">
    <div class="restyle-header-content" id="restyle-mobile-header-content">
      <!-- здесь телепортом кидаем контент -->
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.restyle-header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 26px;
  grid-row-gap: 0px;
  min-height: 52px;
  align-items: center;
  margin-bottom: 15px;
}

.restyle-header-content {
  height: 100%;
}
</style>