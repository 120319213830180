import {authHeader} from "./auth-header";
import axios from 'axios';
import store from '../_store';
import {router} from './router'


export function request(host, method, data = {}, headers, responseType, signal, options = {}) {
    return new Promise((resolve, reject) => {
        let logout = localStorage.getItem('logout');
        let allow =
          host.includes('login') ||
          host.includes('password') ||
          host.includes('logout') ||
          host.includes('auth/remove/request') ||
          host.includes('auth/remove/approve_code') ||
          host.includes('auth/remove/approve_hash');
        if(!logout || allow) {
            headers = (headers) ? headers : authHeader();
            axios({method: method, url: host, data: data, headers: headers, responseType: responseType, signal})
                .then(response => {
                    let token = response.headers.token;
                    if(token && token !== localStorage.getItem('auth_token')) {
                        localStorage.setItem('auth_token', token);
                        store.dispatch('user/setUser')
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    if (error.response) {
                        if(error.response.status === 401 && host.indexOf('logout') > -1) {
                            localStorage.removeItem('auth_token');
                            window.document.cookie = !Boolean(process.env.VUE_APP_LOCAL_DEV)? 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;domain=smartro.ru;': 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;';
                            router.push({name: 'login'});
                            reject(error);
                        }

                        if(error.response.status === 401) {
                            localStorage.removeItem('auth_token');
                            localStorage.setItem('logout', '1');
                            window.document.cookie = !Boolean(process.env.VUE_APP_LOCAL_DEV)? 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;domain=smartro.ru;': 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;';
                            if(router.currentRoute.name !== 'login')
                                router.push({name: 'login', query: {redirect: router.currentRoute.fullPath}})
                        }

                        if (error.response.status === 403) {
                            if (host.toLowerCase().includes(`${process.env.VUE_APP_AUTH_URL}`)) {
                                localStorage.removeItem('auth_token');
                                localStorage.setItem('logout', '1');
                                window.document.cookie = !Boolean(process.env.VUE_APP_LOCAL_DEV)? 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;domain=smartro.ru;': 'auth_token=;Max-Age=-1;path=/;secure=true;samesite=none;';
                                if(router.currentRoute.name !== 'login')
                                    router.push({name: 'login', query: {redirect: router.currentRoute.fullPath}})
                            }
                        }

                        if (error.response.status >= 500) {
                          store.dispatch('error/handleServerError');
                        } else if (error.response.data) {
                          let message = error.response.data.message;
                          if (message) {
                            let alertOptions = { type: 'danger', message };
                            if (options.noAlertTitle) {
                              alertOptions.hasTitle = false;
                            }
                            store.dispatch('alert/alerts', alertOptions);
                          }
                        }

                    }

                    reject(error);
                })
        }
    })
}
