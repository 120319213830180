<template>
  <li :class="{'mm-active' : show}" >
    <a href="#" :class="{'active': active}" @click.prevent="show = !show">
      <i :class="icon" v-if="icon"></i><span>{{title}}</span><span class="ti-angle-right"></span>
    </a>
    <ul class="nav-second-level collapse" :class="{'in' : show}">
      <slot />
    </ul>
  </li>
</template>
<script>
  export default {
      props: {
          section: String,
          title: String,
          icon: String
      },
      data() {
          return {
              show: false
          }
      },
      computed: {
          active() {
              return (this.section === this.$route.meta.section);
          }
      }
  }
</script>
<style lang="scss" scoped>
  .in {
    display: block;
  }
</style>
