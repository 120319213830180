const initialSizeOnlineMonitoring = () => {
    let result = localStorage.getItem('size_online_monitoring');
    
    if (result) {
        result = result != 'small'? 'online_monitoring_full': 'online_monitoring';
    } else {
        result = 'online_monitoring'
    }

    return result
}

export const navigation = {
    namespaced: true,
    state: {
        sizeOnlineMonitoring: initialSizeOnlineMonitoring(),
    },
    getters: {
        sizeOnlineMonitoring: state => {
            return state.sizeOnlineMonitoring
        },
    },
    actions: {
        sizeOnlineMonitoring(store) {
          store.commit('sizeOnlineMonitoring')
        }
    },
    mutations: {
        sizeOnlineMonitoring(store) {
          store.sizeOnlineMonitoring = localStorage.getItem('size_online_monitoring') != 'small'? 'online_monitoring_full': 'online_monitoring';
        }
    }
};