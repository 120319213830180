<template>
  <div class="restyle-textarea-box">
    <div @click="clearValue()" class="restyle-textarea-cross" :style="$attrs.styleCross" v-if="valueTextArea">
      <img src="@/assets/restyle/images/shared/textarea/cross.svg" alt="">
    </div>
    <textarea
      @input="textAreaChange" 
      :value="valueTextArea"
      :rows="$attrs.rows"
      :cols="$attrs.cols"
      :wrap="$attrs.wrap"
      class="restyle-textarea"
      :class="{
        'restyle-textarea--white': whiteBG,
        'scrollbar scrollbar--secondary': hasScroll,
        'focus-textarea': enableFocus,
        'focus-textarea--blue': isInputStyle,
        'no-border': isInputStyle && !valueTextArea,
        'hover': isInputStyle,
      }"
      :maxlength="$attrs.maxlength"
      :style="$attrs.styleTextarea"
      :id="customId"
    >
  
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'RestyleTextarea',
  props: {
    valueTextArea: {
      required: true,
      default: '',
    },
    whiteBG: {
      type: Boolean,
      default: false,
    },
    hasScroll: {
      type: Boolean,
      default: false,
    },
    enableFocus: {
      type: Boolean,
      default: false,
    },
    isInputStyle: {
      type: Boolean,
      default: false,
    },
    customId: {
      type: String,
    },
  },
  model: {
    prop: 'valueTextArea',
    event: 'textAreaChange',
  },
  methods: {
    textAreaChange(event) {
      const { 
        target: {
          value
        }
      } = event;

      this.$emit('textAreaChange', value);
    },
    clearValue() {
      this.$emit('textDeleted');
      this.$emit('textAreaChange', '');
    },
  },
}
</script>

<style lang="scss" scoped>

  .restyle-textarea-box {
    position: relative;
  }

  .restyle-textarea {
    padding: 12px 63px 12px 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #63BFCD;
    background: var(--field-color, #EDFAFA);
    color: #4F4B85;
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--white {
      background: #FFF;
      border: 1px solid #D1D9E6;
    }
  }

  .restyle-textarea-cross {
    position: absolute;
    top: 17px;
    right: 20px;
    display: flex;
    cursor: pointer;
    width: 17px;
  }
  
  .no-border {
    border: none;
  }

  .focus-textarea:focus {
    border: 1px solid #44FF8F;
  }

  .focus-textarea--blue:focus {
    border: 1px solid #63BFCD;
  }

  .hover:hover {
    border: 1px solid #63BFCD;
  }

</style>