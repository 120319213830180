import {request} from "../_helpers";

export const permissions = {
    namespaced: true,
    state: {
        permissions: []
    },
    getters: {
        all: state => {
            return state.permissions
        }
    },
    actions: {
        all: ({commit}) => {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/permission`, 'get')
                    .then(response => {
                        commit('all', response.data);
                        resolve(response);
                    })
            })
        },
    },
    mutations: {
        all(state, permissions) {
            state.permissions = permissions;
        },
    }
}
