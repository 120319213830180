<template>
  <div
    class="restyle-tooltip"
    :style="{
      left: tooltipLeftCoordinate + 'px',
      top: tooltipTopCoordinate + 'px',
      visibility: isTooltipReady && (isImageReady || !tooltipTail) ? 'visible' : 'hidden'
    }"
    ref="tooltip"
  >
    <slot name="tooltip-text">
      <span v-html="tooltipText"></span>
    </slot>
    <img
      v-if="tooltipTail"
      @load="isImageReady = true"
      src="@/assets/restyle/images/shared/tooltip/tooltip_tail.svg"
      :class="[tooltipPosition ? `tooltip-tail--${tooltipPosition}`: '']"
      :style="{ 'left': tailLeftCoordinate + 'px', 'top': tailTopCoordinate + 'px' }"
      class="tooltip-tail"
      alt=""
    >
  </div>
</template>

<script>
  // Передаём в тултип текст и эвент наведения на элемент
  // На тултип в родительском компоненте ставим v-if="hoverEvent
  // Можно сместить тултип с помощью tooltipLeftShift и tooltipTopShift
  export default {
    name: 'RestyleTooltip',
    props: {
      tooltipText: {
        type: String,
      },
      hoverEvent: {
        default: null,
        required: true,
      },
      tooltipLeftShift: {
        type: Number,
        default: 0
      },
      tooltipTopShift: {
        type: Number,
        default: 0
      },
      tooltipDelay: {
        type: Number,
        default: 0,
      },
      tooltipTail: {
        type: Boolean,
        default: true,
      },
      tooltipPosition: {
        type: String,
        default: 'top',
      }
    },
    data() {
      return {
        tooltipLeftCoordinate: 0,
        tooltipTopCoordinate: 0,
        tailLeftCoordinate: 0,
        tailTopCoordinate: 0,
        tooltipTimeoutId: null,
        isTooltipReady: false,
        isImageReady: false,
      }
    },
    watch: {
      hoverEvent(newValue) {
        clearTimeout(this.tooltipTimeoutId);
        this.$nextTick(() => {
          this.tooltipUpdate(this.hoverEvent);
        })
      }
    },
    mounted() {
      this.tooltipUpdate(this.hoverEvent);
    },
    beforeDestroy() {
      clearTimeout(this.tooltipTimeoutId);
    },
    methods: {
      tooltipUpdate(event) {
        if (!event) {
          return;
        }
        if (this.tooltipDelay != 0) {
          this.tooltipTimeoutId = setTimeout(() => {
            this.updateCoordinates(event);
          }, this.tooltipDelay);
        } else {
          this.updateCoordinates(event);
        }
      },
      updateCoordinates(event) {
        const targetElementRect = event.target.getBoundingClientRect();
        const tooltipElement = this.$refs.tooltip;
        const tooltipRect = tooltipElement.getBoundingClientRect();
        if (this.tooltipPosition === 'top') {
          this.tooltipLeftCoordinate = targetElementRect.left + (targetElementRect.width - tooltipRect.width) / 2 + this.tooltipLeftShift;
          this.tooltipTopCoordinate = targetElementRect.top - tooltipRect.height - 15 + this.tooltipTopShift;
          this.checkifFitsViewport(tooltipRect);
          this.tailLeftCoordinate = targetElementRect.left + (targetElementRect.width / 2) + this.tooltipLeftShift - 7;
          this.tailTopCoordinate = targetElementRect.top + this.tooltipTopShift - 16;
        }
        if (this.tooltipPosition === 'bottom') {
          this.tooltipLeftCoordinate = targetElementRect.left + (targetElementRect.width - tooltipRect.width) / 2 + this.tooltipLeftShift;
          this.tooltipTopCoordinate = targetElementRect.bottom + 15 + this.tooltipTopShift;
          this.checkifFitsViewport(tooltipRect);
          this.tailLeftCoordinate = targetElementRect.left + (targetElementRect.width / 2) - 6 + this.tooltipLeftShift;
          this.tailTopCoordinate = targetElementRect.bottom + 5 + this.tooltipTopShift;
        }
        if (this.tooltipPosition === 'right') {
          this.tooltipLeftCoordinate = targetElementRect.right + 15 + this.tooltipLeftShift;
          this.tooltipTopCoordinate = targetElementRect.top + (targetElementRect.height - tooltipRect.height) / 2 + this.tooltipTopShift;
          this.tailLeftCoordinate = targetElementRect.right + 3 + this.tooltipLeftShift;
          this.tailTopCoordinate = targetElementRect.top + (targetElementRect.height / 2) - 6 + this.tooltipTopShift;
        } 
        if (this.tooltipPosition === 'left') {
          this.tooltipLeftCoordinate = targetElementRect.left - tooltipRect.width - 15 + this.tooltipLeftShift;
          this.tooltipTopCoordinate = targetElementRect.top + (targetElementRect.height - tooltipRect.height) / 2 + this.tooltipTopShift;
          this.tailLeftCoordinate = targetElementRect.left - 17 + this.tooltipLeftShift;
          this.tailTopCoordinate = targetElementRect.top + (targetElementRect.height / 2) - 6 + this.tooltipTopShift;
        }
        this.isTooltipReady = true;
      },
      checkifFitsViewport(tooltipRect) {
        // Проверка, выходит ли тултип за правую границу
        if (this.tooltipLeftCoordinate + tooltipRect.width > window.innerWidth) {
          this.tooltipLeftCoordinate = window.innerWidth - tooltipRect.width - 10;
        }
        // Проверка, выходит ли тултип за левую границу
        if (this.tooltipLeftCoordinate < 0) {
          this.tooltipLeftCoordinate = 10;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .restyle-tooltip {
    padding: 8px 11px;
    background: $color-tooltip;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-restyle-white;
    position: fixed;
    z-index: 1050;
    overflow-wrap: break-word;
    max-width: 400px;
  }

  .tooltip-tail {
    position: fixed;


    &--right {
      transform: rotate(90deg);
    }

    &--left {
      transform: rotate(-90deg);
    }

    &--bottom {
      transform: rotate(180deg);
    }

  }
</style>