<template>
  <div class="arrow-wrapper" @click="$emit('click')">
    <img class="arrow-icon" :class="{ 'turn-down': expandList }" src="@/assets/restyle/images/shared/arrow_right.svg" alt="" :style="isWhiteColorArrow ? { filter: 'brightness(0) invert(1)' } : {}">
  </div>
</template>

<script>
  export default {
    name: 'ArrowToggleButton',
    props: {
      expandList: {
        type: Boolean,
        required: true,
        default: false,
      },
      isWhiteColorArrow: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

.arrow-icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  flex-grow: 0;
}

.arrow-wrapper {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.turn-down {
  transform: rotate(90deg);
}

</style>