export const mobile_menu = {
  namespaced: true,
  state: {
    isActiveScroll: false,
    isVisibleUserCreate: false,
    isVisibleUserEdit: false,
    isChangeUserForm: false,
    isChangeUserShowModal: false,
    isVisibleVehicleCreate: false,
    isVisibleVehicleEdit: false,
    isVisibleVehicleDetailPage: false,
    isVisibleInstallationKitBinding: false,
    isVisibleComponentInstKit: false,
  },
  getters: {
    GET_IS_ACTIVE_SCROLL: (state) => {
      return state.isActiveScroll;
    },
    GET_IS_VISIBLE_USER_CREATE: (state) => {
      return state.isVisibleUserCreate;
    },
    GET_IS_VISIBLE_USER_EDIT: (state) => {
      return state.isVisibleUserEdit;
    },
    GET_IS_CHANGE_USER_FORM(state) {
      return state.isChangeUserForm;
    },
    GET_IS_CHANGE_USER_SHOW_MODAL(state) {
      return state.isChangeUserShowModal;
    },
    GET_IS_VISIBLE_VEHICLE_CREATE(state) {
      return state.isVisibleVehicleCreate;
    },
    GET_IS_VISIBLE_VEHICLE_EDIT(state) {
      return state.isVisibleVehicleEdit;
    },
    GET_IS_VISIBLE_VEHICLE_DETAIL_PAGE(state) {
      return state.isVisibleVehicleDetailPage;
    },
    GET_IS_VISIBLE_INSTALLATION(state) {
      return state.isVisibleInstallationKitBinding;
    },
    GET_IS_VISIBLE_COMPONENT_INST_KIT(state) {
      return state.isVisibleComponentInstKit;
    },
  },
  actions: {},
  mutations: {
    SET_IS_ACTIVE_SCROLL(store, payload = false) {
      store.isActiveScroll = payload;
    },
    SET_IS_VISIBLE_USER_CREATE(store, payload = false) {
      store.isVisibleUserCreate = payload;
    },
    SET_IS_VISIBLE_USER_EDIT(store, payload = false) {
      store.isVisibleUserEdit = payload;
    },
    SET_IS_CHANGE_USER_FORM(store, payload) {
      store.isChangeUserForm = payload;
    },
    SET_IS_CHANGE_USER_SHOW_MODAL(store, payload) {
      store.isChangeUserShowModal = payload;
    },
    SET_IS_VISIBLE_VEHICLE_CREATE(store, payload) {
      store.isVisibleVehicleCreate = payload;
    },
    SET_IS_VISIBLE_VEHICLE_EDIT(store, payload) {
      store.isVisibleVehicleEdit = payload;
    },
    SET_IS_VISIBLE_VEHICLE_DETAIL_PAGE(store, payload) {
      store.isVisibleVehicleDetailPage = payload;
    },
    SET_IS_VISIBLE_INSTALLATION(store, payload) {
      store.isVisibleInstallationKitBinding = payload;
    },
    SET_IS_VISIBLE_COMPONENT_INST_KIT(store, payload) {
      store.isVisibleComponentInstKit = payload;
    },
  },
};
