export const clickOutside = {
  name: 'click-outside',
  directive: {
    bind(el, binding) {
      el._clickHandler = event => {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event);
        }
      };
      document.body.addEventListener('click', el._clickHandler, true);
    },
    unbind(el) {
      document.body.removeEventListener('click', el._clickHandler, true);
      delete el._clickHandler;
    }
  }
}