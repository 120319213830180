import Gserver from "@/pages/demo/gserver/gserver.vue";
import {request} from "../_helpers";

export const tracksGserver = {
  namespaced: true,
  state: {
    route: null,
    controller: null,
    isLoading: false,
    GserverTrackWorkOrder: null,
  },
  getters: {
    itemTracks: state => {
      return state.route;
    },
    isLoading: state => {
      return state.isLoading;
    },
    getViewGserverTrackWorkOrder: state => {
      return state.GserverTrackWorkOrder
    }
  },
  actions: {
    fetchTracksGserver({ state, commit }, payload) {
      commit('setGserverTrackWorkOrder', payload.wo.id)
      if (state.controller) {
        commit('abortController');
      }
      if (payload?.wo) {
        commit('setController', new AbortController());
        commit('setLoading', true);
        request(`${process.env.VUE_APP_WN}/fact/track_gserver`, "post", {
          workorder_id: payload.wo.id,
        }, null, null, state.controller.signal)
          .then((res) => {
            if (res.success) {
              commit('setTracksGserver', res.data)
            } else {
              commit('setTracksGserver', null)
            }
          })
          .catch(() => {
            commit('setTracksGserver', null)
          })
          .finally(() => {
            commit('setLoading', false);
          })
      } else {
        commit('setTracksGserver', null)
      }
    },
  },
  mutations: {
    setTracksGserver(state, itemTracks) {
      state.route = itemTracks;
    },
    abortController(state) {
      state.controller.abort();
    },
    setController(state, payload) {
      state.controller = payload;
    },
    setLoading(state, payload) {
      state.isLoading = payload;
    },
    setGserverTrackWorkOrder(state, payload) {
      state.GserverTrackWorkOrder = payload;
    },
  }
};