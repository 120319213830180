function getFormattedDate(date) {
  return date.toISOString().substring(0, 10);
}

const emptyFilters = {
  srp_id: '',
  address: '',
  applied: false,
};
const emptyFiltersUnlinkedPlatforms = {
  claimId: '',
  areaId: '',
  containerCount: '',
  areaType: [],
  containerType: [],
  schedule: [],
};
const emptyFiltersFailuresPeriod = {
  srp_id: '',
  address: '',
  reason_name: '',
  containers_count: '',
};
const emptyFiltersHeaderFrame = {
  srp_id: '',
};

export const online_list = {
  namespaced: true,
  state: {
    hiddenColumnKeys: [],
    dates: {
      start: getFormattedDate(new Date()),
      end: getFormattedDate(new Date()),
    },
    tableLoading: false,
    photoModal: {
      isOpen: false,
      address: null,
      platform_id: null,
      platform_srp_id: null,
      icon: 'no_icon',
      state: null,
    },
    loadTable: { status: false, resetPage: true },
    clearTable: false,
    filters: emptyFilters,
    filtersUnlinkedPlatforms: emptyFiltersUnlinkedPlatforms,
    filtersFailuresPeriod: emptyFiltersFailuresPeriod,
    filtersHeaderFrame: emptyFiltersHeaderFrame,
    selectOptionsUnlinkedPlatforms: {
      areaType: [],
      containerType: [],
      schedule: [],
    },
    areUnlinkedPlatformsOpen: false,
    areFailuresPeriodOpen: false,
    pickedPlatform: null,
    confirmationModal: {
      isOpen: false,
      type: '',
    },
    tableLinesToEdit: [],
  },
  getters: {
    hiddenColumnKeys: state => state.hiddenColumnKeys,
    dates: state => state.dates,
    tableLoading: state => state.tableLoading,
    photoModal: state => state.photoModal,
    loadTable: state => state.loadTable,
    clearTable: state => state.clearTable,
    filters: state => state.filters,
    filtersUnlinkedPlatforms: state => state.filtersUnlinkedPlatforms,
    filtersFailuresPeriod: state => state.filtersFailuresPeriod,
    filtersHeaderFrame: state => state.filtersHeaderFrame,
    selectOptionsUnlinkedPlatforms: state => state.selectOptionsUnlinkedPlatforms,
    areUnlinkedPlatformsOpen: state => state.areUnlinkedPlatformsOpen,
    areFailuresPeriodOpen: state => state.areFailuresPeriodOpen,
    confirmationModal: state => state.confirmationModal,
    pickedPlatform: state => state.pickedPlatform,
    tableLinesToEdit: state => state.tableLinesToEdit,
  },
  actions: {
    getHiddenColumnKeys({ commit, dispatch }) {
      const hiddenColumnKeys = localStorage.getItem('onlineList_hiddenColumnKeys');
      if (!hiddenColumnKeys) {
        commit('setHiddenColumnKeys', []);
        dispatch('updateLocalStorage');
      } else {
        commit('setHiddenColumnKeys', JSON.parse(hiddenColumnKeys));
      }
    },
    hideColumn({ commit, dispatch, state }, key) {
      if (!state.hiddenColumnKeys.includes(key)) {
        commit('hideColumn', key);
        dispatch('updateLocalStorage');
      }
    },
    clearHiddenColumns({ commit, dispatch }) {
      commit('clearHiddenColumns');
      dispatch('updateLocalStorage');
    },
    showColumn({ commit, dispatch }, key) {
      commit('showColumn', key);
      dispatch('updateLocalStorage');
    },
    toggleColumnVisibility({ commit, state, dispatch }, key) {
      if (state.hiddenColumnKeys.includes(key)) {
        commit('showColumn', key);
      } else {
        commit('hideColumn', key);
      }
      dispatch('updateLocalStorage');
    },
    updateLocalStorage({ state }) {
      localStorage.setItem('onlineList_hiddenColumnKeys', JSON.stringify(state.hiddenColumnKeys));
    },
    setDate({ commit }, payload) {
      commit('setDate', payload);
    },
    setTableLoading({ commit }, payload) {
      commit('setTableLoading', payload);
    },
    setPhotoModal({ commit }, payload) {
      commit('setPhotoModal', payload);
    },
    loadTable({ commit }, payload) {
      commit('loadTable', payload);
    },
    loadTableDone({ commit }) {
      commit('loadTableDone');
    },
    clearTable({ commit }) {
      commit('clearTable');
    },
    clearTableDone({ commit }) {
      commit('clearTableDone');
    },
    changeFilter({ commit }, payload) {
      commit('changeFilter', payload);
    },
    clearFilter({ commit }) {
      commit('clearFilter');
    },
    changeFilterUnlinkedPlatforms({ commit }, payload) {
      commit('changeFilterUnlinkedPlatforms', payload);
    },
    clearFilterUnlinkedPlatforms({ commit }) {
      commit('clearFilterUnlinkedPlatforms');
    },
    changeFilterFailuresPeriod({ commit }, payload) {
      commit('changeFilterFailuresPeriod', payload);
    },
    clearFiltersFailuresPeriod({ commit }) {
      commit('clearFiltersFailuresPeriod');
    },
    changeFiltersHeaderFrame({ commit }, payload) {
      commit('changeFiltersHeaderFrame', payload);
    },
    clearFiltersHeaderFrame({ commit }) {
      commit('clearFiltersHeaderFrame');
    },
    openUnlinkedPlatforms({ commit }) {
      commit('openUnlinkedPlatforms');
    },
    closeUnlinkedPlatforms({ commit }) {
      commit('closeUnlinkedPlatforms');
    },
    changeSelectOptionsUnlinkedPlatforms({ commit }, payload) {
      commit('changeSelectOptionsUnlinkedPlatforms', payload);
    },
    changePickedPlatform({ commit }, payload) {
      commit('changePickedPlatform', payload);
    },
    openFailuresPeriod({ commit }) {
      commit('openFailuresPeriod');
    },
    closeFailuresPeriod({ commit }) {
      commit('closeFailuresPeriod');
    },
    changeConfirmationModal({ commit }, payload) {
      commit('changeConfirmationModal', payload);
    },
    editTableLines({ commit }, payload) {
      commit('editTableLines', payload);
    },
  },
  mutations: {
    setHiddenColumnKeys(state, hiddenColumnKeys) {
      state.hiddenColumnKeys = hiddenColumnKeys;
    },
    clearHiddenColumns(state) {
      state.hiddenColumnKeys = [];
    },
    hideColumn(state, key) {
      state.hiddenColumnKeys.push(key);
    },
    showColumn(state, key) {
      state.hiddenColumnKeys = state.hiddenColumnKeys.filter(el => el !== key);
    },
    setDate(state, payload) {
      state.dates.start = payload.start;
      state.dates.end = payload.end;
    },
    setTableLoading(state, payload) {
      state.tableLoading = payload;
    },
    setPhotoModal(state, payload) {
      state.photoModal = payload;
    },
    loadTable(state, payload) {
      state.loadTable = payload;
    },
    loadTableDone(state) {
      state.loadTable.status = false;
    },
    clearTable(state) {
      state.clearTable = true;
    },
    clearTableDone(state) {
      state.clearTable = false;
    },
    changeFilter(state, payload) {
      state.filters = payload;
    },
    clearFilter(state) {
      state.filters = emptyFilters;
    },
    changeFilterUnlinkedPlatforms(state, payload) {
      state.filtersUnlinkedPlatforms = payload;
    },
    clearFilterUnlinkedPlatforms(state) {
      state.filtersUnlinkedPlatforms = emptyFiltersUnlinkedPlatforms;
    },
    changeFilterFailuresPeriod(state, payload) {
      state.filtersFailuresPeriod = payload;
    },
    clearFilterFailuresPeriod(state) {
      state.filtersFailuresPeriod = emptyFiltersFailuresPeriod;
    },
    changeFiltersHeaderFrame(state, payload) {
      state.filtersHeaderFrame = payload;
    },
    clearFiltersHeaderFrame(state) {
      state.filtersHeaderFrame = emptyFiltersHeaderFrame;
    },
    openUnlinkedPlatforms(state) {
      state.areUnlinkedPlatformsOpen = true;
    },
    closeUnlinkedPlatforms(state) {
      state.areUnlinkedPlatformsOpen = false;
    },
    changeSelectOptionsUnlinkedPlatforms(state, payload) {
      state.selectOptionsUnlinkedPlatforms = payload;
    },
    changePickedPlatform(state, payload) {
      state.pickedPlatform = payload;
    },
    openFailuresPeriod(state) {
      state.areFailuresPeriodOpen = true;
    },
    closeFailuresPeriod(state) {
      state.areFailuresPeriodOpen = false;
    },
    changeConfirmationModal(state, payload) {
      state.confirmationModal = payload;
    },
    editTableLines(state, payload) {
      state.tableLinesToEdit = payload;
    },
  },
}