<template>
  <div class="checkbox-item" :class="[useType.class]" @click="$emit('change', !checked)">
    <img
      v-if="checked"
      :src="useType.pathImg"
      class="checkbox-clicked"
      alt=""
    >
  </div>
</template>

<script>
const types = {
  '': {
    class: '',
    pathImg: require(`@/assets/restyle/images/shared/icons/checkbox.svg`),
  },
  disabled: {
    class: 'checkbox-item--disabled',
    pathImg: require(`@/assets/restyle/images/shared/icons/checkbox_grey.svg`),
  }
}


export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      required: true,
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    useType() {
      return types[this.type];
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 2px solid $color-success;
    border-radius: 3px;
    cursor: pointer;

    &--disabled {
      border: 2px solid #D1D9E6;
    }
  }
  
</style>