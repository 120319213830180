<template>
  <div class="restyle-alert" :style="alertBoxStyle">
    <div class="restyle-alert-close" @click="$emit('clear')">
      <img :src="require(`@/assets/restyle/images/close_alert_${alerts[0]?.type}.svg`)" alt="" />
    </div>
    <div class="restyle-alert-content">
      <div class="description" v-for="(alert, index) of alerts" :key="index">
        <span class="mr-2">
          <img
            :src="require(`@/assets/restyle/images/alert_${alert.type}.svg`)"
            alt=""
          />
        </span>
        <span><strong v-if="alert.hasTitle !== false">{{ types[alert.type]?.title }}</strong>{{ alert.message }}</span>
        <slot v-if="alert.slot">
          <div v-html="alert.slot">
          </div>
        </slot>        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LightThemeAlert",
  props: {
    alerts: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      types: {
        success: {
          title: 'Успешно!',
          color: '#63bfcd',
        },
        danger: {
          title: 'Ошибка!',
          color: '#EB6363',
        },
      },
    }
  },
  computed: {
    alertBoxStyle() {
      return { border: `1px solid ${this.types[this.alerts[0]?.type]?.color}` };
    },
  },
};
</script>

<style lang="scss" scoped>
.restyle-alert {
  z-index: 1052;
  padding: 20px 41px 20px 20px;
  position: fixed;
  right: 70px;
  bottom: 46px;
  max-width: 400px;
  color: #1d1871;
  background: #ffffff;
  border: 1px solid #63bfcd;
  box-shadow: 0px 8px 21px 4px rgba(129, 184, 235, 0.15);
  border-radius: 10px;
  overflow-wrap: break-word;
}

.restyle-alert-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  & img {
    vertical-align: top;
  }
}

.restyle-alert-content {
  & .description {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #1d1871;
  }

  & strong {
    margin-right: 5px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #1D1871;
  }
}

@media (max-width: 575px) {
  .restyle-alert {
    padding: 15px;
    padding-left: 20px;
    font-size: 16px;
    transform: translateX(-50%);
    left: 50%;
    width: calc(100% - 40px);
  }
}
</style>