<template>
  <header class="topbar" :class="{'topbar-open-logout': userMenu}">
    <!-- <div class="topbar-left">
      <span class="logo d-none d-md-block" v-if="$route.name === 'home'">{{logo}}</span>
      <router-link  :to="{name: 'home'}" v-else class="logo d-none d-md-block">{{logo}}</router-link>
      <span class="logo d-md-none small" v-if="$route.name === 'home'">{{logo_sm}}</span>
      <router-link :to="{name: 'home'}" v-else class="logo d-md-none small">{{logo_sm}}</router-link>
    </div> -->
    <nav class="navbar-custom">
      <ul class="list-unstyled topbar-right-menu float-right mb-0">
        <li class="dropdown notification-list show"><user-menu /></li>
      </ul>
      <!-- <ul class="list-inline menu-left mb-0">
        <li class="float-left">
          <button id="nav-toggle" class="button-menu-mobile open-left waves-light waves-effect" @click.prevent="$store.dispatch('show_nav')" :class="{'active' : isVisibleIconClose}">
            <span></span>
          </button>
        </li>
      </ul> -->
    </nav>
  </header>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import userMenu from '@/components/UserMenu'
  export default {
      name: 'Header',
      components: {
          userMenu
      },
      watch: {
        $route() {
          console.log(`route`, this.$route);
        }
      },
      mounted() {
        console.log(`mounter header`, this.$route);
      },
      computed: {
          ...mapState(['showNav', 'logo', 'logo_sm', 'isVisibleIconClose', 'userMenu'])},
      methods: {
          ...mapMutations(['CHANGE_SHOW_NAV'])
      }

  }
</script>

<style lang="scss">
  .topbar {
    z-index: 0;
    background-color: #f8fbfa;

    & * {
      background-color: #f8fbfa;
    }

    &-open-logout {
      z-index: 1;
    }
  }

#nav-toggle {
  position: relative; 
  display: inline-block; 
  width:25px; 
  height:25px;
  border: none;
  margin-top: 30px;
  }
#nav-toggle span { 
  position: absolute; 
  left: 0; 
  top: 5px; 
  }

#nav-toggle span, 
#nav-toggle span:before,
#nav-toggle span:after {
  position: absolute;
  display: block;
  content: '';
  height: 3px;
  width: 25px;
  background: gray;
  border-radius: 1px;
  cursor: pointer;
}
#nav-toggle span:before {
  top: -7px;
}
#nav-toggle span:after {
  bottom: -7px; 
}

/* Добавим анимацию всех свойств для блоков нашей иконки */
#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  transition: all 500ms ease-in-out;
}

/* Центральный блок делаем прозрачным */
#nav-toggle.active span {
  background-color: transparent;
}

/* Смещаем псевдо-элементы в центр иконки (на место основного блока) */
#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}

/* Наклоняем псевдо-элементы под углом 45 градусов, но в разные стороны */
#nav-toggle.active span:before {
  transform: rotate(45deg);
}
#nav-toggle.active span:after {
  transform: rotate(-45deg);
}
</style>
