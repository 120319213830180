import {request} from "../_helpers";

export const platformTypes = {
    namespaced: true,
    state: {
        platformTypes: []
    },
    getters: {
        current: state => id => {
            const result = state.platformTypes.filter(platformType => {
                return platformType.id === parseInt(id);
            });
            return (result) ? result[0] : null;
        },
        allPlatformTypes: state => {
            return state.platformTypes;
        }
    },
    actions: {
        all({commit}) {
            return new Promise((resolve) => {
                request(`${process.env.VUE_APP_WN}/container_platform_type`, 'get')
                    .then(response => {
                        commit('all', response.data);
                        resolve(response)
                    })
            })
        },
        update({commit, dispatch}, data) {
            return new Promise((resolve) => {
                request(`${process.env.VUE_APP_WN}/container_platform_type/${data.id}`, 'put', data)
                    .then(response => {
                        commit('update', response.data);
                        dispatch('alert/success', {message: `Тип КП ${data.name} успешно обновлён`, title: 'Успешно!'}, {root: true});
                        resolve(response)
                    })
            })
        },
        delete({dispatch}, data) {
            if(confirm(`Вы точно хотите удалить тип КП ${data.name}?`))
                return new Promise((resolve) => {
                    request(`${process.env.VUE_APP_WN}/container_platform_type/${data.id}`, 'delete')
                        .then(response => {
                            dispatch('all');
                            dispatch('alert/success', {message: `Тип КП ${data.name} успешно удалён`, title: 'Успешно!'}, {root: true})
                            resolve(response)
                        })
                })
        },
        create({dispatch}, data) {
            request(`${process.env.VUE_APP_WN}/container_platform_type`, 'post', data)
                .then(response => {
                    dispatch('all');
                    dispatch('alert/success', {title: 'Успешно!', message: `Тип КП "${data.name}" успешно создан!`}, {root: true});
                })
        }
    },
    mutations: {
        all(state, all) {
            state.platformTypes = all;
            state.platformTypes.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        },
        update(state, update) {
            let updateIndex;
            state.platformTypes.forEach((platformType, index) => {
                if(platformType.id === update.id)
                    updateIndex = index;
            });
            state.platformTypes.splice(updateIndex, 1, update);
            state.platformTypes.sort((a, b) => {
                let keyA = parseInt(a.id);
                let keyB = parseInt(b.id);
                if(keyA < keyB) return -1;
                if(keyA > keyB) return 1;
                return 0;
            })
        }
    }
}
