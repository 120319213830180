<template>
  <div class="pb-3">
    <div class="accordion-header pt-3" @click.prevent="toggle">
      <slot name="accordion-header">
        <div class="row mb-1">
          <div class="col-12 col-sm-12">
            <div :class="status(card.status_id)" style="padding-top: 10px;">{{statusText(card.status_id)}}</div>
          </div>
          <div class="col-12 col-sm-12 small">
            <p v-if="card.datetime" class="small mb-0" style="padding-top: 10px;">{{ date_value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 mb-1">
            <span class="font-weight-bold mr-1">Адрес: </span>
            <span v-if="card.address">{{card.address}}</span>
            <span v-else>не задан</span>
          </div>
          <div class="col-12 col-sm-12 mb-1" v-if="card.author" >
            <span class="font-weight-bold mr-1">Автор: </span>
            <span >{{card.author}}</span>
          </div>
          <div class="col-12 col-sm-12 small mb-1">
            <span>Количество контейнеров:</span>
            {{ count_value }}
          </div>
          <div class="col-12 col-sm-12 small mb-1">
            <span class="mr-1">Объем, м<sup>3</sup>:</span>
            {{cp_value}}
          </div>

          <div class="col-12 col-sm-12 small">
            <span class="mr-1">Размер (д*ш), м:</span>
            {{card.length + ' * ' +  card.width}}
          </div>
          
        </div>
      </slot>
    </div>
    <div class="accordion-content">
      <div class="accordion-content--inner mt-1">
        <div class="row mb-1">
          <div class="col-12 col-sm-12 small">
            <span class="mr-1">Открытая:</span>
            <template v-if="card.is_open">да</template>
            <template v-else>нет</template>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-12 col-sm-12 small mb-1">
            <span class="mr-1">Основание:</span>
            <template v-if="card.has_base">есть</template>
            <template v-else>нет</template>
          </div>

          <div class="col-12 col-sm-12 small">
            <span class="mr-1">Ограждение:</span>
            <template v-if="card.has_fence">есть</template>
            <template v-else>нет</template>
          </div>

        </div>
        <div class="row mb-3" v-if="card.comment">
          <div class="col-12 col-sm-12 small">
            <div>Комментарий: </div>
            <div>{{ card.comment }}</div>
          </div>
        </div>
        <div class="row mb-3" v-if="card.container_platform_type_name">
          <div class="col-12 col-sm-12 small">
            <span>Тип площадки: </span>
            <span>{{ card.container_platform_type_name }}</span>
          </div>
        </div>
        <div class="row mb-3" v-if="card.comment">
          <div class="col-12 col-sm-12 small">
            <span>Вид площадки: </span>
            <span>{{ isOpenText(card.is_open) }}</span>
          </div>
        </div>
      </div>
    </div>
      <div class="wrapper-btn-group">
        <router-link 
        :to="{name: 'inventory_platform_view', params: {id: card.id}, query: $route.query}" class="btn btn-info btn-card">
          <i class="mdi mdi-information-variant"></i>
          <span class="ml-1">Подробности</span>
        </router-link>
        <button class="btn btn-danger btn-card" v-if="card.status_id == 1" @click.prevent="rejectCard()">
        Отклонить
        </button>
      </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Photos from '@/components/Photos';

  export default {
      name: 'InventoryCard',
      components: {Photos},
      props: {
          card: Object,
      },
      data() {
          return {
              show: false,
              showModal: false
          };
      },
      computed: {
          ...mapGetters('user', ["userCan"]),
          ...mapGetters('containers', ['container_type', 'types']),
          cp_value() {
              let containers = this.card.container_cards.filter(x => !x.trashed);
              if(containers.length > 0) {
                  return containers.reduce((value, container) => {
                      let v = this.container_type(container.container_type_id);
                      v = (v) ? parseFloat(v.volume) : 0;
                      return Math.round((value + v) * 100) / 100;
                  }, 0)
              } else {
                  return 'Не задан'
              }
          },
          count_value() {
              return (this.card.container_cards.filter(x => !x.trashed).length > 0) ? this.card.container_cards.filter(x => !x.trashed).length : this.card.containers_count;
          },
          date_value() {
              return this.$moment.utc(this.card.datetime.date).format("DD.MM.YYYY HH:mm");
          }
      },
      methods: {
        rejectCard() {
          const consentToDeviate = confirm('Вы действительно хотите отклонить площадку?') // Согласие на отклонение
          if (consentToDeviate) {
           this.setStatus(3);
          }
        },
          toggle(event) {
                this.$emit('toggle', this.card.id)
          },
          isOpenText(val) {
                switch (val) {
                    case 1:
                        return 'Открытая';
                    case 0:
                        return 'Закрытая';
                    default:
                        return ''
                }
          },
          status(val) {
              switch (val) {
                  case 1:
                      return 'text-info';

                  case 2:
                      return 'text-success';

                  case 3:
                      return 'text-danger';

                  default:
                      return ''
              }
          },
          statusText(val) {
              switch (val) {
                  case 1:
                      return 'Новый';

                  case 2:
                      return 'Принятый';

                  case 3:
                      return 'Отклоненный';

                  default:
                      return ''
              }
          },
          setStatus(status) {
              this.$store.dispatch('cards/set_status', {card: this.card, status: status})
                  .finally(() => {
                      this.$emit('update');
                  })
          }
      },
  }
</script>
<style lang="scss" scoped>
  .accordion-header {
    position: relative;
    padding-top: 5px;
    margin-top: 5px;
  }

  .accordion-content--inner {
    overflow-wrap: break-word;
    margin-bottom: 50px;
    /*   white-space: pre-wrap; */
  }

  .accordion-content .btn {
    font-size: 12px;
  }

  .modal-body .btn-rounded {
    padding: 6px 18px;
  }

  .mdi-lead-pencil {
    @media (min-width: 768px) {
      padding: 4px 0;
    }
  }

  .btn-info {
    margin-right: auto;
  }

  .wrapper-btn-group {
    margin-left: -5px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0px;
    left: 15px;
  }
  .row {
    margin: 0;
  }
  .btn-card {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 45%;
    min-width: 120px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
</style>
