var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference-block"},[_c('div',{staticClass:"reference-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'task_installer' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('task_installer'))+" ")]),(_vm.isActiveHotSpot('task_installer')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('task_installer'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'install_documents' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('install_documents'))+" ")]),(_vm.isActiveHotSpot('install_documents')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('install_documents'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'instruction_documents' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('instruction_documents'))+" ")]),(_vm.isActiveHotSpot('instruction_documents')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('instruction_documents'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1),_c('div',{staticClass:"reference-block-link"},[_c('router-link',{staticClass:"links-item",attrs:{"to":{ name: 'fichas_update' }}},[_c('div',{staticClass:"link-flex"},[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getByHotSpotRouteTitleMenu('fichas_update'))+" ")]),(_vm.isActiveHotSpot('fichas_update')?.id)?_c('div',{staticClass:"hotspot",staticStyle:{"margin-left":"-20px"},on:{"mouseenter":function($event){_vm.$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
            hoverEvent: $event,
            tooltipText: _vm.currentTooltipForHotSpot('fichas_update'),
          })},"mouseleave":function($event){return _vm.$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');}}},[_vm._v(" Новое ")]):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }