<template>
  <div class="modal fade" :class="{'show': show}" @click.self="$emit('сancel')">
    <div class="modal-dialog modal-dialog__border" :class="modal_type? `modal-dialog__border_${modal_type}`: `modal-dialog__border_primary`">
      <div class="modal-content">
        <div class="d-flex justify-content-center">
          <div class="col-7">
            <div class="modal-header">
              <h6 class="text-center" v-if="title">{{title}}</h6>
            </div>
          </div>
        </div>
        <div class="modal-body" v-if="$slots.body">
          <div class="d-flex justify-content-center">
            <div class="col-7">
              <p                
                class="text-center"
              ><slot name="body"/></p>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <slot name="footer">
            <button class="btn" :class="modal_type? `btn-${modal_type}`: `btn-primary`" @click="$emit('submit-btn', null)">{{btn_text}}</button>
            <button class="btn btn-border-grey" @click="$emit('сancel')">Отменить</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalConfirm',
  props: {
    modal_type: String,
    btn_text: String,
    title: String,
  },
  data() {
    return {
      show: false,
      contentIndex: null,
    };
  },
  created() {
    document.querySelector("body").classList.add("modal-open");
    this.show = true;
    this.contentIndex = window.document.querySelector('.restyle-content');
    this.contentIndex.style.zIndex = 2;
  },
  beforeDestroy() {
    this.contentIndex.style.zIndex = 1;
    document.querySelector("body").classList.remove("modal-open");
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-dialog {
    &__border {
      z-index: 0;
      border-radius: 10px 10px 0 0;
      &_success {
        border-top: 10px solid $green;
      }
      &_primary {
        border-top: 10px solid $blue;
      }
      &_danger {
        border-top: 10px solid $danger;
      }
    }
  }
}
</style>