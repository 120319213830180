import {request} from "../_helpers";

export const wasteTypes = {
    namespaced: true,
    state: {
      items: [],
      target_waste_type: [],
    },
    getters: {
      get_items: state => {
          return state.items
      },
      target_waste_type: state => {
          return state.target_waste_type
      },
    },
    actions: {
      get_items(store, parameters) {
        request(
          `${process.env.VUE_APP_WN}/rpc`,
          "post",
          {
            'type':'waste_type_list',
            'payload':  {
              organisation_id: Number(parameters.organisation_id)
            },
          }
        )
        .then((res) => {
          if (res.payload) {
            store.commit('get_items', res.payload);
          } else {
            store.dispatch(
              "alert/warning",
              {
                message: `${res.error.message}`,
                title: "Ошибка!",
              },
              { root: true }
            );
          }
        })
      },
      target_waste_type(store, target) {
        store.commit('target_waste_type', target);
      }
    },
    mutations: {
      get_items(store, items) {
        store.items = items
      },
      target_waste_type(store, target) {
        store.target_waste_type = target
      }
    }
};