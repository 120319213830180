<template>
  <div class="component-wrapper">
    <div v-if="search && filteredOptions.length > 0 && isSelectOpen" class="select-options-wrapper">
      <div
        v-for="(option, index) in filteredOptions"
        @click="select(option)"
        :key="index"
        class="select-option"
      >
        {{ option[displayKey] }}
      </div>
    </div>
    <input
      @input="changeSearch"
      @keyup.enter="onEnter"
      class="select-search"
      :value="search"
      :type="inputType"
      v-mask="inputMask"
    />
  </div>
</template>

<script>
  export default {
    name: 'SelectSearchSmall',
    props: {
      options: {
        type: Array,
        required: true,
      },
      displayKey: {
        type: String,
        default: 'name',
      },
      search: {
        type: String,
        required: true,
      },
      inputType: {
        type: String,
        default: 'text',
      },
      inputMask: {
        type: String,
        default: '..........',
      }
    },
    data() {
      return {
        isSelectOpen: false,
        isOptionSelected: false,
      }
    },
    computed: {
      filteredOptions() {
        return this.options.filter((option) => {
          return option[this.displayKey].toLowerCase().startsWith(this.search.toLowerCase());
        }).reverse();
      }
    },
    methods: {
      changeSearch(event) {
        this.$emit('changeSearch', event.target.value);
        if (!this.isOptionSelected) {
          this.isSelectOpen = true;
        }
        this.isOptionSelected = false;
      },
      select(option) {
        this.$emit('changeSearch', option[this.displayKey]);
        this.$emit('selectOption', option[this.displayKey]);
        this.isSelectOpen = false;
        this.isOptionSelected = true;
      },
      onEnter(event) {
        this.$emit('selectOption', event.target.value);
        this.isSelectOpen = false;
      },
    },
  }
</script>

<style lang="scss" scoped>

.select-search {
  width: 70px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #D1D9E6;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(23, 21, 55, 0.25);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #4F4B85;
  &:hover {
    border: 1px solid $color-smartro-green-bright;
  }
  &:focus {
    border: 1px solid $color-smartro-green-bright;
  }
}

.select-options-wrapper {
  position: absolute;
  bottom: 35px;
  // z-index: 1;
  border: 1px solid $color-smartro-green-bright;
  box-shadow: 0px 2px 4px 0px rgba(23, 21, 55, 0.25);
  background: #FFF;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.component-wrapper {
  position: relative;
}

.select-option {
  cursor: pointer;
  &:hover {
    background-color: $color-page-text;
    color: #FFF;
  }
}

</style>