import Vue from "vue";
import * as Sentry from "@sentry/vue";
import store from './_store';

export const initSentry = () => {
  if (!Number(`${process.env.VUE_APP_LOCAL_DEV}`)) {
    const options = {
      Vue,
      dsn: `${process.env.VUE_APP_SENTRY_LARAVEL_DSN}`,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
        }),
      ],
      // Session Replay
      release: `${process.env.VUE_APP_SENTRY_RELEASE}`,
      environment: `${process.env.VUE_APP_SENTRY_ENVIRONMENT}`,
      tracesSampleRate: Number(`${process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE}`),
      replaysSessionSampleRate: Number(`${process.env.VUE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE}`), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: Number(`${process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE}`), // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    }
    Sentry.init(options);

    Sentry.setUser({
      email: store.getters['user/user'].email || `Нет данных`
    })
  }
}