import {
  request
} from "../_helpers";

const update_picked_work_order = (list, payload) => {
  let result = list.map(item => {
    //если элемент picked_work_order = true (уже выбран) и в приходящем массиве id есть совпадения, то элемент делаем не активным
    //если ключ уже true или есть совпадение перебираемого id по id списка заданий присваиваем picked_work_order = true
    //в противном случае проставляется false
    if (item.picked_work_order && payload.includes(item.id)) {
      item.picked_work_order = false;
    } else if (item.picked_work_order || payload.includes(item.id)) {
      item.picked_work_order = true
    } else {
      item.picked_work_order = false
    }

    return item
  })

  return result;
}

const update_picked_container_platforms = (container_platforms, payload) => {
  return container_platforms.map(item => {
    //если элемент picked_container_platforms = true (уже выбран) и в приходящем массиве id есть совпадения, то элемент делаем не активным
    //если ключ уже true или есть совпадение перебираемого id по id списка заданий присваиваем picked_container_platforms = true
    //в противном случае проставляется false 
    if (item.picked_container_platforms && payload.includes(item.id)) {
      item.picked_container_platforms = false;
    } else if (item.picked_container_platforms || payload.includes(item.id)) {
      item.picked_container_platforms = true
    } else {
      item.picked_container_platforms = false
    }
  
    return item
  });
}

//обогащение данными и выравнивание массива
const enrich_view_platforms = (view_workorders, list_failures) => {
  let result = view_workorders.map(wo => wo.platforms.map(platform => {
    platform.vehicle = wo.vehicle;
    platform.workorder_status = wo.status;
    return platform;
  })).flat();
  result = result.filter(item => item.status == 'new' || item.status == 'error');
  result.forEach(element => {
    element.failure_name = list_failures.find(failure => failure.platform_id == element.id)?.failure_name;
  });
  return result;
}

const defaultState = () => ({
  list_work_orders: [],
  container_platforms: [],
  search_cp_in_wo: '',
  list_failures: [],
  sending: true,
  chosen_wo: {},
  search_wo: '',
})

//SET записывает весь payload в переменную
export const transfer_shift_task = {
  namespaced: true,
  state: defaultState(),
  getters: {
    //список всех сменных заданий
    GET_LIST_WORK_ORDERS: (state) => state.list_work_orders,
    //список всех выбранных сменных заданий
    VIEW_PICKED_WORK_ORDERS: (state) => state.list_work_orders.filter(e => e.picked_work_order),
    //вытаскиваем все площадки из выбранных сменных заданий
    HANDLER_CONTAINER_PLATFORMS: (state, getters) => enrich_view_platforms(getters.VIEW_PICKED_WORK_ORDERS, state.list_failures),
    GET_CONTAINER_PLATFORMS: (state, getters) => state.container_platforms,
    VIEW_CONTAINER_PLATFORMS: (state) => state.container_platforms.filter(e => e.picked_container_platforms),
    VIEW_SEARCH_CP_IN_WO: (state, getters) => {//Отображаем контейнерные площадки + фильтрация
      let { search_cp_in_wo, container_platforms } = state;
      search_cp_in_wo = search_cp_in_wo.toLowerCase();
      let result = container_platforms.filter(item => {
        return (
          String(item.number).includes(search_cp_in_wo) ||
          String(item.vehicle.name).toLowerCase().includes(search_cp_in_wo) ||
          item.address?.toLowerCase().includes(search_cp_in_wo) ||
          item.vehicle.registration_number
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(search_cp_in_wo.replace(/\s+/g, ''))
        );
      })
      return result;
    },
    //Глобальная загрузка данных, флаг для лоадера Boolean
    SENDING: (state) => state.sending,
    GET_CHOSEN_WO: (state) => state.chosen_wo,
    GET_SEARCH_WO: (state) => state.search_wo,
  },
  actions: {
    FETCH_LIST_WORK_ORDERS({ dispatch }, payload) {
        dispatch('SET_SENDING', true);
        const { organisation_id, date, active_work_order } = payload;
      //запрос на получение данных по всем сменным заданиям, предварительная фильтрация нужных данных
      const promise_summary = request(`${process.env.VUE_APP_WN}/fact/workorder/summary?o=${organisation_id}&date=${date}`, "get").then(res => {
        let { data: wos } = res.data.wos;
        const object_active_work_order = wos.find(item => item.id == active_work_order);
        //Получаем список путевых заданий и отфильтровываем задания с проблемными КП
        // проверяем 
        // 1) не завершено ли наше СЗ
        // 2) у СЗ должен быть тот же тип отходов
        // 3) у СЗ должна быть та же организация
        wos = wos.filter(item => {
          return (
            (item.status == 1 || item.status == 2) 
            && object_active_work_order.waste_type?.id == item.waste_type?.id
            // && (object_active_work_order.id == item.id || object_active_work_order.vehicle?.id != item.vehicle?.id)
            && object_active_work_order.organisation?.id == item.organisation?.id
          )
        }
        );
        return wos;
      })
      //запрос на получение данных по всем невывозам кп
      const promise_failure = request(`${process.env.VUE_APP_WN}/fact/failure?o=${organisation_id}&page=all&date=${date}`, 'get').then((res) => {
        if (res.success) {
          return res.data;
        }
      })
      //ожидаем окончание загрузки, после этого делаем наполнение данными => список путевых листов, список невывозов, выбранный путевой лист
      Promise.all([promise_summary, promise_failure]).then(values => {
        const [summary, failures] = values;

        dispatch('SET_LIST_WORK_ORDERS', summary);
        dispatch('SET_FAILURES', failures);
        dispatch('TOGGLE_WORK_ORDERS', [Number(active_work_order)]);
        dispatch('SET_SENDING', false);
      })
      .finally(() => {
        dispatch('SET_SENDING', false);
      })
    },
    SET_FAILURES({ commit }, payload) {
      commit('SET_FAILURES', payload)
    },
    SET_LIST_WORK_ORDERS({ commit }, payload) {
      commit('SET_LIST_WORK_ORDERS', payload)
    },
    TOGGLE_WORK_ORDERS({ dispatch, state, getters }, payload = []) {//payload = получаем массив id списка заданий
      //делаем перезапись нашего списка, теперь с новыми статусами
      dispatch('SET_LIST_WORK_ORDERS', update_picked_work_order(state.list_work_orders, payload))
      //Получаем из выбранного списка СЗ все КП и делаем запись в переменную
      dispatch('SET_CONTAINER_PLATFORMS', getters.HANDLER_CONTAINER_PLATFORMS)
    },
    SET_CONTAINER_PLATFORMS({ commit }, payload) {//payload = получаем массив id контейнерных площадок
      commit('SET_CONTAINER_PLATFORM', payload)
    },
    TOGGLE_CONTAINER_PLATFORMS({ dispatch, state }, payload = []) {//payload = получаем массив id контейнерных площадок
      //Обновляем список контейнерных площадок
      dispatch('SET_CONTAINER_PLATFORMS', update_picked_container_platforms(state.container_platforms, payload))
    },
    UPDATE_CONTAINER_PLATFORMS({ dispatch, state }, payload = {picked_container_platforms: false, container_platforms: state.container_platforms.map(i => i.id)}) {//payload получаем ключ для выбора или отмены выбора по контейнерам
      //так же получаем список ID контейнеров для изменения
      let result = state.container_platforms.map(item => {
        if (payload.container_platforms.includes(item.id)) {
          item.picked_container_platforms = payload.picked_container_platforms
        }
        return item;
      })

      dispatch('SET_CONTAINER_PLATFORMS', result)
    },
    //SEARCH_CONTAINER_PLATFORMS_IN_WORK_ORDERS =>
    SEARCH_CP_IN_WO(store, payload) {//Передаем строку для фильтрации 
      store.commit('SEARCH_CP_IN_WO', payload)
    },
    SET_SENDING({ commit }, payload) {
      commit('SET_SENDING', payload)
    },
    SET_CHOSEN_WO({ commit }, payload) {
      commit('SET_CHOSEN_WO', payload)
    },
    SET_SEARCH_WO({ commit }, payload) {
      commit('SET_SEARCH_WO', payload);
    },
    RESET_STATE({ commit }) {
      commit('RESET_STATE');
    },
  },
  mutations: {
    SET_FAILURES(state, payload) {
      state.list_failures = payload;
    },
    SET_LIST_WORK_ORDERS(state, payload) {
      state.list_work_orders = payload;
    },
    SET_CONTAINER_PLATFORM(state, payload) {
      state.container_platforms = payload;
    },
    SEARCH_CP_IN_WO(state, payload) {
      state.search_cp_in_wo = payload
    },
    SET_SENDING(state, payload) {
      state.sending = payload;
    },
    SET_CHOSEN_WO(state, payload) {
      state.chosen_wo = payload;
    },
    SET_SEARCH_WO(state, payload) {
      state.search_wo = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  }
};