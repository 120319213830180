<template>
  <div class="breadcrumb pt-1 mb-0 pl-0">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
      >
        <router-link :to="{path: routeTo(idx)}" v-if="breadcrumb.link">{{breadcrumb.name}}</router-link>
        <span v-else>{{breadcrumb.name}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',
        data () {
            return {
                breadcrumbList: []
            }
        },
        watch: { '$route' () { this.updateList() } },
        mounted () { this.updateList() },
        methods: {
            routeTo (pRouteTo) {
                let link = this.breadcrumbList[pRouteTo].link;
                if (link) {
                    if(link.indexOf(':id') > -1) {
                        link = link.substr(0, link.indexOf(':id')) + this.$route.params.id + link.substr(link.indexOf(':id') + 3);
                    }
                    return link
                }
                return '';
            },
            updateList () {
                this.breadcrumbList = this.$route.meta.breadcrumb
            }
        }
    }
</script>
<style lang="scss">
  .breadcrumb {
    font-size: 12px;
    color: #8d9092;
    background-color: #fff;
    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    ul > li:not(:last-child)::after {
      content: '/';
      /*float: right;*/
      margin: 0.5em;
      color: #343a40;
      cursor: pointer;
    }
  }
</style>
