<template>
  <div class="position-relative">
    <div class="bg-container" >
      <div class="auth-box-card-blur">
        <img src="@/assets/new_year_2024/auth_login_twig_up.png" class="position-absolute" style=" top: 0; left: 0;"  alt="" v-if="viewNewYear">
        <div class="auth-box-card-blur-bg">
          
        </div>
        <div class="auth-box-card-blur-bgimg" :class="{'auth-box-card-blur-bgimg--newyear': viewNewYear}">
          
        </div>
        <div class="auth-box-card-blur-frame" :class="{'auth-box-card-blur-frame--newyear': viewNewYear}">
        </div>
      </div>
    </div>
    <div class="position-fixed w-100 h-100" style="z-index: 0; top: 0; left: 0;" v-if="viewNewYear">
      <img src="@/assets/new_year_2024/auth_login_twig_down.png" class="position-absolute" style="bottom: 0; right: 0;" alt="">
    </div>
    <div class="auth-box">
      <div class="auth-box-card">
        <div class="auth-box-card-logo">
          <img src="@/assets/restyle/images/new_logo.svg" alt="" />
        </div>
        <h2 class="auth-box-card-title">Факт</h2>
        <h4 class="auth-box-card-subtitle">Вход в систему</h4>
        <div class="auth-box-card-login">
          <input
            id="name"
            class="auth-input"
            :class="{ 'auth-input--error': (send && !email) || isEmailValid() }"
            type="email"
            v-model="email"
            @keyup.enter="login"
            placeholder="Логин"
          />
        </div>
        <div class="auth-box-card-password">
          <input
            id="password"
            class="auth-input"
            :class="{ 'auth-input--error': send && !password }"
            v-model="password"
            @keyup.enter="login"
            :type="[eye_password ? 'password' : 'text']"
            placeholder="Пароль"
          />
          <div
            class="auth-box-card-password-img"
            @click="eye_password = !eye_password"
          >
          <img src="@/assets/restyle/images/eye_close.svg" alt=""   v-if="eye_password"/>
          <img src="@/assets/restyle/images/eye.svg" v-else alt=""/>
          </div>
        </div>
        <div class="auth-box-card-forgot">
          <router-link :to="{ name: 'forgot-password' }">
            Забыли пароль?
          </router-link>
        </div>
        <div class="auth-box-card-submit">
          <button
            :disabled="send || !email || !password"
            @click.prevent="login"
            class="restyle-button restyle-button--success"
          >
            Войти
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.header-auth {
  font-size: 1.75rem;
  font-weight: 500;
}
</style>
<script>
import { mapGetters } from "vuex";
import Footer from "../../components/Footer.vue";

export default {
  data() {
    return {
      email: "",
      password: "",
      eye_password: true,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  created() {    
    localStorage.removeItem("monitoring_organisation_id");
    if (!localStorage.getItem('logout')) {
      this.$store.dispatch("auth/logout");
    }
    this.setCenter();
  },
  computed: {
    ...mapGetters({ send: "auth/send", viewNewYear: "new_year_store/viewNewYear" }),
  },
  methods: {
    login() {
      this.$store.dispatch(`auth/send`, true);
      const { password } = this;
      const email = this.email.toLowerCase();
      this.$store.dispatch("auth/login", { email, password, app: 'desktop' });
    },
    setCenter() {
      navigator.geolocation.getAccurateCurrentPosition(
        this.onSuccess,
        this.onError,
        this.onProgress,
        { desiredAccuracy: 50, maxWait: 2000 }
      );
    },
    onSuccess(res) {
      if (res) {
        localStorage.setItem(
          "coords",
          JSON.stringify([res.coords.latitude, res.coords.longitude])
        );
      } else {
        localStorage.setItem("coords", JSON.stringify([54.3042, 48.4123]));
      }
    },
    onError() {
      localStorage.setItem("coords", JSON.stringify([54.3042, 48.4123]));
    },
    onProgress() {},
    isEmailValid() {
      if (this.send) {
        return this.reg.test(this.email) ? false : true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/restyle/styles/reset.scss";

label {
  color: #3c3c3c;
  font-family: "Noto Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.restore {
  color: #8a8a8a;
  font-family: "Noto Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
  border-bottom: 1px dashed #8a8a8a;
}

.btn {
  border-radius: 25.5px;
}
.footer-login {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 200px;
  transform: translate(-50%, 0);
}

.bg-container {
  --image-bg-auth: url("../../assets/restyle/images/background_auth.svg");

  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &--newyear {
    --image-bg-auth: url("../../assets/new_year_2024/auth_login_buble.svg");
  }

  &::after {
    z-index: 0;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
  }
}



.auth-box {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & * {
    font-family: "Noto Sans", "sans-serif";
  }
}

.auth-box-card-blur {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  &-frame {
    width: 650px;
    min-height: 705px;
    box-shadow: 0px 6.43799px 41px 16px rgba(38, 65, 90, 0.4);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 20px;
    
    &--newyear {
      backdrop-filter: blur(14px);
    }
  }

  &-bg {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #1d9c95 0%, #1d106f 100%);
  }

  &-bgimg {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/restyle/images/background_auth.svg");
    background-repeat: no-repeat;
    background-size: cover;

    &--newyear {
      background-image: url("../../assets/new_year_2024/auth_login_buble.svg");
    }
  }
}

.auth-box-card {
  padding: 92px 120px 99px 117px;
  width: 650px;
  min-height: 705px;

  &-logo {
    margin-bottom: 10px;
    text-align: center;
  }

  &-title {
    margin-bottom: 19px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    color: #ffffff;
  }

  &-subtitle {
    margin-bottom: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
  }

  &-login {
    margin-bottom: 36px;

    & input {
      width: 100%;
    }
  }

  &-password {
    position: relative;
    margin-bottom: 20px;

    & input {
      width: 100%;
    }

    &-img {
      position: absolute;
      top: calc(50% - 7px);
      right: 9px;
      cursor: pointer;
    }
  }

  &-forgot {
    margin-bottom: 40px;
    text-align: right;
    & a {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      text-decoration-line: underline !important;
      color: #63bfcd;
    }
  }

  &-submit {
    & button {
      padding: 12.5px 25px;
      text-align: center;
      width: 100%;
      background: #44ff8f;
      border-radius: 53.3676px;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      cursor: pointer;
      color: #110c61;

      &:hover {
        background-color: #39da8c;
      }

      &:active {
        background-color: #0d3c61;
        color: #44ff8f;
      }

      &:disabled {
        background-color: #bae2ca;
        color: #486983;
        cursor: not-allowed;
      }
    }
  }
}

.auth-input {
  padding: 14px 31px 9px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #a7d1d7;
  border-bottom: 2px solid #ffffff;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &:-ms-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &::-ms-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &--error {
    border-bottom: 2px solid #ff998b;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
      transition: all 10000s ease-in-out 0s; /* выставляется желаемая задержка */
  }
}
</style>
