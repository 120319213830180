export const om_photo = {
  namespaced: true,
  state: {
    selectPhotoInfo: null,
  },
  getters: {
    getSelectPhotoInfo: (store) => store.selectPhotoInfo,
  },
  actions: {},
  mutations: {
    setSelectPhotoInfo(store, payload) {
      store.selectPhotoInfo = payload;
    },
    clearSelectPhotoInfo(store) {
      store.selectPhotoInfo = null;
    }
  },
};
