<template>
  <li :class="active">
    <router-link :to="{name: to}" :class="active">
      <i :class="icon" v-if="icon"></i><span><slot /></span>
    </router-link>
  </li>
</template>
<script>
  export default {
      props: {
          to: String,
          icon: String,
      },
      computed: {
          active() {
              return (this.to === this.$route.name) ? 'active' : '';
          }
      }
  }
</script>
