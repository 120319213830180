<template>
    <div class="pagination" :style="{'--color-bg': color}">
      <div class="pagination-prev" @click="prevStep" v-if="current > 1"></div>
      <div class="pagination-number" v-if="start > 1" @click="step(1)">1</div>
      <div class="pagination-number" v-if="start > 1 && current > 4">...</div>
      <div class="pagination-number" :class="{'pagination-number--active': i == current}" v-for="i of range()" :key="i" @click="step(i)">
        {{ i }}
      </div>
      <div class="pagination-number" v-if="end - 2 <= 1 && total > 3">...</div>
      <div class="pagination-number" v-if="end < total" @click="step(total)">{{total}}</div>
      <div class="pagination-next" @click="nextStep" v-if="current < total"></div>
    </div>
</template>

<script>
export default {
  name: "portalPagination",
  props: ['total', 'color'],
  data() {
    return {
      current: 1,
      start: null,
      end: null,
    }
  },
  created() {
    this.current = this.$route.query.page? this.$route.query.page: this.current;
  },
  watch: {
    current() {
      // logDev('current изменился', this.current)
      this.update();
    },
    $route() {
      if (this.$route.query.page != this.current) {
        this.current = this.$route.query.page? this.$route.query.page: this.current;
      }
    }
  },
  methods: {
    range() {
      let {total, current} = this
      let x = [];
      this.start = (Number(current) - 2) >= 1? (Number(current) - 2): 1;
      this.end = (Number(current) + 2) <= total? (Number(current) + 2): total;

      for (let i = this.start; i <= this.end; i++) {
        x.push(i)
      }

      return x
    },
    nextStep() {
      this.current = Number(this.current) + 1
    },
    step(page = 1) {
      this.current = page
    },
    prevStep() {
      this.current -= 1
    },
    update() {
      if (this.$route.query.page != this.current) {
        let query = Object.assign({}, this.$route.query, { page: this.current })
        this.$router.replace({ query: query })
        this.$emit('update')
        window.scrollTo(0, 0);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  padding-top: 20px;
  justify-content: center;

  &-prev {
    width: 24px;
    height: 24px;
    background-image: url("../../assets/icons/pagination-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      background-image: url("../../assets/icons/pagination-arrow-green.svg");
    }
  }

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: #3C3C3C;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      color: var(--color-bg, #1EA402);
    }

    &--active {
      color: #FFFFFF;
      background-color: var(--color-bg, #1EA402);

      &:hover {
        color: #FFFFFF;
      }
    }
  }

  &-next {
    width: 24px;
    height: 24px;
    background-image: url("../../assets/icons/pagination-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transform: rotate(180deg);

    &:hover {
      background-image: url("../../assets/icons/pagination-arrow-green.svg");
    }
  }
}
</style>
