<template>
  <div>
    <div>
      <ul class="nav" :class="styles">
        <li class="nav-item" v-for="tab in tabs">
          <a :href="tab.href" @click.prevent="selectTab(tab)" class="nav-link" :class="{ 'active': tab.isActive }">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'Tabs',
        props: {
          params: Object,
        },
        data() {
            return { tabs: [] };
        },
        computed: {
            styles() {
                let type = this.params.type;
                let result = '';

                switch (type) {
                    case 'pills':
                        result = 'nav-pills';
                        break;

                    case 'bordered':
                       result = 'nav-tabs tabs-bordered';
                       break;

                    default:
                        result = 'nav-tabs';
                        break;
                }

                if (this.params.justified)
                    result = result + ' nav-justified';

                return result;
            },
        },
        created() {
            this.tabs = this.$children;
        },
        methods: {
            selectTab(selectedTab) {
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.href === selectedTab.href);
                });
            }
        },
    }
</script>
