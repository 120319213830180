<template lang="pug">
.row
  .col-12.col-sm-12.text-center
    ul.pagination.d-flex.flex-row.justify-content-center.mt-3
      li.page-item(v-if="pages.current !== pages.first")
        a.page-link(href="#", @click.prevent="setPage(pages.current - 1)")
          span «
          span.sr-only Назад 
      li.page-item(:class="{ active: 1 === pages.current }")
        a.page-link(href="#", @click.prevent="setPage(1)") 1
      li.page-item(v-if="pages.current > 1 + 3")
        .page-link
          span ...
      li.page-item(
        v-for="item in range(pages.current - 2, pages.current + 2)",
        :key="item.index",
        :class="{ active: item === pages.current }"
      )
        a.page-link(href="#", @click.prevent="setPage(item)") {{ item }}
      li.page-item(v-if="pages.current < pages.last - 3")
        .page-link
          span ...
      li.page-item(:class="{ active: pages.last === pages.current }")
        a.page-link(href="#", @click.prevent="setPage(pages.last)") {{ pages.last }}
      li.page-item(v-if="pages.current !== pages.last")
        a.page-link(href="#", @click.prevent="setPage(pages.current + 1)")
          span »
          span.sr-only Вперед
</template>
<script>
    import {request} from "@/_helpers";


export default {
  name: 'Pagination',
  props: {
    pages: Object,
  },
  data() {
    return {
      current: this.pages.current,
    };
  },
  methods: {
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx)
        .filter((x) => x > 1 && x < this.pages.last);
    },
    setPage(v) {
      let query = Object.assign({}, this.$route.query);
      
      if (v) {
        query.page = v;
      }
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query))
        {
          this.$router.replace({ query: query }).then(()=>{
            this.$emit("update");
          })
          
        }
        
      window.scrollTo(0, 0);
    },
  },
};
</script>
