export const new_year_store = {
  namespaced: true,
  state: {
    viewNewYear: false,
  },
  getters: {
    viewNewYear: state => state.viewNewYear
  },
  actions: {
    toggleViewNewYear({commit}) {
      commit('toggleViewNewYear')
    }
  },
  mutations: {
    toggleViewNewYear(state) {
      state.viewNewYear = !state.viewNewYear;
    }
  }
}