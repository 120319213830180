<template>
  <div class="list">
    <slot/>
  </div> 
</template>

<script>
export default {
  name: 'List',
}
</script>