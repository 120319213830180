<template>
  <button class="restyle_button" @click="$emit('click')" @mouseenter="$emit('mouseenter')" @mouseleave="$emit('mouseleave')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "restyle_button",
};
</script>
<style lang="scss" scoped>
// Классы для кнопок по дефолту стоят стили кнопки success
// Добавочные outline, danger
.restyle_button {
  padding: 11px 23px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 183px;
  height: 46px;
  background: #44ff8f;
  border-radius: 50px;
  cursor: pointer;
  border: 3px solid transparent;
  font-weight: 700;
  font-size: 17px;
  line-height: 27px;
  color: #110c61;

  & * {
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
    color: #110c61;
  }

  &:hover {
    background-color: #0D3C61;
    color: #44ff8f;

    & * {
      color: #44ff8f;
    }
  }

  &:active {
    background-color: #0D3C61;
    color: #44ff8f;
    border: 3px solid #44ff8f;
  }

  &:active * {
    background-color: #0d3c61;
    color: #44ff8f;
  }

  &:disabled,
  &:disabled * {
    background-color: #EEF1F4;
    color: #C3C9D2;
    cursor: not-allowed;
  }

  &:active:disabled {
    border: none;
  }
}

.outline {
  border: 1px solid #0D3C61;
  background-color: white;
  color: #0D3C61;
  border-radius: 50px;

  & * {
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
    color: #0D3C61;
  }

  &:hover {
    background-color: #0D3C61;
    color: #FFFFFF;

    & * {
      color: #FFFFFF;
    }
  }

  &:active {
    background-color: #0d3c61;
    color: #FFFFFF;
    border: 3px solid #44FF8F;
  }

  &:active * {
    background-color: #0d3c61;
    color: #FFFFFF;
  }

  &:disabled,
  &:disabled * {
    background-color: white;
    color: #96A5BD;
    cursor: not-allowed;
  }

  &:active:disabled {
    border: none;
  }
}
.danger {
  border: 1px solid #EB6363;
  background-color: white;
  color: #EB6363;
  border-radius: 50px;

  & * {
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
    color: #EB6363;
  }

  &:hover {
    background-color: #EB6363;
    border: none;
    color: #FFFFFF;

    & * {
      color: #FFFFFF;
    }
  }

  &:active {
    background-color: #FFFFFF;
    color: #EB6363;
    border: 1px solid #EB6363;
  }

  &:active * {
    background-color: #FFFFFF;
    color: #EB6363;
  }

  &:disabled,
  &:disabled * {
    border: 1px solid #96A5BD;
    background-color: white;
    color: #96A5BD;
    cursor: not-allowed;
  }

  &:active:disabled {
    border: none;
  }
}

.danger-red-background {
  background-color: #EB6363;
  color: white;
  border-radius: 50px;

  & * {
    font-weight: 700;
    font-size: 17px;
    line-height: 27px;
    color: white;
  }

  &:hover {
    background-color: #FFFFFF;
    border: none;
    color: #EB6363;
    border: 3px solid #EB6363;

    & * {
      color: #EB6363;
    }
  }

  &:active {
    background-color: #FFFFFF;
    color: #EB6363;
    border: 3px solid #EB6363;
  }

  &:active * {
    background-color: #FFFFFF;
    color: #EB6363;
  }

  &:disabled,
  &:disabled * {
    border: 1px solid #96A5BD;
    background-color: white;
    color: #96A5BD;
    cursor: not-allowed;
  }

  &:active:disabled {
    border: none;
  }
}
</style>