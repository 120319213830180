export const error = {
  namespaced: true,
  state: {
    serverError: false
  },
  getters: {
    serverError: state => state.serverError
  },
  actions: {
    handleServerError({commit}) {
      commit('handleServerError');
    },
    clearServerError({commit}) {
      commit('clearServerError');
    },
  },
  mutations: {
    handleServerError(state) {
      state.serverError = true;
    },
    clearServerError(state) {
      state.serverError = false;
    }
  }
}