<template>
  <div
    class="modal fade"
    :class="{'show': show}"
    @click.self="$emit('close')"
  >
    <div class="modal-dialog" :style="fullHd">
      <div class="modal-content" :style="{'min-height' : customHeight, 'width' : customWidth}">
        <div
          class="modal-close"
          @click="$emit('close')"
        >
          <em class="sr-close" />
        </div>
        <div class="modal-header" :class="[headerCenter ? 'modal-header-center' : '']" v-if="showHeader">
          <h5 class="modal-title" v-if="$slots.title"><slot name="title"/></h5>
        </div>
        <div class="modal-body" :class="[customCenter ? 'modal-body-center' : '']">
          <slot />
        </div>
        <div class="modal-footer" v-if="showFooter">
          <slot name="footer" v-if="showFooter">
            <button class="btn btn-success" @click="$emit('close')">Закрыть</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
      name: 'Modal',
      props: {
        btn_text: {
          type: String,
          default: "закрыть"
        },
        btn_color: {
          type: String,
          default: 'success'
        },
        showFooter: {
          type: Boolean,
          default: true
        },
        showHeader: {
          type: Boolean,
          default: true,
        },
        
        full: {
          type: Boolean,
          default: false
        },
        customHeight: {
          type: String,
          default: ''
        },
        customWidth: {
          type: String,
          default: ''
        },
        customCenter: {
          type: Boolean,
          default: false,
        },
        headerCenter: {
          type: Boolean,
          default: false,
        }
      },
      data() {
          return {
              show: false,
              contentIndex: null,
          }
      },
      created() {
          document.querySelector('body').classList.add('modal-open');
          this.show = true;
          this.contentIndex = window.document.querySelector('.restyle-content');
          this.contentIndex.style.zIndex = 2;
      },
      computed: {
        fullHd() {
          return this.full? {width: '100%', 'max-width': 'calc(100% - 300px)', 'min-width': '1200px'}: {};
        }
      },
      beforeDestroy() {
          this.contentIndex.style.zIndex = 1;
          document.querySelector('body').classList.remove('modal-open');
      }
  }
</script>

<style lang="scss" scoped>
.modal-body-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>