export const omFullWorkOrders = {
  namespaced: true,
  state: {
    workorders_if: true,
  },
  mutations: {
    oppositeWorkordersIf(store) {
      store.workorders_if = !store.workorders_if;
    },
    setTrueWorkordersIf(store) {
      store.workorders_if = true;
    },
  },
  getters: {
    getWorkOrdersIf(state) {
      return state.workorders_if;
    },
  },
};
