export const tooltip_store = {
    namespaced: true,
    state: {
        view_tooltip: null,
    },
    getters: {
      GET_VIEW_TOOLTIP: state => {
        return state.view_tooltip
      },
    },
    actions: {
      SET_VIEW_TOOLTIP({ commit }, payload) {
        commit('SET_VIEW_TOOLTIP', payload)
      },
      CLEAR_VIEW_TOOLTIP({ commit }) {
        commit('SET_VIEW_TOOLTIP', null)
      },
    },
    mutations: {
      SET_VIEW_TOOLTIP(store, payload = null) {
        store.view_tooltip = payload;
      },
    }
};