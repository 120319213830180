function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? matches[1] : undefined;
}

const auth_token = getCookie('auth_token');

const updateCookie = () => {
  const auth_token = getCookie('auth_token');

  if (!Boolean(process.env.VUE_APP_LOCAL_DEV)) {
      auth_token? localStorage.setItem('auth_token', auth_token): localStorage.removeItem('auth_token');
      auth_token? localStorage.removeItem('logout'): localStorage.setItem('logout', 1);
  }
}

export default { updateCookie, auth_token, getCookie }