<template>
  <div class="container-item">
    <div class="container-content inventory-container pt-3 pl-3 pr-3 pb-2 mb-3 border">
      <div class="row">
        <div class="col-12 text-right mb-2">
          <router-link :to="{name: 'inventory_container_view', params: {id: container.id, status_id: container.status_id}}" class="small text-muted mr-2">просмотр</router-link>
          <router-link :to="{name: 'inventory_container_edit', params: {id: container.id}}" class="small text-muted" v-if="container.status_id != 2">редактировать</router-link>
          <a href="#" class="small text-danger pl-2" v-if="container.status_id === 3" @click.prevent="deleteContainer()">удалить</a>
        </div>
      </div>
      <carousel :images="container.media_files" :id="container.id"/>
      <div class="row">
        <label class="col-6 col-sm-6 col-md-5">Инвентарный номер</label>
        <div class="col-6 col-sm-6 col-md-7">{{ container.number }}</div>
      </div>
      <div v-if="container.status_id" class="row">
        <div class="col-6 col-sm-6 col-md-5">
          <span >Статус</span>
        </div>
        <div class="col-3 col-sm-3 col-md-4 mb-2">
          <span :class="statusClass(container.status_id)" >{{ statusText(container.status_id) }}</span>
        </div>
        <div class="col-2 col-sm-2 col-md-3 text-right">
          <a href="#" v-if="container.status_id !== 1" @click.prevent="setStatus(1)"><i class="text-info mdi mdi-new-box mdi-18px align-middle"></i></a>
          <a href="#" class="pl-1" v-if="container.status_id !== 2" @click.prevent="setStatus(2)"><i class="text-success mdi mdi-check mdi-18px align-middle"></i></a>
          <a href="#" class="pl-1" v-if="container.status_id !== 3" @click.prevent="setStatus(3)"><i class="text-danger mdi mdi-block-helper mdi-18px align-middle"></i></a>
        </div>
      </div>
      <div class="row" v-if="status_name(container.container_status_id)">
        <label class="col-6 col-sm-6 col-md-5">Состояние</label>
        <div class="col-6 col-sm-6 col-md-7">{{status_name(container.container_status_id)}}</div>
      </div>
      <div class="row" v-if="type">
        <label class="col-6 col-sm-6 col-md-5">Тип контейнера</label>
        <div class="col-6 col-sm-6 col-md-7">{{type.name}}</div>
        <label class="col-6 col-sm-6 col-md-5">Вместимость</label>
        <div class="col-6 col-sm-6 col-md-7">{{`${type.volume? type.volume: 0} куб.м`}}</div>
        <label class="col-6 col-sm-6 col-md-5">Материал</label>
        <div class="col-6 col-sm-6 col-md-7">{{type.material}}</div>
      </div>
      <div class="row">
        <label class="col-6 col-sm-6 col-md-5">Есть педаль</label>
        <div class="col-6 col-sm-6 col-md-7 container-field-value"><template v-if="container.has_pedal">Да</template><template v-else>нет</template></div>
      </div>
      <div v-if="container.owner_kind_name" class="row">
        <label class="col-6 col-sm-6 col-md-5">Тип собственности</label>
        <div class="col-6 col-sm-6 col-md-7 container-field-value">
          {{ container.owner_kind_name }}
        </div>
      </div>
      <div class="row">
        <label class="col-6 col-sm-6 col-md-5">Комментарий</label>
        <div class="col-6 col-sm-6 col-md-7">{{ container.comment }}</div>
      </div>
    </div>
  </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import Carousel from '@/components/Carousel/Carousel';
    import {request} from "@/_helpers";

    export default {
        name: 'InventoryContainer',
        components: {Carousel},
        props: {
             container: Object
        },
        data() {
            return {

            };
        },
        computed: {
            ...mapGetters('containers', ["status", "statuses", 'container_type']),
            type() {
                return this.container_type(this.container.container_type_id);
            },
        },
        methods: {
            deleteContainer() {
                request(`${process.env.VUE_APP_WN}/container_card/${this.container.id}`, 'delete')
                    .then(() => {
                        this.$emit('update');
                        this.$store.dispatch('alert/success', {title: 'Успешно', message: 'Контейнер успешно удалён'}, {root: true});
                    })
            },
            status_name(v) {
                let status = this.status(v);
                if(status)
                  return status.name;
                return false;
            },
            statusClass(val) {
                switch (val) {
                    case 1:
                        return 'text-info';
                    case 2:
                        return 'text-success';
                    case 3:
                        return 'text-danger';
                    default:
                        return ''
                }
            },
            statusText(val) {
                switch (val) {
                    case 1:
                        return 'Новый';
                    case 2:
                        return 'Принят';
                    case 3:
                        return 'Отклонён';
                    default:
                        return ''
                }
            },
            setStatus(status) {
                let tempContainer = JSON.parse(JSON.stringify(this.container));
                if(tempContainer.coordinates) {
                    tempContainer.latitude = tempContainer.coordinates.latitude;
                    tempContainer.longitude = tempContainer.coordinates.longitude;
                };
                tempContainer.status_id = status;
                tempContainer.datetime = Date.parse(tempContainer.datetime.date) / 1000;
                request(`${process.env.VUE_APP_WN}/container_card/${tempContainer.id}`, 'put', tempContainer)
                    .then(() => {
                        this.$emit('update');
                        this.$store.dispatch('alert/success', {title: 'Успешно', message: 'Данные о контейнере успешно обновлены'}, {root: true});
                    }).catch(() => {
                      window.scrollTo(0,0)
                    })
            }
        }
    }
</script>
<style lang="scss">
.container-item {
  display: flex; 
  padding: 0.5em;
  width: 100%;
}
.container-content {
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 100%;
}

.container-field-value {
  line-height: 18px;
  overflow-wrap: break-word;
}
</style>
