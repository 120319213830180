import {
    request
} from "../_helpers";

export const users = {
    namespaced: true,
    state: {},
    getters: {
        current: state => id => {
            let result = state.all.filter(user => {
                return user.id === parseInt(id);
            });
            return (result) ? result[0] : null;
        }
    },
    actions: {
        remove({
            dispatch
        }, user) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/user/${user.id}`, 'delete')
                    .then(response => {
                        if (response.success) {
                            dispatch('alert/success', {
                                title: 'Успешно!',
                                message: `Пользователь ${user.full_name} успешно удален`
                            }, {
                                root: true
                            });
                            resolve(response.data)
                        }
                    })
            })
        },
        create({
            dispatch
        }, data) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/register`, 'post', data.user)
                    .then(async response => {
                        if (response.success) {
                            await request(`${process.env.VUE_APP_AUTH_URL}/user/${response.data.id}/bind_roles`, 'post', {
                                roles: data.roles
                            });
                            await request(`${process.env.VUE_APP_AUTH_URL}/user/${response.data.id}/update_organisation`, 'post', {
                                organisations: data.organisations
                            });
                            dispatch('alert/success', {
                                title: 'Успешно!',
                                message: response.data.message
                            });
                        }
                        resolve(response);
                    })
            })
        },
        update({
            dispatch
        }, data) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}`, 'put', {
                        name: data.user.name
                    })
                    .then(async () => {
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/bind_roles`, 'post', {
                            roles: data.roles
                        });
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/update_role_srp`, 'post', {
                            roles: data.rolesSRP
                        });
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/update_organisation`, 'post', {
                                organisations: data.organisations
                            })
                            .then(() => {
                                dispatch('alert/success', {
                                    title: 'Успешно!',
                                    message: `Пользователь ${data.user.name} успешно изменен`
                                }, {
                                    root: true
                                });
                                resolve()
                            })
                    })
            })
        }
    },
    mutations: {}
};