<template>
  <div>
    <div
      class="restyle-mobile-layout"
      :class="{ 'restyle-mobile-layout--collapse': $store.state.showNav }"
      id="restyle-mobile-layout"
    >
      <div class="restyle-header-horizontal">
        <restyle_menu_mobile />
      </div>
      <div
        class="restyle-mobile-content-and-footer-overflow-wrapper scrollbar scrollbar--primary"
        rer="scroll"
      >
        <div
          v-show="!loadingStatus"
          class="restyle-mobile-content"
          :class="{ 'restyle-mobile-content--no-padding': $route.meta.noPadding }"
        >
          <!-- <header>
            
            <slot name="header"></slot>
          </header> -->
          <!-- <restyle_header_mobile /> -->
          <slot></slot>
          <Alert/>
        </div>
        <PageLoaderMobile v-show="loadingStatus" />
        <div class="restyle-footer">
          <restyle_footer_mobile />
        </div>
      </div>
      <button v-if="isActiveScroll" class="scroll-top" @click="scrollTop">
        <img src="@/assets/icons/mobile_menu/button_top_page.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
import restyle_menu_mobile from '@/components/restyle/restyle_menu/restyle_menu_mobile';
import restyle_footer_mobile from '@/components/restyle/restyle_menu/restyle_footer_mobile';
import restyle_header_mobile from '@/components/restyle/restyle_header/restyle_header_mobile';
import PageLoaderMobile from '@/components/restyle/loader/PageLoaderMobile.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'restyle-mobile-layout',
  components: {
    // restyle_menu,
    restyle_menu_mobile,
    restyle_header_mobile,
    restyle_footer_mobile,
    PageLoaderMobile,
  },
  data() {
    return {
      delay_var: false,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch('loader/finishLoading');
    },
  },
  created() {
    const app = document.getElementById('app');
    app.style.width = '100%';
  },
  mounted() {
    const scroll = document.querySelector('.scrollbar')
    scroll.addEventListener('scroll', (event) => {
      this.handleScroll(event)
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapMutations({
      setIsActiveScroll: "mobile_menu/SET_IS_ACTIVE_SCROLL",
      setCurrentPage: "mobile_menu/SET_CURRENT_PAGE",
    }),
    handleScroll(event) {
      const targetItem = event.target;
      if (targetItem.scrollTop > 0) {
        this.setIsActiveScroll(true)
      } else {
        this.setIsActiveScroll(false)
      }
    },
    scrollTop() {
      const scroll = document.querySelector('.scrollbar');
      const scrollToTop = () => {
        const currentPosition = scroll.scrollTop;
        if (currentPosition > 0) {
          window.requestAnimationFrame(scrollToTop);
          scroll.scrollTo(0, currentPosition - currentPosition / 8);
        }
      };
      scrollToTop();
      this.setIsActiveScroll(false);
    }
  },
  computed: {
    ...mapGetters({
      loadingStatus: 'loader/loadingStatus',
      isActiveScroll: "mobile_menu/GET_IS_ACTIVE_SCROLL",
      getCurrentPage: "mobile_menu/GET_CURRENT_PAGE",
    }),
  },
};
</script>

<style lang="scss">
@import '../assets/restyle/styles/reset.scss';
</style>

<style lang="scss" scoped>
$width_full: 291px;
$width_mini: 75px;

.restyle-mobile-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 32px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
  transition: all 0.6s ease;
}

.restyle-mobile-content {
  padding: 25px 20px 0 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 32px);
  &--no-padding {
    padding: 0;
  }
}

.restyle-header-horizontal {
  z-index: 9999;
}
.restyle-footer {
  z-index: 2;
  padding: 0 30px;
  background: #1d1871;
  height: 32px;
}

.restyle-mobile-content-and-footer-overflow-wrapper {
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.scroll-top {
  position: sticky;
  left: 15px;
  bottom: 15px;
}
</style>
