export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null,
        title: null,
        alerts: [],
    },
    getters: {
        get_alerts: (state) => state.alerts.reduce((acc, item) => acc.some(element => item.message == element.message)? acc: [...acc, item], []),
    },
    actions: {
        alerts(store, payload) {
            store.commit('alerts', payload);
        },
        success(store,{message, title}) {
            store.commit('success', {message, title});
            setTimeout(function () {
                store.dispatch('clear')
            }, 30000)
        },
        info(store,{message, title}) {
            store.commit('info', {message, title});
            setTimeout(function () {
                store.dispatch('clear')
            }, 30000)
        },
        warning(store, {message, title}) {
            store.commit('warning', {message, title});
            setTimeout(function () {
                store.dispatch('clear')
            }, 30000)
        },
        error(store, {message, title}) {
            store.commit('error', {message, title});
            setTimeout(function () {
                store.dispatch('clear')
            }, 30000)
        },
        clear({commit}) {
            commit('clear');
        }
    },
    mutations: {
        alerts(state, payload) {
          // Создаём уникальный id для алерта, чтобы потом знать, какой алерт нужно удалить из массива
          payload.id = Date.now().toString(36) + Math.random().toString(36).substring(2);
          const { timer = 15000 } = payload;
          state.alerts.push(payload);
          setTimeout(() => {
            const alertIndex = state.alerts.findIndex(alert => alert.id === payload.id);
            if (alertIndex !== -1) {
              state.alerts.splice(alertIndex, 1);
            }
          }, timer);
        },
        success(state, {message, title}) {
            state.type = 'alert-success';
            state.message = message;
            state.title = title;
        },
        info(state, {message, title}) {
            state.type = 'alert-info';
            state.message = message;
            state.title = title;
        },
        warning(state, {message, title}) {
            state.type = 'alert-warning';
            state.message = message;
            state.title = title;
        },
        error(state, {message, title}) {
            state.type = 'alert-danger';
            state.message = message;
            state.title = title;
        },
        clear(state) {
            state.type = null;
            state.message = null;
            state.alerts = [];
        }
    }
}
