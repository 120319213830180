let authUser = localStorage.getItem('user');
authUser = (authUser) ? JSON.parse(authUser) : {id: null, name: null, email: null, full_name: null, roles: [], rolesSRP: [], permissions: [], organisation_ids: [], organisations: []};

import {request, navigation} from "../_helpers";

const normalize_separation_organisations = (organisations) => {
    let arr = []
    for (const key in organisations) {
        if (Object.hasOwnProperty.call(organisations, key)) {
            const element = organisations[key];
            arr.push(element)
        }
    }

    return arr
}

export const user = {
    namespaced: true,
    state: {
        id: authUser.id,
        name: authUser.name,
        email: authUser.email,
        full_name: authUser.full_name,
        roles: authUser.roles,
        rolesSRP:authUser.rolesSRP,
        permissions: authUser.permissions,
        organisation_ids: authUser.organisation_ids,
        organisations: authUser.organisations,
        coords: (localStorage.getItem('coords')) ? JSON.parse(localStorage.getItem('coords')) : [0, 0],
        separation_organisations: localStorage.getItem('separation_organisations')? normalize_separation_organisations(JSON.parse(localStorage.getItem('separation_organisations'))): null,
        currentUser: null
    },
    getters: {
        userCan: state => can => state.permissions.some(permission => permission.name === can),
        isCurrent: state => id => (!id) ? false : parseInt(id) === state.id,
        user: state => {
            return {
                id: state.id,
                name: state.name,
                full_name: state.full_name,
                email: state.email
            }
        },
        userRoles: state => state.roles,
        userRolesSRP: state => state.rolesSRP,
        userPermissions: state => state.permissions,
        userOrganisations: state => state.organisations,
        coords: state => state.coords,
        separation_organisations: state => state.separation_organisations,
        currentUser: state => state.currentUser,
    },
    actions: {
        setUser({commit, state, dispatch}){
            if(!localStorage.getItem('auth_token')) {
                dispatch('auth/logout', null, {root: true});
            }

            request(`${process.env.VUE_APP_AUTH_URL}/owner`, 'get').then(response => {
                const user = response.data;
                
                request(`${process.env.VUE_APP_WN}/rpc`,
                "post",
                {
                    'type':'user_organisations'
                }).then((res) => {
                    localStorage.setItem('separation_organisations', JSON.stringify(res.payload));
                    commit(`separation_organisations`, res.payload);;
                    commit('setCurrentUser', user);
                    commit('setUser', user);
                    const permissionRequestsArray = [];
                    state.roles.forEach(role => {
                      permissionRequestsArray.push(request(`${process.env.VUE_APP_AUTH_URL}/role/${role}/permissions`, 'get'));
                    })
                    Promise.all(permissionRequestsArray).then((responses) => {
                      state.permissions = [];
                      responses.forEach((res) => {
                        commit('setPermissions', res.data);
                      });
                    })
                }).catch(()=> {
                    dispatch('auth/logout', null, {root: true});
                })
                
            });

            
        },
        update(store, data) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}`, 'patch', data.user)
                    .then(async response => {
                        let user = response.data;
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/bind_roles`, 'post', {roles: data.roles})
                            .then((response) => {
                                user.roles = response.data;
                            });
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/update_role_srp`, 'post', {roles: data.uRolesSRP})
                            .then((response) => {
                                user.rolesSRP = response.data.role_srp_ids;
                            });
                        await request(`${process.env.VUE_APP_AUTH_URL}/user/${data.user.id}/update_organisation`, 'post', {organisations: data.organisations})
                            .then(async response => {
                                user.organisations = response.data.organisations;
                                await store.dispatch('setUser');
                                resolve(user)
                            })
                    })
            })
        },
        change_password(store, data) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_AUTH_URL}/change_password`, 'post', data)
                    .then(response => {
                        resolve(response)
                    })
            })
        },
        removeUser({commit}) {
            commit('removeUser');
        },
        coords(store) {
            navigation().then(res => {store.commit('coords', res)});
        },
        setCoords(store, val) {
            store.commit('coords', val);
        },
        getCurrentUser({ commit, state }) {
            if (state?.id) {
                request(
                `${process.env.VUE_APP_AUTH_URL}/user/${state.id}`,
                'get'
                ).then((response) => {
                commit('setCurrentUser', response.data);
                });
            }
        },
    },
    mutations: {
        setUser(state, user) {
            if (user) {
                state.id = user.id;
                state.name = user.name;
                state.email = user.email;
                state.full_name = user.full_name;
                state.roles = user.roles;
                state.rolesSRP = user.role_srp_ids;
                state.organisations = user.organisations;
                user.permissions = [];
                localStorage.setItem('user', JSON.stringify(user));
            }
        },
        separation_organisations(state, organisations) {
            state.separation_organisations = normalize_separation_organisations(organisations)
        },
        removeUser(state) {
            state.id = null;
            state.name = null;
            state.email = null;
            state.full_name = null;
            state.roles = [];
            state.permissions = [];
            state.organisations = [];
            state.rolesSRP = [];
            localStorage.removeItem('user');
        },
        updateUser(state, user) {
            state.name = user.name;
            state.email = user.email;
            state.full_name = user.full_name;
            state.roles = user.roles;
            state.rolesSRP = user.roles_srp_ids;
            state.organisation_ids = user.organisations;
            user.id = state.id;
            user.permissions = state.permissions;
            localStorage.setItem('user', JSON.stringify(user));
        },
        set_roles(state, roles) {
            state.roles = roles;
            let user = JSON.parse(localStorage.getItem('user'));
            user.roles = roles;
            localStorage.setItem('user', JSON.parse(user));
        },
        setPermissions(state, permissions) {
            // не у всех пермишенов есть id, поэтому нужно проверять по названию
            let newPermissions = permissions.filter(permission => {
                 return !state.permissions.some(elem => elem.name === permission.name);
            });
            state.permissions = [...state.permissions, ...newPermissions];
            let user = JSON.parse(localStorage.getItem('user'));
            user.permissions = state.permissions;
            localStorage.setItem('user', JSON.stringify(user));
        },
        coords(state, result) {
            state.coords = result;
            localStorage.setItem('coords', JSON.stringify(result));
        },
        setCurrentUser(state, userInfo) {
            state.currentUser = userInfo;
        },
    }
};
