class object {
    clear(v) {
        for (let k in v) {
            if(v[k] === null) {
                delete v[k]
            }
        }
        return v;
    }
    string(v) {
        let query = null;

        for (let k in v) {
            if(query === null)
                query = '?';
            else
                query += '&';
            query += k + '=' + v[k];
        }
        return (query !== null) ? query : '';
    }
    int(v) {
        for(let k in v) {
            if(v[k] !== null)
                v[k] = parseInt(v[k])
        }
        return v;
    }
    clearQuery(query) {
        for (const key in query) {
            if (Object.hasOwnProperty.call(query, key)) {
                const element = query[key];
                
                if (!element) {
                delete query[key]
                }
            }
        }
    }
}

export default object;