<template>
  <div class="gserver">
    <div v-if="load">
      <div class="preloader">
        <loadPreload :circleColor="'black'"/>
      </div>
    </div>
    <template v-else>
      <icon_car v-if="success_sensors" :use_car="success_sensors" />
      <error_gserver v-else :use_car="error_sensors" />
    </template>
  </div>
</template>

<script>
import { request } from "@/_helpers";
import icon_car from "./icons/car.vue"
import error_gserver from "./error_gserver.vue"
import loadPreload from "@/components/Preloaders/btn-preload";

export default {
  components: {
    icon_car,
    loadPreload,
    error_gserver,
  },
  data() {
    return {
      success_sensors: null,
      error_sensors: null,
      load: true,
      timer: null,
    }
  },
  mounted() {
    this.update();

    this.timer = setInterval(() => {
      this.update();
    }, 3500)
  },
  methods: {
    update() {
      request(`${process.env.VUE_APP_WN}/filling_sensor_stats`, "get").then(
      (response) => {
        const { success_sensors, error_sensors } = response;

        this.success_sensors = success_sensors[0];
        this.error_sensors = error_sensors[0];
      }
    ).finally(() => {
      this.load = false
    }) ;
    }
  },
  destroyed() {
    console.log(`destroyed`);
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>