<template>
  <div class="modalCustom" @click.prevent.self="$emit('close')">
		<div
				class="modalCustom-close"
				@click="$emit('close')"
			>
				<em class="sr-close" />
		</div>
		<div class="modalCustom-body">
			<h5 class="modal-title" v-if="$slots.title"><slot name="title"/></h5>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalCustom',
	data() {
		return {
			contentIndex: null,
		}
	},
  created() {
    document.querySelector("body").classList.add("modal-open");
		this.contentIndex = window.document.querySelector('.restyle-content');
		this.contentIndex.style.zIndex = 2;
  },
  beforeDestroy() {
		this.contentIndex.style.zIndex = 1;
    document.querySelector("body").classList.remove("modal-open");
		this.$emit('preload', true)
  },
};
</script>

<style lang="scss" scoped>
.modalCustom {
  z-index: 2000;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);

	&-body {
		position: relative;
		margin: 72px auto 0;
		padding: 40px 72px;
		overflow-x: hidden;
		overflow-y: auto;
		width: calc(100% - 150px);
		min-height: 400px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
		text-align: center;

		&-title {
			margin-bottom: 40px;
			font-size: 28px;
			line-height: 1.4;
		}
	}

	&-close {
		margin: 72px auto 0;
		position: absolute;
		right: 5px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		width: 64px;
		height: 64px;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
}
</style>
