<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="footer-content-logo">
        <img src="@/assets/restyle/images/new_logo_mini.svg" alt="">
      </div>
      <div class="footer-content-link">
        <a href="https://soyuz317.ru/" target="_blank">
          Сайт компании
        </a>
      </div>
    </div>
    <div class="footer-copyright">
      © ООО “Союз 317”
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .footer-content {
    display: flex;
    align-items: center;
    height: 100%;

    &-logo {
      margin-right: 25px;
      line-height: 0;
      width: 25px;
    }

    &-link {
      line-height: 0;
      & a {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        text-decoration-line: underline !important;
        color: #FFFFFF;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
  .footer-copyright {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }
</style>