<template>
  <span class="position-relative">
    <button type="button" class="btn btn-default" @click.prevent="showPopover=!showPopover">
      {{button.title}}
    </button>
    <div v-if="showPopover" class="popover fade show" :class="'bs-popover-' + direction" >
      <div class="arrow" :class="'bs-popover-' + direction + '-arrow'"></div>
      <div class="popover-body"><slot /></div>
    </div>
  </span>
</template>

<script>
  export default {
      name: 'Popover',
      props: {
          button: Object,
          direction: String
      },
      data() {
          return {
              showPopover: false
          }
      }
  }
</script>

<style lang="scss">
  .popover {
    width: 100%;
    min-width: 270px;
  }
  .bs-popover-top {
    top: -5rem;
    left: -60px;
    &-arrow {
      left: 120px;
    }
  }
  .bs-popover-bottom {
    top: 1.5rem;
    left: -50px;
    &-arrow {
      left: 120px;
    }
  }
  .bs-popover-right {
    top: -1.5rem;
    //right: -5rem;
  /*  position: absolute;*/
  /*  transform: translate3d(346px, 5264px, 0px);*/
  /*  top: 0px;*/
    left: 100%;
  /*  will-change: transform;*/
    &-arrow {
      top: 15px;
    }
  }
  .bs-popover-left {
    top: -20px;
    left: -17rem;
      /*  will-change: transform;*/
  /*  position: absolute;*/
  /*  transform: translate3d(499px, 5246px, 0px);*/
  /*  top: 0px;*/
  /*  left: 0px;*/
    &-arrow {
      top: 15px;
    }
  }
</style>
