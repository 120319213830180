import {request} from "../_helpers";

export const cards = {
    namespaced: true,
    state: {
        statuses: [],
        platforms: []
    },
    getters: {
        statuses: state => {
            return state.statuses
        },
        platforms: state => {
            return state.platforms;
        },
        points: state => status => {
            const tmp = (status) ? state.platforms.filter(platform => { return platform.status_id === parseInt(status)}) : state.platforms;

            return tmp.map(item => {
                let status;
                switch (item.status_id) {
                    case 1:
                        status = 'platform-new';
                        break;

                    case 2:
                        status = 'platform-success';
                        break;

                    case 3:
                        status = 'platform-decline';
                        break;

                    default:
                        status = 'platform-new';
                        break;
                }
                return {id: item.id, type: 'marker', coordinates: [item.coordinates.latitude, item.coordinates.longitude], number: item.containers_count, status: status};
            });
        }
    },
    actions: {
        statuses(store) {
            request(`${process.env.VUE_APP_WN}/card_status`, 'get')
                .then(response => {
                    store.commit('statuses', response.data)
            })
        },
        platforms(store) {
            request(`${process.env.VUE_APP_WN}/platform_card`, 'get')
                .then(response => {
                    store.commit('platforms', response.data);
                    return response.data
                })
        },
        async set_status(store, data) {
            return await request(`${process.env.VUE_APP_WN}/inventory/platform_status_update`, 'post', {datetime: parseInt(+ new Date() / 1000), status_id: data.status, id: data.card.id});
        },
        async exportCard(state, data) {
            await request(`${process.env.VUE_APP_WN}/inventory/platform/export?${data}`, 'post');
        }
    },
    mutations: {
        statuses(state, statuses) {
            state.statuses = statuses;
        },
        platforms(state, platforms) {
            state.platforms = platforms
        }
    }
};
