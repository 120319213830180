<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="wrapper-page">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'no-sidebar-layout',
  }
</script>