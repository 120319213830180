<template>
  <div class="pt-4">
    <p class="text-danger text-center">Ошибка! Нет сигнала!</p>
    <p class="text-center">
      <template v-if="timeSensorSignal(use_car.signal_lidar_datetime)">
        Данные с лидара есть
      </template>
      <template v-else>
        Нет сигнала с лидара
      </template>
      <img src="./img/lidar.svg" alt="" v-if="timeSensorSignal(use_car.signal_lidar_datetime)" />
      <img src="./img/lidar_no_worked.svg" alt="" v-else/>
    </p>
    <p class="text-center">
      <template v-if="timeSensorSignal(use_car.signal_datetime)">
        Данные с gserver есть
      </template>
      <template v-else>
        Нет сигнала с gserver
      </template>
      <img src="./img/gserver.svg" alt="" v-if="timeSensorSignal(use_car.signal_datetime)" />
      <img src="./img/gserver_no_worked.svg" alt="" v-else/>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    use_car: {
      required: true,
    },
  },
  created() {
    console.log(`error car`)
  },
  methods: {
    timeSensorSignal(target) {
      if (target) {
        target = target.replace(/-/g, "/");
      }

      let result = target ? Math.abs(new Date() - new Date(target)) : false; //если значение даты есть, то вычитаем от текущего времени

      if (result) {
        result = result > 24 * 60 * 60 * 1000 ? false : true; // если с момента последнего сигнала прошло больше 24 часов, то датчик или gserver считаем сломанным
      }

      return result;
    },
  },
};
</script>