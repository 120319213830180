import {
    request
} from '../_helpers';

export const containers = {
    namespaced: true,
    state: {
        types: [],
        statuses: [],
    },
    getters: {
        types: state => {
            return state.types;
        },
        container_type: state => id => {
            let type = state.types.filter(type => {
                return type.id === parseInt(id);
            });
            return (type) ? type[0] : null;
        },
        status: state => id => {
            let status = state.statuses.filter(item => parseInt(item.id) === id);

            return (status && status.length > 0) ? status[0] : null
        },
        statuses: state => {
            return state.statuses
        }
    },
    actions: {
        types({
            commit
        }, query = '') {
            return request(`${process.env.VUE_APP_WN}/container_type${query}`, 'get')
            .then(response => {
                    commit('types', response.data)
                })
        },
        type_add(__, type) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_WN}/container_type`, 'post',
                    type).
                then(response => {
                    resolve(response);
                });
            });
        },
        type_edit(__, type) {
            return new Promise(resolve => {
                request(`${process.env.VUE_APP_WN}/container_type/${type.id}`,
                    'put',
                    type).then(response => {
                    resolve(response);
                });
            });
        },
        async type_remove({
            commit
        }, type) {
            await request(`${process.env.VUE_APP_WN}/container_type/${type.id}`,
                'delete');
            commit('type_remove', type.id);
        },
        async statuses(store) {
            const statuses = await request(
                `${process.env.VUE_APP_WN}/container_card/available_statuses`,
                'get').
            then(response => {
                return Object.entries(response.data).
                map(([id, name]) => ({
                    id: parseInt(id),
                    name
                }));
            });
            store.commit('statuses', statuses);
        },
    },
    mutations: {
        types(state, types) {
            state.types = types;
        },
        type_remove(state, type) {
            state.types = state.types.filter(t => {
                return t.id !== parseInt(type);
            });
        },
        statuses(state, statuses) {
            state.statuses = statuses;
        },
    },
};