<template>
  <div class="loader-gif-wrapper">
    <img class="loader-gif" src="@/assets/loading_green.gif" alt=""/>
  </div>
</template>

<script>
  export default {
    name: 'PageLoader',
  }
</script>

<style lang="scss" scoped>
  .loader-gif-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 32px);
  }

  .loader-gif {
    width: 800px;
  }
</style>