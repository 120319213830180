export const online_list_events = {
  namespaced: true,
  state: {
    pickedEvent: null,
    settings: false,
    viewEvents: [],
  },
  getters: {
    objectPickedEvent: state => state.pickedEvent,
    settings: state => state.settings,
    viewEvents: state => state.viewEvents,
    lengthViewEvents: state => state.viewEvents.length,
    disabledViewEvents: state => state.viewEvents?.some(e => e.is_visible === true)
  },
  actions: {
    changeObjectPickedEvent({
      commit
    }, payload) {
      commit('changeObjectPickedEvent', payload);
    },
    setSettings({
      commit
    }, payload) {
      commit('setSettings', payload);
    },
    setViewEvents({
      commit
    }, payload) {
      commit('setViewEvents', payload);
    },
    actionClearEvents({
      commit
    }) {
      commit('actionClearEvents');
    }
  },
  mutations: {
    changeObjectPickedEvent(state, payload) {
      state.pickedEvent = payload;
    },
    setSettings(state, payload) {
      state.settings = payload;
    },
    setViewEvents(state, payload) {
      if (state.viewEvents.some(e => e.id === payload.id)) {
        state.viewEvents = state.viewEvents.filter(e => e.id != payload.id)
      } else {
        state.viewEvents = [...state.viewEvents, payload]
      }
    },
    actionClearEvents(state) {
      state.pickedEvent = null
      state.settings = false
      state.viewEvents = []
    },
    
  }
}