<template>
  <div
    class="nav-link waves-effect waves-light nav-user"
    :class="{ 'dropdown-toggle': nameHidden }"
    v-if="auth"
  >
    <div class="position-absolute" style="top: -5px; width: 50px; cursor: pointer;" v-if="viewNewYear" @click="isVisibleModalUser = !isVisibleModalUser">
      <img src="@/assets/new_year_2024/menu_hat.svg" class="w-100 h-auto" alt="">
    </div>
    <div
      class="user-menu__toggle"
      style="cursor: pointer"
    >
      <img
        class="avatar-small"
        :src="avatar ? avatar : require('@/assets/icons/user/default_avatar.svg')"
      />
    </div>

    <User v-if="isVisibleModalUser && currentUser" 
    @update="update()"
    @isOpenModalUserEdit="isVisibleModalUserEdit = true;" @closeModalUser="isVisibleModalUser = false" @openDeletePhotoUserModal="isVisiblePhotoUserModal = true" @openChangePasswordModal="isVisibleChangePasswordModal = true"/>
    <UserEdit v-if="isVisibleModalUserEdit && currentUser"@close="isVisibleModalUserEdit = false" @openDeletePhotoUserModal="isVisiblePhotoUserModal = true" @openChangePasswordModal="isVisibleChangePasswordModal = true"
    @update="update()"
    />
    <DeletePhotoUserModal v-if="isVisiblePhotoUserModal" @close="isVisiblePhotoUserModal = false" :userID="user.id"/>
    <ChangePasswordModal v-if="isVisibleChangePasswordModal" @close="isVisibleChangePasswordModal = false"/>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import User from "../pages/User/User.vue";
import UserEdit from "../pages/User/UserEdit.vue";
import DeletePhotoUserModal from "../pages/User/components/DeletePhotoUserModal.vue";
import ChangePasswordModal from "../pages/User/components/ChangePasswordModal.vue";

export default {
  components: {
    User,
    UserEdit,
    DeletePhotoUserModal,
    ChangePasswordModal
  },
  props: {
    nameHidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisibleModalUserEdit: false,
      isVisibleModalUser: false,
      isVisiblePhotoUserModal: false,
      isVisibleChangePasswordModal: false,
    }
  },
  created() {
    this.$store.dispatch("avatar/getAvatar");
    this.$store.dispatch('user/getCurrentUser')
    this.$store.commit('user/setUser')
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const userProfile = this.$el.querySelector('.user-profile');
      if (event?.target.closest(".user-menu__toggle") !== null) {
        this.isVisibleModalUser = !this.isVisibleModalUser;
        return;
      }
      if (userProfile && !userProfile.contains(event?.target)) {
        this.isVisibleModalUser = false;
      }
    },
    update() {
      this.$store.dispatch('organisations/all');
      this.$store.dispatch('organisations/all_tree');
      this.$store.dispatch('user/getCurrentUser')
      this.$store.commit('user/setUser')
    }
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      auth: "auth/auth",
      avatar: "avatar/avatar",
      viewNewYear: "new_year_store/viewNewYear",
      currentUser: 'user/currentUser',
    }),
  },
};
</script>

<style lang="scss" scoped>
.avatar-small {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.user-menu__toggle {
  height: 32px;
  width: 32px;
  border: 2px solid white;
  border-radius: 50%;
}
.nav-link {
  position: relative;
  padding: 9px 0;
  height: 100%;
}

.dropdown-toggle::after {
  display: none;
}
.profile-dropdown {
  width: 235px;
}
</style>