var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"restyle-textarea-box"},[(_vm.valueTextArea)?_c('div',{staticClass:"restyle-textarea-cross",style:(_vm.$attrs.styleCross),on:{"click":function($event){return _vm.clearValue()}}},[_c('img',{attrs:{"src":require("@/assets/restyle/images/shared/textarea/cross.svg"),"alt":""}})]):_vm._e(),_c('textarea',{staticClass:"restyle-textarea",class:{
      'restyle-textarea--white': _vm.whiteBG,
      'scrollbar scrollbar--secondary': _vm.hasScroll,
      'focus-textarea': _vm.enableFocus,
      'focus-textarea--blue': _vm.isInputStyle,
      'no-border': _vm.isInputStyle && !_vm.valueTextArea,
      'hover': _vm.isInputStyle,
    },style:(_vm.$attrs.styleTextarea),attrs:{"rows":_vm.$attrs.rows,"cols":_vm.$attrs.cols,"wrap":_vm.$attrs.wrap,"maxlength":_vm.$attrs.maxlength,"id":_vm.customId},domProps:{"value":_vm.valueTextArea},on:{"input":_vm.textAreaChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }