<template>
  <RestyleModal
    @cancel="$emit('close')"
    @close="$emit('close')"
    @save="$emit('close')"
    :showCancelButton="false"
    :use_header="false"
    :horizontalLineAlwaysVisible="true"
  >
    <div class="error-modal-content">
      <div class="error-message-one">Возможно ведутся технические работы</div>
      <div class="error-message-two">Попробуйте обратиться к администратору</div>
      <img class="error-gif" src="@/assets/restyle/images/errors/server_error.gif" alt="">
    </div>
    <template slot="confirmButtonText">
      Закрыть
    </template>
  </RestyleModal>
</template>

<script>
  export default {
    name: 'ServerErrorModal',
    beforeDestroy() {
      this.$store.dispatch('error/clearServerError');
    }
  }
</script>

<style lang="scss" scoped>
  .error-gif {
    width: 759px;
    position: absolute;
    right: -25px;
    
  }

  .error-message-one {
    font-size: 38px;
    font-weight: 500;
    color: $color-page-text;
    position: absolute;
    z-index: 1;
    top: 100px;
    left: -25px;
  }

  .error-message-two {
    font-size: 28px;
    font-weight: 500;
    color: $color-simple-text;
    position: absolute;
    z-index: 1;
    top: 180px;
    left: 0px;
  }

  .error-modal-content {
    width: 1350px;
    height: 450px;
    position: relative;
  }


</style>