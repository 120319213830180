<template>
  <div :style="{ '--circle-color': circleColor }">
    <div class="circle">
      <div class="circle-size circle-one"></div>
      <div class="circle-size circle-two"></div>
      <div class="circle-size circle-three"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "btnPreload",
  props: {
    circleColor: {
      default: "white",
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
$circleColor: var(--circle-color);

.circle {
  z-index: 1002;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 30px;
  height: 6px;
  animation: activeRotate 1.2s infinite ease-in-out;
  animation-delay: 0.2s;

  &-size {
    width: 6px;
    height: 6px;
    background-color: $circleColor;
    border-radius: 50%;
  }

  &-one {
    animation: leftCircle 2.4s ease 0s infinite;
    transform: translateX(6px);
  }
  &-three {
    animation: rightCircle 2.4s ease 0s infinite;
    transform: translateX(-6px);
  }
}

@keyframes leftCircle {
  from {
    transform: translateX(6px);
  }
  20% {
    transform: translateX(-6px);
  }
  40% {
    transform: translateX(-6px);
  }
  60% {
    transform: translateX(-6px);
  }
  80% {
    transform: translateX(-6px);
  }
  to {
    transform: translateX(6px);
  }
}

@keyframes rightCircle {
  from {
    transform: translateX(-6px);
  }
  20% {
    transform: translateX(6px);
  }
  40% {
    transform: translateX(6px);
  }
  60% {
    transform: translateX(6px);
  }
  80% {
    transform: translateX(6px);
  }
  to {
    transform: translateX(-6px);
  }
}

@keyframes activeRotate {
  from {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
