<template>
  <div class="loader">
    <div class="loader-container text-center">
      <i class="fa fa-spinner fa-spin mb-3"></i>
      <p>Подождите...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
}
</script>

<style lang="scss">
  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 42px;
    }
  }
</style>
