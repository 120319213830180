<template>
  <div>
    <ul class="list-unstyled gallery">
      <li class="gallery-nav gallery-nav__prev" @click="prev"><i class="mdi mdi-chevron-left"></i></li>
      <li class="gallery-item text-center" :class="{'gallery-item__active' : index === active}" v-for="(image, index) in images" :key="index">
        <span @click.prevent="zoom" :id="`zoom-${index}-${id}`" class="zoom-open"><i class="mdi mdi-magnify-plus"></i></span>
        <img v-auth-image="host(image.link)" />
      </li>
      <li class="gallery-nav gallery-nav__next" @click="next"><i class="mdi mdi-chevron-right"></i></li>
    </ul>
    <ul class="list-unstyled gallery-pick d-flex flex-row justify-content-center">
      <li v-for="i in (max + 1)" class="gallery-pick-item ml-1 mr-1" :class="{'gallery-pick-item__active': active === i - 1}" @click="active = i -1"></li>
    </ul>
    <div class="zoom" v-show="is_zoom" @click.prevent="zoom_out">
      <div class="zoom-container">
        <span class="zoom-close" @click.prevent="zoom_out"><i class="mdi mdi-window-close"></i></span>
        <img :id="`zoom-target-${id}`" />
      </div>
    </div>
  </div>
</template>
<script>
    import axios from 'axios';
  export default {
      props: {images: Array, id: Number},
      data() {
          return {
              active: 0,
              max: this.images.length - 1,
              is_zoom: false
          }
      },
      beforeCreate() {
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token')
      },
      watch: {
          is_zoom() {   
              if (this.is_zoom === false) {
                window.removeEventListener("keyup", this.onEscape);
              } else {
                window.addEventListener("keyup", this.onEscape);
              }
          }
      },
      methods: {
          onEscape(event) {
              if (event.which === 27)
                  this.zoom_out();
          },
          host(link) {
              return `${process.env.VUE_APP_WN}/file/${link}?api_token=${localStorage.getItem('auth_token')}`;
          },
          prev() {
              if(this.active === 0)
                  this.active = this.max;
              else
                  this.active -= 1;
          },
          next() {
            if(this.active === this.max)
                this.active = 0;
              else
                  this.active += 1;
          },
          zoom(event) {
              let src = document.getElementById(event.target.id).nextElementSibling.getAttribute('src');
              if(src !== null) {
                  this.is_zoom = true;
                  let target = document.getElementById('zoom-target-' + this.id);
                  target.setAttribute('src', src);
              }
          },
          zoom_out(){
              this.is_zoom = false;
              document.getElementById('zoom-target-' + this.id).removeAttribute('src');
          }
      }
  }
</script>
<style lang="scss">
  .gallery {
    position: relative;
    min-height: 200px;
    &-item {
      display: none;
      &__active {
        display: block;
      }
      & > img {
        max-width: 100%;
      }
    }
    &-nav {
      text-align: center;
      display: inline-block;
      width: 48px;
      height: 48px;
      font-size: 36px;
      line-height: 48px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      cursor: pointer;
      &:hover, &:focus, &:active {
        opacity: 0.9;
      }
      &__prev {
        left: 0;
      }
      &__next {
        right: 0;
      }
    }
    &-pick {
      &-item {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $c-primary-dark;
        opacity: 0.6;
        cursor: pointer;
        &:hover, &:focus, &:active {
          opacity: 0.9;
        }
        &__active {
          background: $c-primary-dark;
        }
      }
    }
  }
  .zoom {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.55);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    &-container {
      position: relative;
      max-width: 95%;
      max-height: 95%;
    }
    &-close {
      position: absolute;
      bottom: 100%;
      left: 100%;
      background: $white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      opacity: 0.65;
      cursor: pointer;
      &:hover, &:focus, &:active {
        opacity: 0.9;
      }
    }
    &-open {
      position: absolute;
      top: 20px;
      right: 20px;
      background: $white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      font-size: 18px;
      line-height: 32px;
      text-align: center;
      opacity: 0.65;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      &:hover, &:focus, &:active {
        opacity: 0.9;
      }
    }
  }
</style>
