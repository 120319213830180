<template>
  <RestyleModal @close="$emit('close')" @cancel="$emit('close')" @save="editUser()" :sendDisabled="sendDisabled">
    <template slot="header">
      Редактировать профиль
    </template>
    <div class="content-container">
      <div class="left-part-container">
        <div class="input-block">
         <div class="edit-profile__avatar">
            <img class="edit-profile__avatar__img" :src="avatar ? avatar : require('@/assets/icons/user/default_avatar.svg')" alt="avatar">
            <div style="display: flex; flex-direction: column; margin-left: 25px;">
               <div class="edit-profile__avatar__update" @click="chooseAvatar()">
                  <input type="file" accept="image/*" @change="uploadAvatar" ref="uploadAvatarRef" hidden />
                  <button class="edit-profile__avatar__update__btn">
                     <img src="@/assets/restyle/images/users/icons/update_photo_black.svg" alt="">
                  </button>
                  <div class="edit-profile__avatar__update__text">Загрузить новое фото</div>
               </div>
               <div v-if="avatar" class="edit-profile__avatar__delete" @click="$emit('openDeletePhotoUserModal')">
                  <button class="edit-profile__avatar__delete__btn">
                     <img src="@/assets/restyle/images/users/icons/delete_photo_red.svg" alt="">
                  </button>
                  <div class="edit-profile__avatar__delete__text">Удалить фото</div>
               </div>
            </div>
         </div>
          <RestyleInput v-model="login" @inputChange="noFullName = false" :required="noFullName" class="input">
            <template slot="title">
               Логин
            </template>
            <template slot="required">
               Это поле обязательно к заполнению
            </template>
          </RestyleInput>
         <RestyleInput v-model="fio" class="input">
            <template slot="title">
               ФИО
            </template>
          </RestyleInput>
          <RestyleInput v-model="email" @inputChange="noEmail = false; invalidEmail = false" :required="noEmail || invalidEmail" class="input">
            <template slot="title">
              Email
            </template>
            <template slot="required">
               {{ invalidEmail ? 'Неверный адрес электронной почты' : 'Это поле обязательно к заполнению' }}
            </template>
          </RestyleInput>
        </div>
        <div class="bottom-left-block">
          <div class="role-block">
            <div class="title">Роли Факта</div>
            <div class="role-block-content">
              <template v-for="(role, roleIndex) in roles">
                <div v-if="!isRoleDisabledForAdmin(role)" class="role-wrapper">
                  <CheckboxSquareButton v-model="checkedRoles[role.id]" class="checkbox"/>
                  <span class="role-name">{{ role.name }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="role-srp-block">
            <div class="title">Роли Логистики</div>
            <div class="role-srp-block-content">
              <div v-for="(roleSRP, roleSRPIndex) in rolesSRPSort" class="role-wrapper">
                <CheckboxSquareButton v-model="checkedRolesSRP[roleSRP.id]" class="checkbox"/>
                <span class="role-name">{{ roleSRP.name }}</span>
              </div>
            </div>
          </div>
        </div>
      <div v-if="userCan('admin') || userCan('superadmin')" class="edit-profile__change-password">
            <button class="edit-profile__change-password__btn" @click="$emit('openChangePasswordModal')">
               <img src="@/assets/restyle/images/users/icons/change_pasword_black.svg" alt="change_pasword">
               Сменить пароль
            </button>
      </div>
      </div>
      <div class="right-part-container" v-if="organisationsTreeFirstHalf.length || organisationsTreeSecondHalf.length">
        <div class="title">Организации</div>
        <div v-if="noOrgSelected" class="no-org-selected">Выберите хотя бы одну организацию</div>
        <div class="org-content">
          <div class="column-1">
            <template v-for="(organisation, organisationIndex) in organisationsTreeFirstHalf">
              <div class="parent-org-wrapper">
                <ArrowToggleButton
                  v-if="organisation.children"
                  @click="showChildren[organisation.id] = !showChildren[organisation.id]"
                  :expandList="showChildren[organisation.id]"
                  class="toggle-arrow"
                />
                <CheckboxSquareButton v-model="checkedOrgs[organisation.id]" class="checkbox"/>
                <span class="org-name">{{ organisation.name }}</span>
                <span class="org-id">[{{ organisation.id }}]</span>
              </div>
              <template v-if="organisation.children && showChildren[organisation.id]">
                <div v-for="(childOrg, childOrgIndex) in organisation.children" class="children-org-wrapper">
                  <CheckboxSquareButton v-model="checkedOrgs[childOrg.id]" class="checkbox"/>
                  <span class="org-name">{{ childOrg.name }}</span>
                  <span class="org-id">[{{ childOrg.id }}]</span>
                </div>
              </template>
            </template>
          </div>
          <div class="column-2">
            <template v-for="(organisation, organisationIndex) in organisationsTreeSecondHalf">
              <div class="parent-org-wrapper">
                <ArrowToggleButton
                  v-if="organisation.children"
                  @click="showChildren[organisation.id] = !showChildren[organisation.id]"
                  :expandList="showChildren[organisation.id]"
                  class="toggle-arrow"
                />
                <CheckboxSquareButton v-model="checkedOrgs[organisation.id]" class="checkbox"/>
                <span class="org-name">{{ organisation.name }}</span>
                <span class="org-id">[{{ organisation.id }}]</span>
              </div>
              <template v-if="organisation.children && showChildren[organisation.id]">
                <div v-for="(childOrg, childOrgIndex) in organisation.children" class="children-org-wrapper">
                  <CheckboxSquareButton v-model="checkedOrgs[childOrg.id]" class="checkbox"/>
                  <span class="org-name">{{ childOrg.name }}</span>
                  <span class="org-id">[{{ childOrg.id }}]</span>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>  
  </RestyleModal>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "@/_helpers";
import CheckboxSquareButton from '@/components/restyle/primitives/checkboxes/CheckboxSquareButton.vue';
import ArrowToggleButton from '@/components/restyle/primitives/buttons/ArrowToggleButton.vue';

export default {
  name: "UserEditModal",
  components: {
    CheckboxSquareButton,
    ArrowToggleButton,
  },
  data() {
    return {
      login: '',
      fio: '',
      email: '',
      filteredOrganisations: [],

      checkedRoles: {},
      checkedRolesSRP: {},
      checkedOrgs: {},
      showChildren: {},
      invalidEmail: false,
      noEmail: false,
      noFullName: false,
      noName: false,
      sendDisabled: false,
      noOrgSelected: false,
    };
  },
  created() {
      this.initInfoForCard();
      this.assignDefaultCheckboxValues();
      this.assignDefaultParentOrgToggleValues();
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      currentUser: 'user/currentUser',
      avatar: 'avatar/avatar',
      organisations: 'organisations/all',
      userOrganisations:'user/userOrganisations',
      rolesSRP: "roles/roles_SRP",
      roles: 'roles/allRoles',
      organisationsTree: "organisations/all_tree",
    }),
    organisationsTreeFirstHalf() {
        return this.splitArrayIntoTwo(this.organisationsTree).firstHalf;
    },
    organisationsTreeSecondHalf() {
      return this.splitArrayIntoTwo(this.organisationsTree).secondHalf;
    },
    rolesSRPSort() {
      let result = this.rolesSRP;
      if (!this.userCan('superadmin')) {
        result = result.filter((role) => role.name.toLowerCase() !== 'администратор');
      }
      return result;
    }
  },
  watch: {
    checkedOrgs: {
      handler() {
        this.noOrgSelected = false;
        this.organisationsTree.forEach((parentOrg) => {
          if (this.checkedOrgs[parentOrg.id] === 'yes') {
            return;
          }
          if (parentOrg.children) {
            if (this.hasCheckedChildren(parentOrg)) {
              this.$set(this.checkedOrgs, parentOrg.id, 'partial');
            } else {
              this.$set(this.checkedOrgs, parentOrg.id, 'no');
            }
          }
        });
      },
      deep: true,
    },
  },
  methods: {
  initInfoForCard() {
    if (this.currentUser?.full_name) {
      this.fio = this.currentUser.full_name;
    }
    if (this.currentUser?.email) {
      this.email = this.currentUser.email;
    }
    if (this.currentUser?.name) {
      this.login = this.currentUser.name;
    }
  },
  editUser() {
      let organisations = this.getYesKeys(this.checkedOrgs);
      let roles = this.getYesKeys(this.checkedRoles);
      let rolesSRP = this.getYesKeys(this.checkedRolesSRP);
      const id = this.currentUser.id;
      this.sendDisabled = true;
      request(`${process.env.VUE_APP_AUTH_URL}/user`, "put", {
        id: id,
        name: this.login,
        email: this.email,
        full_name: this.fio,
      })
      .then(async (res) => {
        if (res.success) {
          await request(`${process.env.VUE_APP_AUTH_URL}/user/${id}/update_organisation`, "post", {
            organisations,
          });
          await request(`${process.env.VUE_APP_AUTH_URL}/user/${id}/bind_roles`, "post", {
            roles,
          });
          await request(`${process.env.VUE_APP_AUTH_URL}/user/${id}/update_role_srp`, "post", {
            roles: rolesSRP,
          });
          this.$store.dispatch(
            "alert/alerts",
            { message: `Пользователь ${this.fio} изменен`, type: "success" },
          );
          this.$emit("update");
          this.$emit("close");
        } else {
          this.$store.dispatch(
            "alert/alerts",
            { message: res.message, type: "danger" },
          );
        }
      })
      .finally(() => {
        this.sendDisabled = false;
      })
  },
  isRoleDisabledForAdmin(role) {
      return (role.name === 'admin' || role.name === 'superadmin' || role.name === 'back_office')
        && this.userCan('admin')
        && !this.userCan('superadmin');
  },
    chooseAvatar() {
      const uploadAvatarButton = this.$refs.uploadAvatarRef;
      uploadAvatarButton.click();
    },
    uploadAvatar() {
      const avatarFile = this.$refs.uploadAvatarRef.files[0];
      if (!avatarFile) {
          return;
      }
      if (avatarFile.size > 2000000) {
          this.$store.dispatch('alert/alerts', { type: 'danger', message: 'Размер файла превышает 2мб. Попробуйте загрузить другой файл.' });
          return;
      }
      if (!avatarFile.type?.includes('image')) {
          this.$store.dispatch('alert/alerts', { type: 'danger', message: 'Неверный тип файла. Выберите изображение.' });
          return;
      }
      const formData = new FormData();
      formData.append('avatar', avatarFile);
      this.updateAvatar(formData);
    },
    updateAvatar(image) {
      request(`${process.env.VUE_APP_WN}/auth/avatar/update`, 'post', image )
        .then(() => {
        this.$store.dispatch('avatar/getAvatar');
        this.$store.dispatch(
            "alert/alerts",
            { message: 'Аватар обновлён.', type: "success" },
            );
        })
    },
    checkOrgInUser(orgId) {
      return this.currentUser.organisation_ids.some((organisation_id) => organisation_id == orgId) ? 'yes' : 'no';
    },
    assignDefaultCheckboxValues() {
      this.roles.forEach((role) => {
        this.$set(this.checkedRoles, role.id, this.checkRoleInUser(role.id));
      })
      this.rolesSRP.forEach((roleSRP) => {
        this.$set(this.checkedRolesSRP, roleSRP.id, this.checkRoleSRPInUser(roleSRP.id));
      })
      this.organisations.forEach((org) => {
        this.$set(this.checkedOrgs, org.id, this.checkOrgInUser(org.id));
      })
    },
    assignDefaultParentOrgToggleValues() {
      this.organisationsTree.forEach((parentOrg) => {
        if (parentOrg.children) {
          this.$set(this.showChildren, parentOrg.id, false);
        }
      })
    },
    checkRoleInUser(roleId) {
      return this.currentUser.roles.some((role) => {
        return role == roleId
      }) ? 'yes' : 'no';
    },
    checkRoleSRPInUser(roleSRPId) {
      return this.currentUser.role_srp_ids.some((roleSRP) => {
        return roleSRP == roleSRPId
      }) ? 'yes' : 'no';
    },
    hasCheckedChildren(org) {
      if (org.children) {
        for (let i = 0; i < org.children.length; i++) {
          if (this.checkedOrgs[org.children[i].id] === 'yes') {
            return true;
          }
        }
      }
      return false;
    },
    getYesKeys(obj) {
      let yesKeys = [];
      for (let [key, value] of Object.entries(obj)) {
        if (value === 'yes') {
          yesKeys.push(key);
        }
      }
      return yesKeys;
    },
    splitArrayIntoTwo(array) {
      let mid = Math.ceil(array.length / 2);
      return {
        firstHalf: array.slice(0, mid),
        secondHalf: array.slice(mid)
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-profile {
   &__avatar {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      &__img {
         height: 100px;
         width: 100px;
         border-radius: 50%;
      }
      &__update {
         display: flex;
         align-items: center;
         margin-bottom: 10px;
         &__text {
            cursor: pointer;
            font-size: 18px;
            color: $color-page-text;
            &:hover {
               text-decoration: underline;
            }
         }
         &__btn {
            margin-right: 10px;
            & img {
               width: 20px;
            }
         }
      }
      &__delete {
         display: flex;
         align-items: center;
         &__text {
            cursor: pointer;
            font-size: 16px;
            color: $color-error;
            &:hover {
               text-decoration: underline;
            }
         }
         &__btn {
            margin-right: 10px;
            & img {
               width: 14px;
            }
         }
      }
   }
   &__change-password {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 35px;
   &__btn {
      display: flex;
      align-items: center;
      color: $color-page-text;
      font-size: 16px;
      cursor: pointer;
      &:hover {
         text-decoration: underline;
      }
      & img {
         width: 22px;
         margin-right: 10px;
      }
   }
}
}
.crown {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 50%;
  left: -38px;
  transform: translateY(-50%);
}
</style>

<style lang="scss" scoped>
.org-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
}

.role-block-content, .role-srp-block-content {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 1.7fr;
  color: $color-simple-text;
  grid-column-gap: 60px;
}

.children-org-wrapper, .parent-org-wrapper, .role-wrapper {
  display: flex;
  align-items: baseline;
  white-space: break-spaces;
  margin-bottom: 4px;
}

.title {
  font-weight: 500;
  font-size: 23px;
  line-height: 31px;
  margin-bottom: 20px;
  color: $color-page-text;
}

.input {
  margin-bottom: 25px;
}

.checkbox {
  flex-shrink: 0;
  margin-right: 7px;
}

.role-name, .org-name {
  font-size: 20px;
  line-height: 37px;
}

.toggle-arrow {
  position: absolute;
  left: -20px;
  top: 9px;
}

.parent-org-wrapper {
  position: relative;
}

.children-org-wrapper {
  position: relative;
  left: 20px;
}

.column-1 {
  padding-left: 18px;
}

.org-id {
  font-weight: 500;
  font-size: 20px;
  line-height: 37px;
  color: $color-placeholder;
}

.org-name {
  margin-right: 9px;
}

.bottom-left-block {
  display: flex;
  justify-content: space-between;
}

.role-block, .role-srp-block {
  width: 50%;
}

.role-block {
  margin-right: 40px;
}
.no-org-selected {
  position: absolute;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #EB6363;
  top: 27px;
}
.right-part-container {
  position: relative;
}
</style>
