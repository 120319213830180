function getWordForm(number, wordArray) {
  // Принимает числительное и массив вида ['роль', 'роли', 'ролей'] (одна роль, две роли, пять ролей)
  // Возвращает нужную форму слова
  let numberRemainder100 = Math.abs(number) % 100;
  let numberRemainder10 = numberRemainder100 % 10;
  if (numberRemainder100 > 10 && numberRemainder100 < 20) {
    return wordArray[2];
  }
  if (numberRemainder10 > 1 && numberRemainder10 < 5) {
    return wordArray[1];
  }
  if (numberRemainder10 === 1) {
    return wordArray[0];
  }
  return wordArray[2];
}

function transformOrgsIntoOrgTree(orgs) {
  // Принимает массив организаций, если организация дочерняя, обязательно наличие parent_id
  // Убирает из списка организаций дочерние и добавляет их внутрь родительских
  // Возвращает новый массив
  let orgsCopy = structuredClone(orgs);
  return orgsCopy.filter((userOrg, index, userOrgArr) => {
    if (!userOrg.parent_id) {
      userOrg.children = userOrgArr.filter((org) => org.parent_id == userOrg.id);
      return true;
    } else {
      return !userOrgArr.some((user_org) => user_org.id == userOrg.parent_id);
    }
  })
}
export { getWordForm, transformOrgsIntoOrgTree };