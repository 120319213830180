<template>
  <div class="select-component-container">
    <div v-if="$slots.title" class="select-label">
      <slot name="title"></slot>
    </div>
    <div class="select-plus-options-wrapper" :id="customId" ref="clickOutsideWrapper">
      <div
        @click="toggleOptionsVisible(disabled)"
        class="select-wrapper"
        :class="{
          'select-disabled': disabled,
          'select-active': optionsVisible || selectedOption,
          'select-placeholder': placeholder && !selectedOption,
          'select-required': required,
          'select-wrapper--danger': danger.status,
        }"
      >
        <div class="select-box">
          <template v-if="useContentSlots">
            <slot v-if="selectedOption" name="selected-option-content"></slot>
            <template v-else>{{ placeholder }}</template>
          </template>
          <template v-else>
            {{ selectedOption ? selectedOption[viewKey] : placeholder }}
          </template>
        </div>
        <div class="select-error" v-if="required || danger.status">
          <img class="select-error-image" src="@/assets/restyle/images/shared/select/error.svg" alt=""/>
        </div>
        <div class="select-arrow">
          <img src="@/assets/restyle/images/shared/arrow_down.svg" alt="">
        </div>
      </div>
      <div v-if="optionsVisible" ref="selectOptions" class="select-option-wrapper scrollbar scrollbar--secondary" :style="selectOptionsStyle">
        <div v-if="!notSelected" @click="selectOption(null)" class="select-option">Не выбрано</div>
        <div v-for="(option, index) in options" :key="index" @click="selectOption(option)" class="select-option">
          <slot
            v-if="useContentSlots"
            :currentOption="option"
            name="option-content"
          >
          </slot>
          <template v-else>
            {{ option[viewKey] }}
          </template>
        </div>
      </div>
    </div>
    <div v-if="$slots.required && required" class="select-required-label">
      <slot name="required"></slot>
    </div>
    <small class="danger-text" v-if="danger.text">
      {{ danger.text }}
    </small>
  </div>
</template>

<script>
  // Использовать компонент с помощью v-model='variable', добавив prop :options - массив объектов с полями label и value
  // Ширина по умолчанию 590px, максимальная высота 250px (задается через prop maxHeight), можно задать свою.
  // notSelected отвечает за наличие опции "Не выбрано"
  export default {
    name: 'RestyleSelect',
    props: {
      options: {
        required: true,
        type: Array
      },
      disabled: {
        default: false,
        type: Boolean
      },
      selectedOption: {
        default: null,
      },
      notSelected: {
        default: false,
        type: Boolean
      },
      placeholder: {
        default: null,
        type: String
      },
      required: {
        default: false,
        type: Boolean
      },
      maxHeight: {
        default: '250px',
        type: String
      },
      danger: {
        type: Object,
        default: () => {
          return {
            text: '',
            status: false,
          }
        },
      },
      viewKey: {
        type: String,
        default: 'label',
      },
      customId: {
        type: String,
      },
      useContentSlots: {
        type: Boolean,
        default: false,
      },
    },
    model: {
      prop: 'selectedOption',
      event: 'select'
    },
    data() {
      return {
        optionsVisible: false,
        selectOptionsStyle: {},
      }
    },
    mounted() {
      document.addEventListener('click', this.clickOutsideClose);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickOutsideClose);
    },
    methods: {
      selectOption(option) {
        this.optionsVisible = false;
        this.$emit('select', option);
      },
      toggleOptionsVisible(disabled) {
        if (!disabled) {
          this.optionsVisible = !this.optionsVisible;
          if (this.optionsVisible) {
            this.$nextTick(() => {
              this.adjustDropdownPosition();
            })
          }
        }
      },
      clickOutsideClose(event) {
        if (!this.$refs.clickOutsideWrapper?.contains(event.target)) {
          this.optionsVisible = false;
        }
      },
      adjustDropdownPosition() {
        let currentElement = this.$refs.clickOutsideWrapper;
        const selectRect = currentElement.getBoundingClientRect();
        let isCovering = false;
        while (currentElement) {
          const overflowY = window.getComputedStyle(currentElement).overflowY;
          const canOverflow = (overflowY !== 'visible');
          if (canOverflow) {
            const rect = currentElement.getBoundingClientRect();
            const spaceDown = rect.bottom - selectRect.bottom;
            const options = this.$refs.selectOptions;
            const optionsRect = options.getBoundingClientRect();
            if ((spaceDown < parseInt(this.maxHeight)) && spaceDown < optionsRect.height) {
              isCovering = true;
              break;
            }
          }
          currentElement = currentElement.parentElement;
        }
        if (isCovering) {
          this.selectOptionsStyle = {
            top: 'auto',
            bottom: `${selectRect.height}px`,
            maxHeight: `${this.maxHeight}`,
          };
        } else {
          this.selectOptionsStyle = {
            bottom: 'auto',
            top: `${selectRect.height}px`,
            maxHeight: `${this.maxHeight}`,
          };
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

.select-component-container {
  color: #1D1871;
  width: 590px;
  position: relative;
}

.select-label {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  margin-bottom: 15px;
}

.select-box {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select-arrow {
  position: absolute;
  right: 17px;
  top: 33%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-error {
  position: absolute;
  right: 42px;
  top: 33%;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-image {
    width: 21px;
    height: 21px;
  }
}

.select-wrapper {
  position: relative;
  background: #EDFAFA;
  border-radius: 10px;
  height: 52px;
  padding: 12px 40px 12px 20px;
  width: 100%;
  &:hover {
    outline: 1px solid $color-success;
    cursor: pointer;
  }

  &--danger {
    outline: 1px solid #EB6363 !important;
  }
}

.select-option {
  background: #EDFAFA;
  overflow-wrap: break-word;
  &:hover {
    background: #1D1871;
    color: #FFFFFF;
    cursor: pointer;
  }
  padding: 10px 20px 10px 20px;
  &:first-child {
    border-radius: 9.4px 9.4px 0 0;
  }
  &:last-child {
    border-radius: 0 0 9.4px 9.4px;
  }
}

.select-option-wrapper {
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 9.4px;
  box-shadow: 0px 3.8px 15.1px rgba(23, 21, 55, 0.1);
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  font-size: 20px;
  line-height: 27px;
  width: 100%;
  overflow-y: auto;
  max-height: 250px;
}

.select-disabled {
  cursor: not-allowed;
  background: #D7E8E8;
  color: #62849D;
  &:hover {
    cursor: not-allowed;
    background: #D7E8E8;
  }
}

.select-active {
  outline: 1px solid $color-success;
}

.select-placeholder {
  color: #62849D;
}

.select-required {
  outline: 1px solid #EB6363;
  &-label {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #EB6363;
  }
}
.danger-text {
  padding-top: 4px;
  display: inline-block;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #EB6363;
}

.select-plus-options-wrapper {
  position: relative;
}
</style>