import { render, staticRenderFns } from "./car0.vue?vue&type=template&id=0d5ce927&scoped=true"
var script = {}
import style0 from "./car0.vue?vue&type=style&index=0&id=0d5ce927&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d5ce927",
  null
  
)

export default component.exports