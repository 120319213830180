<template>
  <div class="reference-block">
    <div
      class="reference-block-link"
    >
      <router-link :to="{ name: 'task_installer' }" class="links-item">
        <div class="link-flex">
            <div style="width: 100%;">
              {{ getByHotSpotRouteTitleMenu('task_installer') }}
            </div>
          <div v-if="isActiveHotSpot('task_installer')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('task_installer'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');" style="margin-left: -20px;">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div class="reference-block-link">
      <router-link :to="{ name: 'install_documents' }" class="links-item">
        <div class="link-flex">
            <div style="width: 100%;">
              {{ getByHotSpotRouteTitleMenu('install_documents') }}
            </div>
          <div v-if="isActiveHotSpot('install_documents')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('install_documents'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');" style="margin-left: -20px;">
          Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="reference-block-link"
    >
      <router-link :to="{ name: 'instruction_documents' }" class="links-item">
        <div class="link-flex">
            <div style="width: 100%;">
              {{ getByHotSpotRouteTitleMenu('instruction_documents') }}
            </div>
          <div 
            v-if="isActiveHotSpot('instruction_documents')?.id" class="hotspot"
            @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('instruction_documents'),
            })" 
            @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');" style="margin-left: -20px;"
          >
            Новое
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="reference-block-link"
    >
      <router-link :to="{ name: 'fichas_update' }" class="links-item">
        <div class="link-flex">
            <div style="width: 100%;">
              {{ getByHotSpotRouteTitleMenu('fichas_update') }}
            </div>
          <div 
            v-if="isActiveHotSpot('fichas_update')?.id" class="hotspot"
            @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('fichas_update'),
            })" 
            @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');" style="margin-left: -20px;"
          >
            Новое
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapMutations({
      setIsVisibleModalOMReport: "online_monitoring/set_is_visible_modal_om_report",
    }),
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => {
        return item.menu === rusNameMenu
      });
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    })
  }
};
</script>

<style lang="scss" scoped>
.reference-block {
  padding: 20px 25px;
  z-index: 1;
  display: grid;
  row-gap: 20px;
  position: absolute;
  top: 50px;
  left: 0;
  width: 327px;
  border-radius: 5px;

  &::after {
    margin-top: 2px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 398px;
    height: calc(100% - 2px);
    background: #1d7389;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
  .link-report {
    color: #ffffff;
    &:hover {
      color: #44ff8f;
    }
  }
  .link-flex {
    display: flex;
    align-items: center
  }
}
</style>