import {request} from "../_helpers";

export const garbageTruck = {
    namespaced: true,
    state: {
        data: [],
        var_mobile_cars: [],
    },
    getters: {
        allItems: state => {
            return state.data
        },
        get_mobile_cars: state => {
            return state.var_mobile_cars
        },
    },
    actions: {
        allItems(store, organisation_id) {
          request(`${process.env.VUE_APP_WN}/carpark/vehicle_online_monitoring`, "post", {
            organisation_id: Number(organisation_id)
          }).then((res) => {
            let data = res.data.map((i) => {
              i.co = [i.lat ? i.lat : 0, i.lng ? i.lng : 0];
              return i;
            });
            
            store.commit('allItems', data)
          });
        },
        mobile_cars(store, organisation_id) {
          request(`${process.env.VUE_APP_WN}/carpark/vehicle_mobile_monitoring`, "post", {
            organisation_id: Number(organisation_id)
          }).then((res) => {
            let data = res.data.map((i) => {
              i.co = [i.lat ? i.lat : 0, i.lng ? i.lng : 0];
              return i;
            });
            
            store.commit('mobile_cars', data)
          });
        }
    },
    mutations: {
        allItems(store, garbageTruck) {
          store.data = garbageTruck
        },
        mobile_cars(store, garbageTruck) {
          store.var_mobile_cars = garbageTruck
        }
    }
};