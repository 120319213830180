export const rework_online_monitoring_hardware_report = {
  namespaced: true,
  state: {
    isAllowChangeFullness: false,
    currentWO: null,
    fullnessEditPercentages: {},
    isRequestingChangeFullness: false,
    updateHardwareReportData: false,
    // Массив общих фактических объемов для АО
    totalActualVolume: [],
    changeActualVolume: null,
  },
  getters: {
    isAllowChangeFullness: (state) => state.isAllowChangeFullness,
    currentWO: (state) => state.currentWO,
    fullnessEditPercentages: (state) => state.fullnessEditPercentages,
    isRequestingChangeFullness: (state) => state.isRequestingChangeFullness,
    updateHardwareReportData: (state) => state.updateHardwareReportData,
    getTotalActualVolume: (state) => state.totalActualVolume,
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    setCurrentWO({ commit }, payload) {
      commit('setCurrentWO', payload);
    },
    setIsAllowChangeFullness({ commit }, payload) {
      commit('setIsAllowChangeFullness', payload);
    },
    setFullnessEditPercentages({ commit }, payload) {
      commit('setFullnessEditPercentages', payload);
    },
    setUpdateHardwareReportData({ commit }, payload) {
      commit('setUpdateHardwareReportData', payload);
    },
  },
  mutations: {
    resetState(state) {
      state.isAllowChangeFullness = false;
      state.currentWO = null;
      state.fullnessEditPercentages = {};
      state.isRequestingChangeFullness = false;
      state.updateHardwareReportData = false;
    },
    setCurrentWO(state, payload) {
      state.currentWO = payload;
    },
    setIsAllowChangeFullness(state, payload) {
      state.isAllowChangeFullness = payload;
    },
    setFullnessEditPercentages(state, payload) {
      state.fullnessEditPercentages = payload;
    },
    setUpdateHardwareReportData(state, payload) {
      state.updateHardwareReportData = payload;
    },
    setTotalActualVolume(state, resultData) {
      const result = [];

      [...resultData].forEach((item) => {
        if (item.type == 'platform') {
          const info = item.data;
          result.push({
            id: info.id,
            value: +info.volume.actual.value,
            multiply: 1,
            summaryValue: 0,
            newValue: 0,
          });
        }
      });

      let newSummaryValue = 0;

      result.forEach((element) => {
        element.newValue = element.value;
        const newValue = element.newValue * 10000 * element.multiply;
        newSummaryValue += newValue;
        element.summaryValue = newSummaryValue / 10000;
      });
      state.totalActualVolume = result;
    },
    recalculationTotalActualVolume(state, payload) {
      const [ currentID, selectMultiply ] = Object.entries(payload)[0];

      let newSummaryValue = 0;

      state.totalActualVolume = state.totalActualVolume.map((element) => {
        let newMultiplyValue = 0;

        if (element.id === Number(currentID)) {
          element.multiply = selectMultiply
        }
        newMultiplyValue = element.newValue * 10000 * element.multiply;
        newSummaryValue += newMultiplyValue;
        element.summaryValue = newSummaryValue / 10000;

        return element;
      });      
    },
    resetChangeActualVolume(state) {
      state.changeActualVolume = null;
    }
  },
};