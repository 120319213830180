<template>
  <div class="tech-links" :class="{'tech-links--active': this.$route.meta.active == 5}">
    <img
      class="normal"
      src="@/assets/restyle/images/menu/settings_white.svg"
      alt=""
      style="width: 24px;"
    />
    <img
      class="hover"
      src="@/assets/restyle/images/menu/settings_green.svg"
      alt=""
      style="width: 24px;"
    />
    <div class="links-block">
      <div
        id="integration-rgsoft"
        class="links-block-link"
      >
        <router-link :to="{ name: 'Integration' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('Integration') }}
            <div v-if="isActiveHotSpot('Integration')?.id" class="hotspot"
            @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                hoverEvent: $event,
                tooltipText: currentTooltipForHotSpot('Integration'),
              })" 
            @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
            Новое
            </div>
          </div>
        </router-link>
      </div>
      <div id="integration-autospecbase" v-if="userCan('superadmin')" class="links-block-link">
        <router-link :to="{ name: 'Integrations' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('Integrations') }}
          <div v-if="isActiveHotSpot('Integrations')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('Integrations'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div id="integration-dyn" v-if="userCan('superadmin')" class="links-block-link">
        <router-link :to="{ name: 'Integration_element_technology' }" class="links-item">
          <div class="link-flex">
              {{ getByHotSpotRouteTitleMenu('Integration_element_technology') }}
            <div v-if="isActiveHotSpot('Integration_element_technology')?.id" class="hotspot"
            @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
                hoverEvent: $event,
                tooltipText: currentTooltipForHotSpot('Integration_element_technology'),
              })" 
            @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
            Новое
            </div>
          </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="back_office">
        <router-link :to="{ name: 'vehicle_sensor' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('vehicle_sensor') }}
          <div v-if="isActiveHotSpot('vehicle_sensor')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('vehicle_sensor'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="back_office">
        <router-link
          :to="{ name: 'all_list_sensor_components' }"
          class="links-item"
          >
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('all_list_sensor_components') }}
          <div v-if="isActiveHotSpot('all_list_sensor_components')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('all_list_sensor_components'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link">
        <router-link :to="{ name: 'command_gserver' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('command_gserver') }}
          <div v-if="isActiveHotSpot('command_gserver')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('command_gserver'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="userCan('technical_department_root')">
        <router-link :to="{ name: 'list_logs' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('list_logs') }}
          <div v-if="isActiveHotSpot('list_logs')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('list_logs'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="userCan('show_organisation_settings')">
        <router-link :to="{ name: 'organisation_settings' }" class="links-item">
          <div class="link-flex">
            {{ getByHotSpotRouteTitleMenu('organisation_settings') }}
          <div v-if="isActiveHotSpot('organisation_settings')?.id" class="hotspot"
          @mouseenter="$store.dispatch('tooltip_store/SET_VIEW_TOOLTIP', {
              hoverEvent: $event,
              tooltipText: currentTooltipForHotSpot('organisation_settings'),
            })" 
          @mouseleave="$store.dispatch('tooltip_store/CLEAR_VIEW_TOOLTIP');">
          Новое
          </div>
        </div>
        </router-link>
      </div>
      <div class="links-block-link" v-if="userCan('technical_department_root')">
        <router-link :to="{ name: 'hot_spots' }" class="links-item">
          Редактирование хотспотов
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    back_office: {
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      userCan: "user/userCan",
      getByHotSpotRouteTitleMenu: "hotspots/getByHotSpotRouteTitleMenu",
      allListActiveHotSpots: "hotspots/allListActiveHotSpots",
    }),
  },
  methods: {
    isActiveHotSpot(menu) {
      const rusNameMenu = this.getByHotSpotRouteTitleMenu(menu)
      const result = this.allListActiveHotSpots.find(item => item.menu === rusNameMenu);
      return result;
    },
    currentTooltipForHotSpot(menu) {
      const hotSpot = this.isActiveHotSpot(menu);
      if (Object.entries(hotSpot).length &&  hotSpot.tooltip) {
        return hotSpot.tooltip
      } 
      return ''
    }
  }
}

</script>

<style lang="scss" scoped>
.links-block {
  padding: 20px 25px;
  z-index: 1;
  display: none;
  row-gap: 20px;
  position: absolute;
  top: 0px;
  right: 0;
  width: 320px;
  border-radius: 5px;

  &::after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 280px;
    height: 100%;
    background: #1D407B;
    box-shadow: 4px 4px 8px 2px rgba(57, 134, 144, 0.5);
    border-radius: 5px;
  }

  &-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    height: 100%;

    & a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }

    &:hover {
      & a {
        color: #44ff8f;
      }
    }
  }
}

  .tech-links {
    margin-right: 15px;
    position: relative;
    display: flex;
    cursor: pointer;
    height: 100%;
    
    & .hover {
      display: none;
    }

    &:hover {
      & .normal {
        display: none;
      }
      & .hover {
        display: block;
      }

      & .links-block {
        display: grid;
      }
    }

    &--active {
      & .normal {
        display: none;
      }
      & .hover {
        display: block;
      }
    }
  }
  
  .link-flex {
    display: flex;
    align-items: center
  }
</style>