<template>
  <footer class="footer">
    <div>
      © ООО Союз 317
    </div>
  </footer>
</template>
<script>
  export default {
    name: 'Footer',
  }
</script>
