<template>
  <div class="" style="background: #f8fbfa;">
    <div
      class="restyle-layout"
      :class="{ 'restyle-layout--collapse': $store.state.showNav }"
      id="restyle-layout"
    > 
      <div class="restyle-header-horizontal">
        <restyle_menu_horisontal />
        <restyle_menu_mobile />
      </div>
      <div class="restyle-content-and-footer-overflow-wrapper scrollbar scrollbar--primary">
        <div v-show="!loadingStatus" class="restyle-content" :class="{ 'restyle-content--no-padding': $route.meta.noPadding }">
          <restyle_header v-if="!$route.meta.teleport" />
          <slot></slot>
          <Alert />
        </div>
        <PageLoader v-show="loadingStatus"/>
        <div class="restyle-footer">
          <restyle_footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import restyle_menu from "@/components/restyle/restyle_menu/restyle_menu.vue";
import restyle_menu_horisontal from "@/components/restyle/restyle_menu/restyle_menu_horisontal.vue";
import restyle_menu_mobile from "@/components/restyle/restyle_menu/restyle_menu_mobile";
import restyle_footer from "@/components/restyle/restyle_menu/restyle_footer.vue"
import restyle_header from "@/components/restyle/restyle_header/restyle_header.vue";
import PageLoader from "@/components/restyle/loader/PageLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "restyle-layout",
  components: {
    // restyle_menu,
    restyle_menu_horisontal,
    restyle_menu_mobile,
    restyle_header,
    restyle_footer,
    PageLoader,
  },
  data() {
    return {
      delay_var: false,
    };
  },
  watch: {
    $route() {
      this.$store.dispatch('loader/finishLoading');
    },
  },
  created() {
    const app = document.getElementById("app");
    app.style.width = "100%";
  },
  mounted() {
    // const layout = document.getElementById('restyle-layout');
    // layout.className.split(' ').forEach(item => layout.classList.remove(item));
  },
  methods: {},
  computed: {
    ...mapGetters({
      loadingStatus: "loader/loadingStatus",
    }),
  },
};
</script>

<style lang="scss">
@import "../assets/restyle/styles/reset.scss";
</style>

<style lang="scss" scoped>
$width_full: 291px;
$width_mini: 75px;

.restyle-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 32px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh;
  transition: all 0.6s ease;
}

.restyle-content {
  padding: 25px 30px 0 30px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 32px);
  &--no-padding {
    padding: 0;
  }
}

.restyle-header-horizontal {
  z-index: 2;
}
.restyle-footer {
  z-index: 2;
  padding: 0 30px;
  background: #1D1871;
  height: 32px;
}

.restyle-content-and-footer-overflow-wrapper {
  height: calc(100vh - 50px);
  overflow-y: auto;
}
</style>