<template>
  <div>
    <!-- <Header /> -->
    <Sidebar />
    <div class="content-page">
      <div class="content d-flex flex-column">
        <div class="container-fluid d-flex flex-column">
          <div class="row">
            <div class="col-sm-12">
              <h2 class="header-title m-t-0">
                {{ $route.meta.title }}
              </h2>
            </div>
            <div class="col-sm-12">
              <Breadcrumb />
            </div>
          </div>
          <Alert />
          <slot />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import Breadcrumb from "@/components/Breadcrumbs";
export default {
  name: 'default-layout',
  components: { Breadcrumb, Sidebar },
};
</script>
<style lang="scss">
.container-fluid {
  flex: 1;
}
</style>
