export const online_list_failures = {
  namespaced: true,
  state: {
    pickedFailure: null,
  },
  getters: {
    objectPickedFailure: state => state.pickedFailure
  },
  actions: {
    changeObjectPickedFailure({commit}, payload) {
      commit('changeObjectPickedFailure', payload);
    }
  },
  mutations: {
    changeObjectPickedFailure(state, payload) {
      state.pickedFailure = payload;
    },
  }
}