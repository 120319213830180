export const user_notifications = {
  namespaced: true,
  state: {
    listNotifications: [],
    isVisibleNotificationsPopup: false,
    isWaitAlertAfterRequest: false,
  },
  getters: {
    GET_IS_VISIBLE_NOTIFICATIONS_POPUP: (state) => {
      return state.isVisibleNotificationsPopup;
    },
    GET_LIST_NOTIFICATIONS: (state) => {
      return state.listNotifications;
    },
    GET_IS_WAIT_ALERT_AFTER_REQUEST: (store) => {
      return store.isWaitAlertAfterRequest;
    },
  },
  mutations: {
    SET_IS_VISIBLE_NOTIFICATIONS_POPUP(store, payload = false) {
      store.isVisibleNotificationsPopup = payload;
    },
    SET_LIST_NOTIFICATIONS: (store, payload) => {
      const index = store.listNotifications.findIndex(
        (notification) => notification.id === payload.id
      );
      if (index !== -1) {
        // Если уведомление с таким ID уже существует, заменяем его
        return store.listNotifications.splice(index, 1, payload);
      } else {
        // Если уведомление с таким ID не существует, добавляем его в начало
        store.isVisibleNotificationsPopup = true;
        return store.listNotifications.unshift(payload);
      }
    },
    CLEAN_LIST_NOTIFICATIONS: (store) => {
      return (store.listNotifications = []);
    },
    SET_IS_WAIT_ALERT_AFTER_REQUEST: (store, payload) => {
      return store.isWaitAlertAfterRequest = payload;
    },
  },
};
