<template>
  <div class="list-item row d-flex flex-row align-items-center py-2">
    <div class="col-12 col-sm-9 mb-3 mb-sm-0 list-item-content"><slot /></div>
    <div class="col-12 col-sm-3 d-flex flex-row justify-content-around justify-content-sm-end"><slot name="buttons"/></div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
}
</script>

<style lang="scss">
  .list-item {
    &:nth-child(even) {
      background: #f2f2f2;
    }
  }
</style>