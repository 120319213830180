<template>
  <div class="left side-menu in">
    <div class="user-details">
      <!--      Пользовательский аватар пока не поддерживается системой-->
      <!--      <div class="pull-left">-->
      <!--        <img src="assets/images/users/avatar-1.jpg" alt="" class="thumb-md rounded-circle">-->
      <!--      </div>-->
      <div class="user-info">
        <router-link :to="{name: 'user', params: {id: user.id}}">{{user.name}}</router-link>
        <p class="text-muted m-0" v-if="roles.length > 0 && firstRole">
          {{ firstRole.name }}
        </p>
      </div>
    </div>
    <Navigation />
    <!-- Sidebar -->
    <div class="clearfix"></div>
  </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import Navigation from '@/components/Navigation/Navigation'
    export default {
        components: {Navigation},
        computed: {
            ...mapGetters({user: 'user/user', roles: 'user/userRoles', current: 'roles/current'}),
            firstRole() {
              return this.current(this.roles.sort((first, second) => first - second)[0]);
            }
        },
        created() {
          this.$store.dispatch('roles/getRoles');
        }
    }
</script>
