<template>
  <div :class="[restyle_class]">
    <slot />
  </div>
</template>

<script>
export default {
  name: "empty-layout",
  computed: {
    restyle_class() {
      let result = ["login"].includes(this.$route.name)
        ? ""
        : "page-login";
      return result;
    },
  },
};
</script>

<style lang="scss">
.page-login {
  background-color: #f5f5f5;
}
.page {
  min-height: 100vh;
}
</style>