<template>
  <div class="" style="position: relative; height: 57px" v-if="alert.message">
    <div class="" style="z-index: 1051; position: fixed; bottom: 70px; right: 50px; display: flex; justify-content: center;">
      <div :class="alert.type" class="alert alert-dismissible fade show" style="width: 100%; max-width: 450px">
        <button type="button" class="close" @click.prevent="close">
          <span>&times;</span>
        </button>
        <i :style="'mdi ' + alertIcon.icon"></i>
        <strong>{{ alert.title }}</strong>
        {{ alert.message }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    type: Object,
    icon: Object,
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    alertIcon() {
      let icon = this.alert.icon;
      if (icon !== "") {
        switch (icon) {
          case "check-all":
            break;

          case "information":
            break;

          case "alert":
            break;

          case "block-helper":
            break;

          default:
            icon = "";
            break;
        }
        return "mdi-" + icon;
      }
      return "";
    },
  },
  methods: {
    alertTitle(type) {
      return type.substring(6);
    },
    close() {
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>
<style lang="scss">
.container.toast {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  margin-left: auto;
}
.toast.show {
  transform: translateY(-65%);

  @media (min-width: 375px) {
    margin-right: 15px;
  }
}
.alert-header:first-letter {
  text-transform: uppercase;
}
.alert-header {
  padding: 0.75rem 0.75rem 0 0.75rem;
}
.alert-dismissible {
  padding-right: 4rem;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.fade {
  opacity: 0;
}
.fade.show {
  opacity: 1;
}
</style>
