import {request} from '../_helpers';

export const avatar = {
  namespaced: true,
  state: {
    avatar: null
  },
  getters: {
    avatar: state => state.avatar
  },
  actions: {
    getAvatar({commit}) {
      request(`${process.env.VUE_APP_WN}/auth/avatar/get`, 'get')
        .then((res) => {
          const avatar = res.avatar;
          commit('getAvatar', avatar);
        })
    }
  },
  mutations: {
    getAvatar(state, avatar) {
      state.avatar = avatar;
    }
  }
}