<template>
  <ul class="row list-unstyled mb-0">
    <li v-for="link in links" :key="link.id" class="col-12 col-sm-12 col-md-4 col-lg-3 mb-2"><img v-auth-image="host(link.link)" /></li>
  </ul>
</template>
<script>
  import axios from 'axios';
  export default {
      props: {links: Array},
      beforeCreate() {
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token')
      },
      methods: {
          host(link) {
              return `${process.env.VUE_APP_WN}/file/${link}?api_token=${localStorage.getItem('auth_token')}`;
          }
      }
  }
</script>
