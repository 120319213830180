export const loader = {
  namespaced: true,
  state: {
    loadingStatus: false
  },
  getters: {
    loadingStatus: state => state.loadingStatus
  },
  actions: {
    startLoading({commit}) {
      commit('startLoading');
    },
    finishLoading({commit}) {
      commit('finishLoading');
    },
  },
  mutations: {
    startLoading(state) {
      state.loadingStatus = true;
    },
    finishLoading(state) {
      state.loadingStatus = false;
    },
  }
}