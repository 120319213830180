<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 23 23" fill="none">
    <path v-if="checkboxValue === 'yes'" fill-rule="evenodd" clip-rule="evenodd" d="M5.28571 3.5C4.02335 3.5 3 4.52335 3 5.78571V17.2143C3 18.4767 4.02335 19.5 5.28571 19.5H16.7143C17.9767 19.5 19 18.4767 19 17.2143V5.78571C19 4.52335 17.9767 3.5 16.7143 3.5H5.28571ZM14.5205 8.71827L9.83756 13.4012L8.32948 11.8932C8.18397 11.7476 7.99877 11.6749 7.77388 11.6749C7.54899 11.6749 7.36379 11.7476 7.21827 11.8932C7.07276 12.0387 7 12.2239 7 12.4488C7 12.6737 7.07276 12.8589 7.21827 13.0044L9.28195 15.068C9.44069 15.2268 9.6259 15.3062 9.83756 15.3062C10.0492 15.3062 10.2344 15.2268 10.3932 15.068L15.6317 9.82948C15.7772 9.68397 15.85 9.49877 15.85 9.27388C15.85 9.04899 15.7772 8.86379 15.6317 8.71827C15.4862 8.57276 15.301 8.5 15.0761 8.5C14.8512 8.5 14.666 8.57276 14.5205 8.71827Z" fill="#44FF8F"/>
    <rect x="0.5" y="1" width="21" height="21" rx="2.5" :stroke="hoverColorChange || checkboxValue === 'yes' ? '#44FF8F' : '#D2D9E6'"/>
  </svg>
</template>

<script>
export default {
  name: 'CheckboxSquareMultiselectFilter',
  props: {
    checkboxValue: {
      type: String,
      required: true,
      default: 'no',
    },
    hoverColorChange: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss" scoped>
  svg {
    pointer-events: none;
  }
</style>