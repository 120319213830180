<template>
  <div>
    <svg v-if="checkColor == 'other'" :width="width" :height="height" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6631 0.722465C10.8127 0.922895 10.9962 1.11039 11.1518 1.30867C11.3934 1.61256 11.6262 1.92449 11.8499 2.24401C12.3685 2.97892 12.919 3.68365 13.4756 4.38408C13.8785 4.8927 14.3113 5.36037 14.7262 5.85605C14.9377 6.10821 15.2548 6.46381 15.2149 6.83665C15.191 7.0457 15.0135 7.15993 14.8818 7.2957C14.6943 7.48536 14.4869 7.63406 14.3253 7.85173C14.1617 8.07587 13.8905 8.2569 13.8326 8.55216C13.7807 8.81078 14.072 9.02845 14.2754 9.09526C14.4569 9.1556 14.6504 9.21164 14.8379 9.25043C16.1245 9.50905 17.5028 9.60172 18.8113 9.6125C19.1883 9.61465 19.5753 9.65991 19.9264 9.47888C20.1198 9.37974 20.3313 9.17716 20.3672 8.93578C20.5607 7.62329 20.8878 6.30217 21.0573 4.9983C21.1052 4.63623 21.2169 4.18581 21.1012 3.82805C20.9596 3.39702 20.5008 3.49616 20.1917 3.62331C20.0361 3.68796 19.8905 3.78064 19.7448 3.86469C19.6152 3.94012 19.4815 4.06727 19.3419 4.11038C19.0527 4.2052 18.8014 3.91857 18.6358 3.70521C18.3625 3.35607 18.1311 2.9509 17.8938 2.56944C17.3891 1.7591 16.8865 0.897033 16.2302 0.220313C16.1245 0.110399 15.9789 0.101779 15.8413 0.0694514C15.4483 -0.0210653 15.0394 0.0155724 14.6424 0.0155724H12.5022C12.1651 0.0155724 11.814 -0.00597925 11.4849 0.0328136C11.2555 0.0586755 10.8765 0.00695177 10.687 0.179365C10.5514 0.304364 10.5494 0.565138 10.6551 0.709534C10.6551 0.713844 10.6591 0.718154 10.6631 0.722465Z"
        :fill="`#${color}`"/>
      <path d="M11.3464 2.72021C10.7742 2.05059 10.166 1.42632 9.49982 0.869021C9.25174 0.661653 9.1237 0.484527 8.7796 0.488847C8.40548 0.497487 8.11139 0.873341 7.88332 1.22111C7.34916 2.02682 6.85301 2.86062 6.34285 3.68361C5.99475 4.24523 5.39657 4.92133 5.45058 5.64712C5.5006 6.29947 6.4809 6.90861 6.90903 7.26718C7.59124 7.8396 8.29745 8.38611 9.05368 8.83756C9.28975 8.98013 9.56384 8.82892 9.73789 8.64748C10.3421 8.01889 10.8122 7.2607 11.2924 6.52412C11.6605 5.95385 12.4587 5.19566 12.4927 4.47204C12.5227 3.81753 11.7225 3.16087 11.3464 2.72021ZM5.80069 13.6913C6.25483 13.1988 7.29114 14.443 7.91734 13.9656C8.39748 13.6027 7.91934 12.5961 7.77529 12.1641C7.4852 11.2871 7.18511 10.4144 6.803 9.58063C6.47289 8.86132 5.98675 7.6668 5.10047 7.66464C4.50029 7.66464 3.95012 7.98001 3.38395 8.14202C2.42566 8.41635 1.46937 8.75548 0.603102 9.28902C0.473063 9.36678 0.355027 9.47047 0.297009 9.62383C0.182974 9.92624 0.487067 10.2459 0.677125 10.4101C0.875185 10.5829 1.10926 10.732 1.33132 10.868C1.50338 10.976 1.68143 11.0732 1.68943 11.3195C1.69944 11.5722 1.53739 11.8854 1.41935 12.0993C1.22529 12.4449 1.08925 12.8186 0.95521 13.1901C0.681126 13.9613 0.244993 14.6871 0.0469325 15.4885C-0.171134 16.3611 0.421047 17.1409 0.789159 17.8624C1.22129 18.7135 1.62341 19.5904 2.15958 20.3746C2.31362 20.5992 2.50568 20.9643 2.73175 21.1306C2.84579 21.2148 2.97983 21.0291 3.09586 20.785C3.2319 20.502 3.34394 20.1456 3.37395 20.0765C3.95012 18.6551 4.35425 17.1604 4.96043 15.7541C5.23252 15.1148 5.33255 14.1989 5.80069 13.6913Z"
        :fill="`#${color}`"/>
      <path d="M9.12121 17.4059C8.24363 17.2781 7.36401 17.1944 6.48439 17.0974C6.2319 17.0688 5.82263 16.9542 5.58237 17.0423C5.33396 17.1349 5.22807 17.3816 5.09776 17.6086C4.83917 18.0669 4.70275 18.6111 4.51745 19.1223C4.28317 19.7695 4.10657 20.4394 3.99009 21.123C3.93308 21.4601 3.89643 21.795 3.86588 22.1343C3.83941 22.4186 3.8272 22.6565 4.03896 22.8835C4.2609 23.117 4.72514 23.1699 5.12219 23.1941C6.31131 23.2646 7.50247 23.3396 8.69362 23.3836C9.11714 23.3968 9.60989 23.4982 9.98862 23.2404C10.1963 23.0972 10.2187 22.7402 10.2635 22.5045C10.406 21.7157 10.5139 20.9181 10.5974 20.1182C10.6382 19.7216 10.6748 19.3228 10.7033 18.9262C10.7277 18.5803 10.8336 18.1616 10.6606 17.8399C10.4305 17.4081 9.51012 17.461 9.12121 17.4059ZM24.087 16.7427C24.0483 16.7427 24.0137 16.7449 23.9831 16.7471C22.737 16.8969 21.4868 17.1481 20.2468 17.3464C19.3916 17.483 18.5649 17.7166 17.6975 17.7738C17.4104 17.7915 16.7365 17.9391 16.5512 17.624C16.372 17.3177 16.4229 16.6611 16.372 16.302C16.3394 16.0684 16.3923 15.7644 16.2478 15.5661C16.1378 15.4162 15.9342 15.3413 15.7611 15.403C15.0464 15.652 14.4437 16.7228 13.9978 17.3266C13.3544 18.1991 12.7456 19.1047 12.2325 20.0742C11.7825 20.9247 13.222 22.1718 13.7128 22.6785C14.2666 23.2492 14.853 23.7802 15.4333 24.3179C15.5799 24.4523 15.7265 24.5889 15.8731 24.7255C15.979 24.8268 16.0686 24.9348 16.2091 24.9679C16.4941 25.0295 16.7344 24.9899 16.7996 24.6329C16.8668 24.2562 16.8932 23.9036 16.8627 23.5246C16.8403 23.2558 16.7833 22.8945 17.0398 22.7314C17.329 22.5508 17.6629 22.564 17.9785 22.5001C19.1127 22.2621 20.2855 22.326 21.4257 22.1057C22.3257 21.9316 22.68 20.6889 23.0791 19.9376C23.4578 19.2259 23.9119 18.5274 24.2132 17.7738C24.3049 17.5469 24.427 17.331 24.4861 17.0886C24.5085 16.9961 24.5411 16.9145 24.4739 16.844C24.3945 16.7625 24.2214 16.7405 24.087 16.7427Z"
        :fill="`#${color}`"/>
      <path d="M24.7724 13.9626C24.7435 13.8862 24.7146 13.8183 24.6898 13.7547C24.3698 12.9507 24.0663 12.1404 23.7401 11.3386C23.5357 10.8358 23.3086 10.3289 22.9865 9.89188C22.8668 9.72855 22.6871 9.66703 22.4992 9.62885C22.3217 9.59491 22.1689 9.63097 21.9914 9.69249C21.6589 9.80915 21.3183 9.92795 20.9921 10.0658C20.0898 10.4392 19.1484 10.7871 18.2916 11.258C18.0644 11.3831 17.8002 11.5274 17.7919 11.8244C17.7692 12.5647 18.1801 13.1395 18.3948 13.8162C18.4815 14.0941 18.5971 14.3656 18.7293 14.6223C19.0183 15.1866 19.3115 15.7529 19.615 16.313C19.7574 16.5739 19.9082 16.7202 20.1807 16.7987C20.3686 16.856 20.5461 16.8008 20.7402 16.7775C21.3947 16.6948 22.0099 16.489 22.6479 16.3278C23.3643 16.1433 24.1282 15.929 24.7414 15.4878C25.1853 15.1611 24.9603 14.4462 24.7724 13.9626Z"
        :fill="`#${color}`"/>
    </svg>
    <svg v-if="checkColor == 'white'" xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 32 32" fill="none">
      <mask id="path-1-outside-1_4903_51365" maskUnits="userSpaceOnUse" x="0.5" y="-0.5" width="31" height="32" fill="black">
        <rect fill="white" x="0.5" y="-0.5" width="31" height="32"/>
        <path d="M14.3602 2.36205C14.5407 2.60396 14.7622 2.83027 14.95 3.06958C15.2416 3.43637 15.5226 3.81286 15.7926 4.19851C16.4186 5.08553 17.0831 5.93613 17.7548 6.78152C18.2411 7.39541 18.7635 7.95988 19.2643 8.55816C19.5195 8.8625 19.9023 9.2917 19.8541 9.74171C19.8252 9.99403 19.611 10.1319 19.4521 10.2958C19.2258 10.5247 18.9754 10.7042 18.7804 10.9669C18.583 11.2374 18.2555 11.4559 18.1857 11.8123C18.1231 12.1244 18.4746 12.3872 18.7202 12.4678C18.9393 12.5406 19.1728 12.6083 19.3991 12.6551C20.952 12.9672 22.6156 13.0791 24.1949 13.0921C24.6499 13.0947 25.117 13.1493 25.5407 12.9308C25.7742 12.8112 26.0294 12.5666 26.0728 12.2753C26.3063 10.6912 26.7011 9.09661 26.9058 7.52287C26.9636 7.08587 27.0984 6.54221 26.9587 6.11041C26.7878 5.59016 26.2341 5.70982 25.8609 5.86329C25.6731 5.94133 25.4974 6.05318 25.3216 6.15463C25.1651 6.24567 25.0038 6.39914 24.8353 6.45117C24.4862 6.56562 24.1829 6.21966 23.983 5.96214C23.6532 5.54074 23.3739 5.05171 23.0874 4.5913C22.4783 3.61324 21.8716 2.57275 21.0796 1.75597C20.952 1.6233 20.7762 1.6129 20.6101 1.57388C20.1358 1.46463 19.6423 1.50885 19.1632 1.50885H16.5799C16.173 1.50885 15.7493 1.48284 15.3521 1.52966C15.0752 1.56087 14.6178 1.49845 14.389 1.70654C14.2253 1.85741 14.2229 2.17216 14.3505 2.34644C14.3505 2.35165 14.3553 2.35685 14.3602 2.36205Z"/>
        <path d="M14.1419 4.13076C13.5043 3.34131 12.8267 2.60533 12.0845 1.9483C11.8081 1.70382 11.6654 1.495 11.282 1.50009C10.8652 1.51028 10.5375 1.95339 10.2834 2.3634C9.68825 3.31329 9.13545 4.29629 8.56705 5.26656C8.1792 5.92869 7.51272 6.72578 7.5729 7.58145C7.62863 8.35053 8.72085 9.06868 9.19786 9.49143C9.95796 10.1663 10.7448 10.8106 11.5874 11.3428C11.8504 11.5109 12.1558 11.3326 12.3497 11.1187C13.0229 10.3777 13.5467 9.48379 14.0817 8.61538C14.4918 7.94307 15.3812 7.0492 15.4191 6.19608C15.4525 5.42445 14.5609 4.65028 14.1419 4.13076ZM7.96298 17.0651C8.46897 16.4845 9.62361 17.9513 10.3213 17.3885C10.8563 16.9607 10.3235 15.774 10.163 15.2646C9.83982 14.2307 9.50547 13.2019 9.07972 12.2189C8.71193 11.3708 8.17028 9.96255 7.18282 9.96001C6.51411 9.96001 5.90113 10.3318 5.27031 10.5228C4.20261 10.8462 3.13713 11.2461 2.17196 11.8751C2.02707 11.9668 1.89556 12.089 1.83092 12.2698C1.70387 12.6263 2.04268 13.0032 2.25444 13.1968C2.47511 13.4005 2.73591 13.5762 2.98333 13.7367C3.17502 13.864 3.37341 13.9786 3.38232 14.2689C3.39347 14.5669 3.21292 14.9361 3.08141 15.1882C2.86519 15.5957 2.71362 16.0363 2.56427 16.4743C2.25889 17.3834 1.77296 18.2391 1.55229 19.1839C1.30933 20.2128 1.96912 21.1321 2.37926 21.9827C2.86073 22.986 3.30877 24.02 3.90615 24.9444C4.07778 25.2092 4.29177 25.6396 4.54365 25.8357C4.6707 25.935 4.82005 25.716 4.94933 25.4283C5.10091 25.0946 5.22573 24.6744 5.25917 24.593C5.90113 22.9173 6.35139 21.155 7.02679 19.4971C7.32994 18.7433 7.44139 17.6636 7.96298 17.0651Z"/>
        <path d="M12.3527 21.3437C11.318 21.1894 10.281 21.0883 9.24397 20.9713C8.9463 20.9368 8.4638 20.7985 8.18053 20.9048C7.88767 21.0165 7.76284 21.3144 7.60921 21.5883C7.30434 22.1415 7.1435 22.7984 6.92506 23.4154C6.64884 24.1966 6.44065 25.0052 6.30332 25.8303C6.2361 26.2372 6.19289 26.6414 6.15688 27.051C6.12568 27.3941 6.11127 27.6813 6.36093 27.9552C6.62259 28.2371 7.16991 28.301 7.63801 28.3302C9.03993 28.4153 10.4442 28.5057 11.8486 28.5589C12.3479 28.5749 12.9288 28.6972 13.3753 28.3861C13.6201 28.2132 13.6466 27.7824 13.6994 27.4978C13.8674 26.5457 13.9946 25.5829 14.0931 24.6175C14.1411 24.1388 14.1843 23.6574 14.2179 23.1787C14.2467 22.7612 14.3715 22.2559 14.1675 21.8676C13.8962 21.3463 12.8112 21.4101 12.3527 21.3437ZM29.9966 20.5431C29.951 20.5431 29.9102 20.5458 29.8742 20.5485C28.405 20.7293 26.9311 21.0325 25.4692 21.2719C24.461 21.4367 23.4863 21.7187 22.4637 21.7878C22.1252 21.8091 21.3307 21.9873 21.1122 21.6069C20.901 21.2373 20.961 20.4447 20.901 20.0112C20.8626 19.7293 20.925 19.3623 20.7545 19.123C20.6249 18.9421 20.3849 18.8517 20.1808 18.9262C19.3382 19.2267 18.6277 20.5192 18.1019 21.2479C17.3434 22.3011 16.6256 23.3941 16.0207 24.5643C15.4902 25.5909 17.1873 27.0962 17.7659 27.7079C18.4188 28.3967 19.1102 29.0376 19.7943 29.6866C19.9672 29.8488 20.14 30.0137 20.3128 30.1786C20.4377 30.3009 20.5433 30.4312 20.7089 30.4711C21.045 30.5456 21.3283 30.4977 21.4051 30.0669C21.4843 29.6121 21.5155 29.1866 21.4795 28.7291C21.4531 28.4047 21.3859 27.9685 21.6883 27.7717C22.0292 27.5536 22.4229 27.5696 22.795 27.4925C24.1321 27.2052 25.5148 27.2824 26.8591 27.0164C27.9201 26.8063 28.3378 25.3063 28.8083 24.3994C29.2548 23.5404 29.7902 22.6974 30.1454 21.7878C30.2535 21.5139 30.3975 21.2532 30.4671 20.9607C30.4935 20.849 30.5319 20.7506 30.4527 20.6655C30.3591 20.5671 30.155 20.5405 29.9966 20.5431Z"/>
        <path d="M30.1454 17.6212C30.1129 17.5352 30.0803 17.4587 30.0524 17.387C29.6919 16.4814 29.35 15.5685 28.9825 14.6653C28.7523 14.0989 28.4964 13.5278 28.1336 13.0355C27.9987 12.8515 27.7964 12.7822 27.5847 12.7392C27.3847 12.701 27.2126 12.7416 27.0126 12.8109C26.6381 12.9423 26.2544 13.0762 25.8869 13.2315C24.8705 13.6521 23.8099 14.044 22.8447 14.5744C22.5889 14.7154 22.2912 14.8779 22.2819 15.2125C22.2563 16.0465 22.7191 16.694 22.961 17.4563C23.0587 17.7694 23.1889 18.0752 23.3378 18.3644C23.6634 19 23.9937 19.6381 24.3356 20.2689C24.496 20.5628 24.6658 20.7277 24.9728 20.8161C25.1845 20.8807 25.3845 20.8185 25.6031 20.7922C26.3404 20.6991 27.0335 20.4673 27.7522 20.2857C28.5592 20.0778 29.4198 19.8364 30.1105 19.3394C30.6106 18.9714 30.3571 18.1661 30.1454 17.6212Z"/>
      </mask>
      <path d="M14.3602 2.36205C14.5407 2.60396 14.7622 2.83027 14.95 3.06958C15.2416 3.43637 15.5226 3.81286 15.7926 4.19851C16.4186 5.08553 17.0831 5.93613 17.7548 6.78152C18.2411 7.39541 18.7635 7.95988 19.2643 8.55816C19.5195 8.8625 19.9023 9.2917 19.8541 9.74171C19.8252 9.99403 19.611 10.1319 19.4521 10.2958C19.2258 10.5247 18.9754 10.7042 18.7804 10.9669C18.583 11.2374 18.2555 11.4559 18.1857 11.8123C18.1231 12.1244 18.4746 12.3872 18.7202 12.4678C18.9393 12.5406 19.1728 12.6083 19.3991 12.6551C20.952 12.9672 22.6156 13.0791 24.1949 13.0921C24.6499 13.0947 25.117 13.1493 25.5407 12.9308C25.7742 12.8112 26.0294 12.5666 26.0728 12.2753C26.3063 10.6912 26.7011 9.09661 26.9058 7.52287C26.9636 7.08587 27.0984 6.54221 26.9587 6.11041C26.7878 5.59016 26.2341 5.70982 25.8609 5.86329C25.6731 5.94133 25.4974 6.05318 25.3216 6.15463C25.1651 6.24567 25.0038 6.39914 24.8353 6.45117C24.4862 6.56562 24.1829 6.21966 23.983 5.96214C23.6532 5.54074 23.3739 5.05171 23.0874 4.5913C22.4783 3.61324 21.8716 2.57275 21.0796 1.75597C20.952 1.6233 20.7762 1.6129 20.6101 1.57388C20.1358 1.46463 19.6423 1.50885 19.1632 1.50885H16.5799C16.173 1.50885 15.7493 1.48284 15.3521 1.52966C15.0752 1.56087 14.6178 1.49845 14.389 1.70654C14.2253 1.85741 14.2229 2.17216 14.3505 2.34644C14.3505 2.35165 14.3553 2.35685 14.3602 2.36205Z" stroke="#1D1871" stroke-width="2" mask="url(#path-1-outside-1_4903_51365)"/>
      <path d="M14.1419 4.13076C13.5043 3.34131 12.8267 2.60533 12.0845 1.9483C11.8081 1.70382 11.6654 1.495 11.282 1.50009C10.8652 1.51028 10.5375 1.95339 10.2834 2.3634C9.68825 3.31329 9.13545 4.29629 8.56705 5.26656C8.1792 5.92869 7.51272 6.72578 7.5729 7.58145C7.62863 8.35053 8.72085 9.06868 9.19786 9.49143C9.95796 10.1663 10.7448 10.8106 11.5874 11.3428C11.8504 11.5109 12.1558 11.3326 12.3497 11.1187C13.0229 10.3777 13.5467 9.48379 14.0817 8.61538C14.4918 7.94307 15.3812 7.0492 15.4191 6.19608C15.4525 5.42445 14.5609 4.65028 14.1419 4.13076ZM7.96298 17.0651C8.46897 16.4845 9.62361 17.9513 10.3213 17.3885C10.8563 16.9607 10.3235 15.774 10.163 15.2646C9.83982 14.2307 9.50547 13.2019 9.07972 12.2189C8.71193 11.3708 8.17028 9.96255 7.18282 9.96001C6.51411 9.96001 5.90113 10.3318 5.27031 10.5228C4.20261 10.8462 3.13713 11.2461 2.17196 11.8751C2.02707 11.9668 1.89556 12.089 1.83092 12.2698C1.70387 12.6263 2.04268 13.0032 2.25444 13.1968C2.47511 13.4005 2.73591 13.5762 2.98333 13.7367C3.17502 13.864 3.37341 13.9786 3.38232 14.2689C3.39347 14.5669 3.21292 14.9361 3.08141 15.1882C2.86519 15.5957 2.71362 16.0363 2.56427 16.4743C2.25889 17.3834 1.77296 18.2391 1.55229 19.1839C1.30933 20.2128 1.96912 21.1321 2.37926 21.9827C2.86073 22.986 3.30877 24.02 3.90615 24.9444C4.07778 25.2092 4.29177 25.6396 4.54365 25.8357C4.6707 25.935 4.82005 25.716 4.94933 25.4283C5.10091 25.0946 5.22573 24.6744 5.25917 24.593C5.90113 22.9173 6.35139 21.155 7.02679 19.4971C7.32994 18.7433 7.44139 17.6636 7.96298 17.0651Z" stroke="#1D1871" stroke-width="2" mask="url(#path-1-outside-1_4903_51365)"/>
      <path d="M12.3527 21.3437C11.318 21.1894 10.281 21.0883 9.24397 20.9713C8.9463 20.9368 8.4638 20.7985 8.18053 20.9048C7.88767 21.0165 7.76284 21.3144 7.60921 21.5883C7.30434 22.1415 7.1435 22.7984 6.92506 23.4154C6.64884 24.1966 6.44065 25.0052 6.30332 25.8303C6.2361 26.2372 6.19289 26.6414 6.15688 27.051C6.12568 27.3941 6.11127 27.6813 6.36093 27.9552C6.62259 28.2371 7.16991 28.301 7.63801 28.3302C9.03993 28.4153 10.4442 28.5057 11.8486 28.5589C12.3479 28.5749 12.9288 28.6972 13.3753 28.3861C13.6201 28.2132 13.6466 27.7824 13.6994 27.4978C13.8674 26.5457 13.9946 25.5829 14.0931 24.6175C14.1411 24.1388 14.1843 23.6574 14.2179 23.1787C14.2467 22.7612 14.3715 22.2559 14.1675 21.8676C13.8962 21.3463 12.8112 21.4101 12.3527 21.3437ZM29.9966 20.5431C29.951 20.5431 29.9102 20.5458 29.8742 20.5485C28.405 20.7293 26.9311 21.0325 25.4692 21.2719C24.461 21.4367 23.4863 21.7187 22.4637 21.7878C22.1252 21.8091 21.3307 21.9873 21.1122 21.6069C20.901 21.2373 20.961 20.4447 20.901 20.0112C20.8626 19.7293 20.925 19.3623 20.7545 19.123C20.6249 18.9421 20.3849 18.8517 20.1808 18.9262C19.3382 19.2267 18.6277 20.5192 18.1019 21.2479C17.3434 22.3011 16.6256 23.3941 16.0207 24.5643C15.4902 25.5909 17.1873 27.0962 17.7659 27.7079C18.4188 28.3967 19.1102 29.0376 19.7943 29.6866C19.9672 29.8488 20.14 30.0137 20.3128 30.1786C20.4377 30.3009 20.5433 30.4312 20.7089 30.4711C21.045 30.5456 21.3283 30.4977 21.4051 30.0669C21.4843 29.6121 21.5155 29.1866 21.4795 28.7291C21.4531 28.4047 21.3859 27.9685 21.6883 27.7717C22.0292 27.5536 22.4229 27.5696 22.795 27.4925C24.1321 27.2052 25.5148 27.2824 26.8591 27.0164C27.9201 26.8063 28.3378 25.3063 28.8083 24.3994C29.2548 23.5404 29.7902 22.6974 30.1454 21.7878C30.2535 21.5139 30.3975 21.2532 30.4671 20.9607C30.4935 20.849 30.5319 20.7506 30.4527 20.6655C30.3591 20.5671 30.155 20.5405 29.9966 20.5431Z" stroke="#1D1871" stroke-width="2" mask="url(#path-1-outside-1_4903_51365)"/>
      <path d="M30.1454 17.6212C30.1129 17.5352 30.0803 17.4587 30.0524 17.387C29.6919 16.4814 29.35 15.5685 28.9825 14.6653C28.7523 14.0989 28.4964 13.5278 28.1336 13.0355C27.9987 12.8515 27.7964 12.7822 27.5847 12.7392C27.3847 12.701 27.2126 12.7416 27.0126 12.8109C26.6381 12.9423 26.2544 13.0762 25.8869 13.2315C24.8705 13.6521 23.8099 14.044 22.8447 14.5744C22.5889 14.7154 22.2912 14.8779 22.2819 15.2125C22.2563 16.0465 22.7191 16.694 22.961 17.4563C23.0587 17.7694 23.1889 18.0752 23.3378 18.3644C23.6634 19 23.9937 19.6381 24.3356 20.2689C24.496 20.5628 24.6658 20.7277 24.9728 20.8161C25.1845 20.8807 25.3845 20.8185 25.6031 20.7922C26.3404 20.6991 27.0335 20.4673 27.7522 20.2857C28.5592 20.0778 29.4198 19.8364 30.1105 19.3394C30.6106 18.9714 30.3571 18.1661 30.1454 17.6212Z" stroke="#1D1871" stroke-width="2" mask="url(#path-1-outside-1_4903_51365)"/>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'IconWasteType',
  props: {
    color: { 
      default: "fff"
    },
    width: {
      default: 25
    },
    height: {
      default: 25
    }
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {
    checkColor() {
      if (this.color === 'fff' || this.color === 'ffffff' || this.color === 'FFF' || this.color === 'FFFFFF') {
        return 'white';
      } else {
        return 'other';
      }
    }
  }
};

</script>

<style lang="scss" scoped></style>