<template>
  <div
    @mouseenter="hoverEvent = $event"
    @mouseleave="hoverEvent = null"
    class="icon-button"
  >
    <div v-if="disabled" class="icon-button-img-wrapper--disabled">
      <img
        :src="types[type]?.src_disabled"
        class="icon-button-img"
        alt=""
      />
    </div>
    <div v-else-if="hoverActive" @click="$emit('click', $event)" class="icon-button-img-wrapper">
      <img
        :src="types[type]?.src_hover_green"
        class="icon-button-img"
        alt=""
      />
    </div>
    <div v-else-if="alwaysActive" @click="$emit('click', $event)" class="icon-button-img-wrapper">
      <img
        :src="types[type]?.src_hover"
        class="icon-button-img"
        alt=""
      />
    </div>
    <div v-else @click="$emit('click', $event)" class="icon-button-img-wrapper">
      <img
        :src="types[type]?.src"
        class="icon-button-img icon-button-img--base"
        alt=""
      />
      <img
        :src="types[type]?.src_hover"
        class="icon-button-img icon-button-img--hover"
        alt=""
      />
    </div>
    <RestyleTooltip
      v-if="hoverEvent && tooltip.view"
      :hoverEvent="hoverEvent"
      :tooltipDelay="tooltip.delay"
      :tooltipText="tooltip.text ?? initialTooltip.text"
    />
  </div>
</template>

<script>
//передаем в type кнопку которую нужно отображать
const initialTooltip = {
  edit: {
    text: "Редактировать",
  },
  delete: {
    text: "Удалить",
  },
  copy: {
    text: "Скопировать ссылку"
  },
  unblock: {
    text: "Разблокировать"
  },
  restore: {
    text: "Восстановить"
  },
  detail: {
    text: "Детальная"
  },
  open: {
    text: "Подробнее"
  },
  link: {
    text: "Привязка/отвязка комплекта установки"
  },
  unlink: {
    text: "Отвязка комплекта установки"
  },
  statistics: {
    text: "Статистика"
  },
  documents: {
    text: "Комплект установки"
  },
  stateCalibration: {
    text: ""
  },
  map: {
    text: ""
  },
  downloadComplect: {
    text: "Скачать в Excel"
  }
};

export default {
  name: "icon_button",
  props: {
    type: {
      required: true,
      type: String,
    },
    tooltip: {
      type: Object,
      default: () => ({
        view: true,
        delay: 0,
      }),
    },
    alwaysActive: {
      type: Boolean,
      default: false,
    },
    hoverActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: {
        edit: {
          src: require("@/assets/restyle/images/shared/icons/edit.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/edit_active.svg"),
          src_disabled: require("@/assets/restyle/images/shared/icons/edit_disabled.svg"),
        },
        delete: {
          src: require("@/assets/restyle/images/shared/icons/delete.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/delete_active.svg"),
          src_disabled: require("@/assets/restyle/images/shared/icons/delete_disabled.svg"),
        },
        copy: {
          src: require("@/assets/restyle/images/shared/icons/copy.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/copy_active.svg"),
        },
        restore: {
          src: require("@/assets/restyle/images/users/icons/restore.svg"),
          src_hover: require("@/assets/restyle/images/users/icons/restore_active.svg"),
        },
        unblock: {
          src: require("@/assets/restyle/images/users/icons/unblock.svg"),
          src_hover: require("@/assets/restyle/images/users/icons/unblock_active.svg"),
        },
        detail: {
          src: require("@/assets/restyle/images/shared/icons/settings.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/settings_active.svg"),
        },
        open: {
          src: require("@/assets/restyle/images/shared/icons/open.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/open_active.svg"),
        },
        link: {
          src: require("@/assets/restyle/images/shared/icons/link.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/link_active.svg"),
          src_hover_green: require("@/assets/restyle/images/shared/icons/link_active_green.svg"),
          src_disabled: require("@/assets/restyle/images/shared/icons/link.svg"),
        },
        unlink: {
          src: require("@/assets/restyle/images/shared/icons/unlink.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/unlink_active.svg"),
          src_disabled: require("@/assets/restyle/images/shared/icons/unlink.svg"),
        },
        unattach: {
          src: require("@/assets/restyle/images/navigation_systems/unattach.svg"),
          src_hover: require("@/assets/restyle/images/navigation_systems/unattach_active.svg"),
        },
        statistics: {
          src: require("@/assets/restyle/images/shared/icons/statistics.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/statistics_active.svg"),
        },
        documents: {
          src: require("@/assets/restyle/images/shared/icons/documents.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/documents_active.svg"),
        },
        stateCalibration: {
          src: require("@/assets/vehicle_images/start_calibrated_disabled.svg"),
          src_hover: require("@/assets/vehicle_images/start_calibrated.svg"),
          src_disabled: require("@/assets/vehicle_images/start_calibrated_disabled.svg"),
        },
        map: {
          src: require("@/assets/restyle/images/shared/icons/map.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/map_active.svg"),
        },
        downloadComplect: {
          src: require("@/assets/restyle/images/shared/icons/download_complect_icon.svg"),
          src_hover: require("@/assets/restyle/images/shared/icons/download_complect_icon_active.svg"),
        },
      },
      hoverEvent: null,
    };
  },
  computed: {
    initialTooltip() {
      return initialTooltip[this.type];
    },
  },
};
</script>

<style lang="scss" scoped>

.icon-button {
  position: relative;
  display: inline-block;
  line-height: 0;

  &-img {
    &--hover {
      display: none;
    }
  }

  &:hover {
    & .icon-button-img--base {
      display: none;
    }

    & .icon-button-img--hover {
      display: initial;
    }
  }
}

.icon-button-img-wrapper {
  cursor: pointer;
}

.icon-button-img-wrapper--disabled {
  cursor: not-allowed;
}

</style>