<template>
  <div>
    <div class="bg-container"></div>
    <div class="auth-box">
      <div class="auth-box-card">
        <div class="auth-box-card-logo">
          <img src="@/assets/restyle/images/new_logo.svg" alt="" />
        </div>
        <h2 class="auth-box-card-title">Факт</h2>
        <h4 class="auth-box-card-subtitle">Забыли пароль?</h4>
        <div class="auth-box-card-subtitle-secondary">
          Для восстановления пароля введите свою почту и следуйте инструкциям, полученным в письме
        </div>
        <div class="auth-box-card-login">
          <input
            id="name"
            class="auth-input"
            :class="{ 'auth-input--error': send && !email }"
            type="email"
            v-model="email"
            @keyup.enter="forgot()"
            placeholder="Email"
          />
        </div>
        <div class="auth-box-card-submit">
          <button
            v-if="isVisibleSendButton"
            :disabled="!email"
            @click="forgot()"
            class="restyle-button restyle-button--success"
          >
            Отправить
          </button>
          <div class="info" v-else>
            <div class="info__text-success">
              <strong>
                Успешно!
              </strong>
              На вашу почту отправлено письмо со ссылкой для смены пароля. Ссылка будет действительна в течение 24 часов. <br/>
            </div>
            <Button class="info__btn-back" @click="backPage">Вернуться на сайт</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        email: null,
        send: false,
        isVisibleSendButton: true,
      }
    },
    created() {
      this.$store.dispatch('auth/logout');
    },
    methods: {
      async forgot() {
        this.send = true;
        const email = this.email.toLowerCase();
        const result = await this.$store.dispatch('auth/forgot', email);
        if (result.success) {
          this.isVisibleSendButton = false
        }
      },
      backPage() {
        this.$router.push({name: 'login'});
      }
    }
  }
</script>

<style lang="scss">
.header-auth {
  font-size: 1.75rem;
  font-weight: 500;
}
//НЕ ДОБАВЛЯТЬ СЮДА НОВЫЕ СТИЛИ
</style>

<style lang="scss" scoped>

label {
  color: #3c3c3c;
  font-family: "Noto Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}

.restore {
  color: #8a8a8a;
  font-family: "Noto Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 21px;
  border-bottom: 1px dashed #8a8a8a;
}

.btn {
  border-radius: 25.5px;
}

.footer-login {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 200px;
  transform: translate(-50%, 0);
}

.bg-container {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #1d9c95 0%, #1d106f 100%);

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/restyle/images/background_auth.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.auth-box {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  & * {
    font-family: "Noto Sans", "sans-serif";
  }
}

.auth-box-card {
  padding: 92px 56px 99px 56px;
  width: 650px;
  min-height: 705px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 6.43799px 41px 16px rgba(38, 65, 90, 0.4);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-logo {
    margin-bottom: 10px;
    text-align: center;
  }

  &-title {
    margin-bottom: 19px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 65px;
    color: #ffffff;
  }

  &-subtitle {
    margin-bottom: 40px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
    &-secondary {
      color: #FFF;
      font-family: Noto Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 40px;
    }
  }

  &-login {
    margin-bottom: 60px;

  }

  &-submit {
    & button {
      padding: 12.5px 25px;
      text-align: center;
      width: 413px;
      background: #44ff8f;
      border-radius: 53.3676px;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      cursor: pointer;
      color: #110c61;

      &:hover {
        background-color: #39da8c;
      }

      &:active {
        background-color: #0d3c61;
        color: #44ff8f;
      }

      &:disabled {
        background-color: #bae2ca;
        color: #486983;
        cursor: not-allowed;
      }
    }
  }
}

.auth-input {
  padding: 14px 31px 9px 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #a7d1d7;
  border-bottom: 2px solid #ffffff;
  width: 413px;

  &::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &:-ms-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &::-ms-input-placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #63bfcd;
  }

  &--error {
    border-bottom: 2px solid #ff998b;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: all 10000s ease-in-out 0s;
    /* выставляется желаемая задержка */
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text-success {
    border: 1px solid rgba(255, 255, 255, 0.51);
    color: white !important;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  &__btn-back {
    height: 50px !important;
    width: 300px !important;
    font-size: 22px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>