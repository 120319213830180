<template>
  <div class="restyle-alert">
    <div class="restyle-alert-close" @click="$emit('clear')">
      <img src="@/assets/restyle/images/shared/icons/x_close_white.svg" alt="" />
    </div>
    <div class="restyle-alert-content">
      <div class="description" v-for="(alert, index) of alerts" :key="index">
        <img
          :src="require(`@/assets/restyle/images/alert_${alert.type}.svg`)"
          class="restyle-alert-icon"
          alt=""
        />
        <span class="restyle-alert-message">
          <strong>{{ types[alert.type]?.title }}</strong>
          {{ alert.message }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DarkThemeAlert",
  props: {
    alerts: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      types: {
        success: {
          title: 'Успешно!',
        },
        danger: {
          title: 'Ошибка!',
        },
      },
    }
  },
};
</script>

<style lang="scss" scoped>
.restyle-alert {
  z-index: 1052;
  position: fixed;
  bottom: 46px;
  overflow-wrap: break-word;
  border-radius: 10px;
  background: #0B0936;
  padding: 15px 20px 15px 20px;
  width: fit-content;
  max-width: 1300px;
  left: 0;
  right: 0;
  margin: 0 auto;

}

.restyle-alert-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 11px;
  height: 11px;

  & img {
    vertical-align: top;
  }
}

.restyle-alert-content {
  margin-right: 20px;
  & .description {
    font-family: "Noto Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: $color-restyle-white;
  }

  & strong {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color-restyle-white;
  }
}

.restyle-alert-icon {
  margin-right: 10px;
  position: relative;
  bottom: 2px;
}
</style>