<template>
  <component
    @clear="clear()"
    :alerts="alerts"
    :is="currentAlertComponent"
  >
  </component>
</template>

<script>
// Пример вызова в компоненте, сейчас у нас пока что будет 2 типа success, danger

// this.$store.dispatch('alert/alerts', { type: String, message: String, timer: Number, sticky: Boolean, hasTitle: Boolean });
// timer - опционально, default timer - 15000,
// sticky - опционально, алерт остаётся при переходе на другой роут
// hasTitle - опционально, убирает title только в случае, если hasTitle: false

// this.$store.dispatch('alert/alerts', {type: 'success', message: 'message description'});
// this.$store.dispatch('alert/alerts', {type: 'danger', message: 'message description'});

import LightThemeAlert from '@/components/restyle/alert/LightThemeAlert.vue';
import DarkThemeAlert from '@/components/restyle/alert/DarkThemeAlert.vue';

export default {
  name: "restyle_alert",
  components: {
    LightThemeAlert,
    DarkThemeAlert,
  },
  props: {
    alerts: {
      required: true,
      type: Array,
      default: () => [],
    },
    darkTheme: {
      required: true,
      type: Boolean,
      default: false,
    },
    darkThemeRoutes: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  computed: {
    currentAlertComponent() {
      return this.darkTheme ? DarkThemeAlert : LightThemeAlert;
    }
  },
  methods: {
    clear() {
      this.$store.dispatch('alert/clear');
    }
  },
};
</script>

<style lang="scss" scoped>

</style>